import React, { createContext, useState, useEffect } from 'react'
import {
  useQuery, 
  useLazyQuery,
  useMutation,
  gql
} from "@apollo/client";
import { Blockfrost, Lucid } from "lucid-cardano";

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

const BANKCARD_POLICY_ID = '1d52a061c0b6daea2cb248d32790fbf32d21b78723fcfde75177f176';

const CONSUMABLE_UPGRADE_DESTINATION_WALLET_ADDRESS = 'addr1qyev4mm4kpnnqutaykgashnpnjfnwqfqzw5j9z2tu9zsrjggyysnnl43aw6xhmm7295y7s3yyz9rc9p0nfey6plhwuyshyx2ea';
const DETACH_REQUESTS_WALLET_ADDRESS = 'addr1vxh2wndpkdzctpfp2atvwf0lf6xm8g75nf5n7qu7ensg3fgt7c5sd';
const ATTACH_UPGRADE_NFTS_WALLET_ADDRESS = 'addr1v85krzlm08yyzup0hxd47jhgzl8gsxk2v08jcq79znrchhgvnypx4';
const INVENTORY_REQUESTS_WALLET_ADDRESS = 'addr1v93ry4sz307khwng073mwk76xjnvwr0s8xn9xl6kt56lrgsrxhmte';
const MINT_ITEM_REQUESTS_WALLET_ADDRESS = 'addr1vx8f0uhea8yr9lavdfd4p7gpcjlwxp4c3l23ju5f0mznvqqx9xc56';
const BURN_TASKS_WALLET_ADDRESS = 'addr1v8yytyenw0p9ynftendqc5lwlruvvvfyc63qkczege07r4q9qmtud';
const FORGE_ITEMS_WALLET_ADDRESS = 'addr1vx0rqqm0yrg7fca5mcn5tun8m57cg8dfyt44ct050ujqvaq9srq0s';
// const FORGE_ITEMS_WALLET_ADDRESS = 'addr1q9ex6r975mngtch0jjguke6chvfc68th8qgc6tf47g33tckw8pf4fhlh78v92y0pqeztyxs6mmey37sheges8js20u4s2af3z0';
const RACCOON_PRIZES_WALLET_ADDRESS = 'addr1q9dp2lkrlfgqje0nfsg48y0cdld6kc2txw3hlmspcmm3wrn4ymlje49n5hf7amchkeakqfd4pt8xv5la7769mspn5jjq8evvns';


const USE_CONSUMABLE_FEE = 3000000n; // from 6 to 3
const ATTACH_UPGRADE_FEE = 5000000n; // from 10 to 5
const DETACH_UPGRADE_FEE = 5000000n; // from 10 to 5
const MINT_CONSUMABLE_FEE = 3000000n; // from 6 to 3
const MINT_ATTACHABLE_FEE = 5000000n; // from 10 to 5
const BURN_TASKS_FEE_SHARE = 2400000n; // from 3 to 2.4


const bankerCards = [
  "BankCard2475",
  "BankCard2476",
  "BankCard2477",
  "BankCard2478",
  "BankCard2479",
  "BankCard2480",
  "BankCard2481",
  "BankCard2482",
  "BankCard2483",
  "BankCard2484",
  "BankCard2485",
  "BankCard2486",
  "BankCard2487",
  "BankCard2488",
  "BankCard2489",
  "BankCard2490",
  "BankCard2491",
  "BankCard2492",
  "BankCard2493",
  "BankCard2494",
  "BankCard2495",
  "BankCard2496",
  "BankCard2497",
  "BankCard2498",
  "BankCard2499"
];

const GET_GAME_STATE = gql`
  query GetGameState {
    gamestate {
      currentEpoch
    }
  }
`;

const GET_RACCOONS_SQUADS = gql`
  query GetRaccoonsSquads($raccoonNumbers: String!, $source: String) {
    raccoonsSquads(raccoonNumbers: $raccoonNumbers, source: $source) {
      results
      source
    }
  }
`;

const GET_RACCOONS_UPGRADE_STATUS = gql`
  query GetRaccoonsUpgradeStatus($raccoonNumbers: String!) {
    raccoonsUpgradeStatus(raccoonNumbers: $raccoonNumbers) {
      results
      upgrades
    }
  }
`;

const GET_NUM_UNREAD_CONVERSATIONS = gql`
  query GetNumUnreadConversations($myRaccoons: String!, $myWalletAddresses: String!) {
    numUnreadConversations(myRaccoons: $myRaccoons, myWalletAddresses: $myWalletAddresses) {
      numUnreadConversations
    }
  }
`;

const TRX_UPDATE_WATCH_HASH = gql`
  mutation TRXUpdateWatchHash($assetNameHex: String!, $inventoryItemId: String, $quantity: Int!, $action: String!, $myWalletAddresses: String!, $raccoonNum: Int, $watchId: String, $trxHash: String, $operationId: String) {
    trxUpdateWatchHash( assetNameHex: $assetNameHex, inventoryItemId: $inventoryItemId, quantity: $quantity, action: $action, myWalletAddresses: $myWalletAddresses, raccoonNum: $raccoonNum, watchId: $watchId, trxHash: $trxHash, operationId: $operationId ) {
      result
      assetNameHex
    }
  }
`;

const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;

const FORGE_ITEMS_UPDATE_STATUS = gql`
  mutation ForgeItemsUpdateStatus($forge_watch_id: String!, $status: String!, $trx_hash: String, $operationId: String) {
    forgeItemsUpdateStatus( forge_watch_id: $forge_watch_id, status: $status, trx_hash: $trx_hash, operationId: $operationId ) {
      result
      cellItems
      pairs
      nftItems
      inventoryItems
      forge_watch_id
      trx_hash
    }
  }
`;

const FORGE_ITEMS_ADV_UPDATE_STATUS = gql`
  mutation ForgeItemsAdvUpdateStatus($forge_adv_watch_id: String!, $status: String!, $trx_hash: String, $operationId: String) {
    forgeItemsAdvUpdateStatus( forge_adv_watch_id: $forge_adv_watch_id, status: $status, trx_hash: $trx_hash, operationId: $operationId ) {
      result
      cellItems
      pairs
      nftItems
      inventoryItems
      forge_adv_watch_id
      trx_hash
    }
  }
`;

// Wallet.setBlockfrost("mainnetkawMJgC9loXVYD3uwvi1o8BaubKVACzh"); // CES Key

let lucid = await Lucid.new(
  new Blockfrost("https://cardano-mainnet.blockfrost.io/api/v0", "mainnetelrNhK3Uj9H8pWBZvomp6l4ZYS55px65"),
  "Mainnet",
);



function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}

export const GlobalStateContext = createContext();

let forgeItemsSuccessCallback = null;
let forgeItemsFailureCallback = null;

export const GlobalStateProvider = ({ children }) => {

  const [state, setState] = useState({
    wallets: {},
    doneInitialWalletLoad: false,
    connectInProgress: false,
    numUnreadConversations: '-',
  });

  const [numUnreadConversations, setNumUnreadConversations] = useState('-');

  const [currentEpoch, setCurrentEpoch] = useState(0);

  const [team, setTeam] = useState(false);
  const [regEpochs, setRegEpochs] = useState([]);
  const [teamEpochs, setTeamEpochs] = useState([]);
  const [activeWallet, setActiveWallet] = useState(null);

  const [mailboxId, setMailboxId] = useState(null);

  const [raccoonUpgradesApplied, setRaccoonUpgradesApplied] = useState([]);

  const [globalLucid, setGlobalLucid] = useState(lucid);

  const [getGameState, { loading: loadingGetGameState, error: errorGetGameState, data: dataGetGameState, refetch: refetchGetGameState }] = useLazyQuery(GET_GAME_STATE);

  const [getRaccoonsSquads, { loading: loadingGetRaccoonsSquads, error: errorGetRaccoonsSquads, data: dataGetRaccoonsSquads, refetch: refetchGetRaccoonsSquads }] = useLazyQuery(GET_RACCOONS_SQUADS);
  
  const [getRaccoonsUpgradeStatus, { loading: loadingGetRaccoonsUpdateStatus, error: errorGetRaccoonsUpdateStatus, data: dataGetRaccoonsUpdateStatus, refetch: refetchGetRaccoonsUpdateStatus }] = useLazyQuery(GET_RACCOONS_UPGRADE_STATUS);

  const [getNumUnreadConversations, { loading: loadingGetNumUnreadConversations, error: errorGetNumUnreadConversations, data: dataGetNumUnreadConversations, refetch: refetchGetNumUnreadConversations }] = useLazyQuery(GET_NUM_UNREAD_CONVERSATIONS);

  const [trxUpdateWatchHash, { data: dataTRXUpdateWatchHash, loading: loadingTRXUpdateWatchHash, error: errorTRXUpdateWatchHash }] = useMutation(TRX_UPDATE_WATCH_HASH, 
    {
      onCompleted: response => {
        if (response && response.trxUpdateWatchHash && response.trxUpdateWatchHash.result === 'success' && response.trxUpdateWatchHash.assetNameHex) {
          
        }
    }
  });

  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          setMailboxId(response.setReadMessagesTime.mailboxId);
        }
    }
  });

  const [forgeItemsUpdateStatus, { data: dataForgeItemsUpdateStatus, loading: loadingForgeItemsUpdateStatus, error: errorForgeItemsUpdateStatus }] = useMutation(FORGE_ITEMS_UPDATE_STATUS, 
    {
      onCompleted: response => {

        if (response && response.forgeItemsUpdateStatus && response.forgeItemsUpdateStatus.result === 'success') {
          if (forgeItemsSuccessCallback) {
            forgeItemsSuccessCallback(response);
          }/*
          setToastColor('#14711f');
          setTimeout(() => {
            toast("Forge request added to queue. Please wait 5 - 15 minutes for the forge to complete your item(s).")
          }, 200);
          */
          return;
        } else {
          /*
          setToastColor('#e08705');
          setTimeout(() => {
            toast("Sorry, there was an error completing your forge request.")
          }, 200);
          */
          forgeItemsFailureCallback(response);
          return;
        }
      }
  });



  const [forgeItemsAdvUpdateStatus, { data: dataForgeItemsAdvUpdateStatus, loading: loadingForgeItemsAdvUpdateStatus, error: errorForgeItemsAdvUpdateStatus }] = useMutation(FORGE_ITEMS_ADV_UPDATE_STATUS, 
    {
      onCompleted: response => {
        if (response && response.forgeItemsAdvUpdateStatus && response.forgeItemsAdvUpdateStatus.result === 'success') {
          if (forgeItemsSuccessCallback) {
            forgeItemsSuccessCallback(response);
          }/*
          setToastColor('#14711f');
          setTimeout(() => {
            toast("Forge request added to queue. Please wait 5 - 15 minutes for the forge to complete your item(s).")
          }, 200);
          */
          return;
        } else {
          /*
          setToastColor('#e08705');
          setTimeout(() => {
            toast("Sorry, there was an error completing your forge request.")
          }, 200);
          */
          forgeItemsFailureCallback(response);
          return;
        }
      }
  });


  useEffect(() => {
    getGameState();
  }, []);

  useEffect(() => {
    if (!dataGetGameState) return;
    if (dataGetGameState && dataGetGameState.gamestate) {
      if (dataGetGameState.gamestate.currentEpoch) {
        setCurrentEpoch(parseInt(dataGetGameState.gamestate.currentEpoch));
      }
    }
  }, [dataGetGameState])

  useEffect(() => {
    setTimeout(() => {
      getNumUnreadConversations({ variables: { myRaccoons: 'myRaccoonsHere', myWalletAddresses: 'testAddresses' } });
    }, 10);
    setTimeout(() => {
      if (!state.doneInitialWalletLoad) {
        setState({
          ...state,
          doneInitialWalletLoad: true,
          updateTime: Date.now()
        })
      }
    });
  }, [])

  useEffect(() => {
    if (!dataGetNumUnreadConversations) return;
    
    let numUnreadConversationsNew = 0;
    if (dataGetNumUnreadConversations && dataGetNumUnreadConversations.numUnreadConversations && dataGetNumUnreadConversations.numUnreadConversations.numUnreadConversations) {
      numUnreadConversationsNew = dataGetNumUnreadConversations.numUnreadConversations.numUnreadConversations;
    }

    setNumUnreadConversations(numUnreadConversationsNew);
  }, [dataGetNumUnreadConversations])
  

  useEffect(() => {
    if (dataGetRaccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads.results) {
      let newRaccoonsInWallet = [];
      let results = JSON.parse(dataGetRaccoonsSquads.raccoonsSquads.results);
      let raccoonSquads = {};
      results.forEach(result => {
        raccoonSquads[result.racNum] = result;
      });
      
      if (state.raccoonsInWallet && state.raccoonsInWallet.length) {
        state.raccoonsInWallet.forEach(rac => {
          let newRac = {...rac};
          let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
          let racNum = parseInt(racNumStr);
          if (raccoonSquads[racNum]) {
            newRac.racNum = raccoonSquads[racNum].racNum;
            newRac.raccoonsInSquadILead = raccoonSquads[racNum].raccoonsInSquadILead;
            newRac.raccoonsInSquadImIn = raccoonSquads[racNum].raccoonsInSquadImIn;
            newRac.squadILead = raccoonSquads[racNum].squadILead;
            newRac.squadImIn = raccoonSquads[racNum].squadImIn;
          }
          newRaccoonsInWallet.push(newRac);
        });
      }

      setState({
        ...state,
        raccoonsInWallet: [...newRaccoonsInWallet],
        doneGetRaccoonsSquads: true,
        updateTime: Date.now()
      })
    }
  }, [dataGetRaccoonsSquads, loadingGetRaccoonsSquads]);

  
  useEffect(() => {
    if (dataGetRaccoonsUpdateStatus && dataGetRaccoonsUpdateStatus.raccoonsUpgradeStatus && dataGetRaccoonsUpdateStatus.raccoonsUpgradeStatus.results === 'success' && dataGetRaccoonsUpdateStatus.raccoonsUpgradeStatus.upgrades) {
      let upgrades = JSON.parse(dataGetRaccoonsUpdateStatus.raccoonsUpgradeStatus.upgrades);
      let upgradesObj = {};
      upgrades.forEach(upgrade => {
        let existingForThisRaccoon = upgradesObj[upgrade.raccoonNum];
        if (!existingForThisRaccoon) {
          existingForThisRaccoon = [];
        }
        existingForThisRaccoon.push(upgrade);
        upgradesObj[upgrade.raccoonNum] = existingForThisRaccoon;
      });
      setRaccoonUpgradesApplied(upgradesObj);
    }
  }, [dataGetRaccoonsUpdateStatus])

  useEffect(() => {
    initialLoad();
  }, []);

  useEffect(() => {
    refreshWallet();
  }, [state.api]);

  /*
  useEffect(() => {
    
    if (state.raccoonsInWallet) {
      let racNums = [];
      state.raccoonsInWallet.forEach(rac => {
        let racName = rac.assetNameAscii;
        if (rac.assetNameAscii) {
          let racNum = racName.substring('Raccoon '.length, racName.length);
          racNums.push(parseInt(racNum));
        }
      });
      if (racNums && racNums.length > 0) {
        refetchGetRaccoonsSquads({raccoonNumbers: JSON.stringify(racNums), source: 'it'})
      }
    }
    
  }, [state.raccoonsInWallet]);
*/

  function ascii_to_hexa(str)
  {
    var arr1 = [];
    for (var n = 0, l = str.length; n < l; n ++) 
      {
      var hex = Number(str.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join('');
  }

  const walletSendConsumableUpgradeNFT = async (assetNameHex, raccoonNum, watchId, sendUpgradeSuccess, sendUpgradeFailure) => {
    try {
      const token = `${RS_UPGRADES_POLICY_ID}${assetNameHex}`;

      const tx = await lucid.newTx()
      .validTo(Date.now() + 2000000000)
      .payToAddress(DETACH_REQUESTS_WALLET_ADDRESS, { [token]: 1 })
      .complete();


      const signedTx = await tx.sign().complete();
      const trxHash = await signedTx.submit();
      trxUpdateWatchHash({ variables: { assetNameHex: assetNameHex, quantity: parseInt(1), action: 'trxUpdateWatchHash', myWalletAddresses: 'testAddresses', raccoonNum: parseInt(raccoonNum), watchId, trxHash } });
      if (sendUpgradeSuccess) {
        sendUpgradeSuccess(assetNameHex, raccoonNum, watchId, trxHash);
      }
    } catch (e) {
      console.error('Error sending upgrade:', e);
      if (sendUpgradeFailure) {
        sendUpgradeFailure(assetNameHex, raccoonNum, watchId, e);
      }
    }
  }

  const walletSendAttachableUpgradeNFT = async (assetNameHex, raccoonNum, watchId, sendUpgradeSuccess, sendUpgradeFailure) => {
    try {
      const token = `${RS_UPGRADES_POLICY_ID}${assetNameHex}`;

      const tx = await lucid.newTx()
      .validTo(Date.now() + 2000000000)
      .payToAddress(ATTACH_UPGRADE_NFTS_WALLET_ADDRESS, { [token]: 1, lovelace: ATTACH_UPGRADE_FEE - BURN_TASKS_FEE_SHARE })
      .payToAddress(BURN_TASKS_WALLET_ADDRESS, { lovelace: BURN_TASKS_FEE_SHARE })
      .complete();
  
      const signedTx = await tx.sign().complete();
      const trxHash = await signedTx.submit();
      trxUpdateWatchHash({ variables: { assetNameHex: assetNameHex, quantity: parseInt(1), action: 'trxUpdateWatchHash', myWalletAddresses: 'testAddresses', raccoonNum: parseInt(raccoonNum), watchId, trxHash } });
      if (sendUpgradeSuccess) {
        sendUpgradeSuccess(assetNameHex, raccoonNum, watchId, trxHash);
      }
    } catch (e) {
      console.error('Error sending upgrade:', e);
      if (sendUpgradeFailure) {
        sendUpgradeFailure(assetNameHex, raccoonNum, watchId, e);
      }
    }
  }


  const walletSendDetachRequest = async (raccoonNum, watchId, sendDetachRequestSuccess, sendDetachRequestFailure) => {
    try {

      const tx = await lucid.newTx()
      .validTo(Date.now() + 2000000000)
      .payToAddress(DETACH_REQUESTS_WALLET_ADDRESS, { lovelace: DETACH_UPGRADE_FEE - BURN_TASKS_FEE_SHARE})
      .payToAddress(BURN_TASKS_WALLET_ADDRESS, { lovelace: BURN_TASKS_FEE_SHARE })
      .complete();
  
      const signedTx = await tx.sign().complete();
      const trxHash = await signedTx.submit();
      trxUpdateWatchHash({ variables: { assetNameHex: 'detach', quantity: parseInt(1), action: 'trxUpdateWatchHash', myWalletAddresses: 'testAddresses', raccoonNum: parseInt(raccoonNum), watchId, trxHash } });
      if (sendDetachRequestSuccess) {
        sendDetachRequestSuccess(raccoonNum, watchId, trxHash);
      }
    } catch (e) {
      console.error('Error sending upgrade:', e);
      if (sendDetachRequestFailure) {
        sendDetachRequestFailure(raccoonNum, watchId, e);
      }
    }
  }


  const walletSendConsumableUpgradeFromInventory = async (assetNameHex, inventoryItemId, raccoonNum, watchId, upgradeType, sendUpgradeSuccess, sendUpgradeFailure) => {
    try {
      const token = `${RS_UPGRADES_POLICY_ID}${assetNameHex}`;


  
      const tx = await lucid.newTx()
      .validTo(Date.now() + 2000000000)
      .payToAddress(INVENTORY_REQUESTS_WALLET_ADDRESS, { lovelace: USE_CONSUMABLE_FEE })
      .complete();

      const signedTx = await tx.sign().complete();
      const trxHash = await signedTx.submit();
      trxUpdateWatchHash({ variables: { assetNameHex: assetNameHex, inventoryItemId, quantity: parseInt(1), action: 'trxUpdateWatchHash', myWalletAddresses: 'testAddresses', raccoonNum: parseInt(raccoonNum), watchId, trxHash } });
      if (sendUpgradeSuccess) {
        sendUpgradeSuccess(assetNameHex, raccoonNum, watchId, trxHash);
      }
    } catch (e) {
      console.error('Error sending upgrade:', e);
      if (sendUpgradeFailure) {
        sendUpgradeFailure(assetNameHex, raccoonNum, watchId, e);
      }
    }
  }

  const walletSendAttachableUpgradeFromInventory = async (assetNameHex, inventoryItemId, raccoonNum, watchId, upgradeType, sendUpgradeSuccess, sendUpgradeFailure) => {
    try {
      const token = `${RS_UPGRADES_POLICY_ID}${assetNameHex}`;

      const tx = await lucid.newTx()
      .validTo(Date.now() + 2000000000)
      .payToAddress(INVENTORY_REQUESTS_WALLET_ADDRESS, { lovelace: ATTACH_UPGRADE_FEE - BURN_TASKS_FEE_SHARE })
      .payToAddress(BURN_TASKS_WALLET_ADDRESS, { lovelace: BURN_TASKS_FEE_SHARE })
      .complete();
  
      const signedTx = await tx.sign().complete();
      const trxHash = await signedTx.submit();
      trxUpdateWatchHash({ variables: { assetNameHex: assetNameHex, inventoryItemId, quantity: parseInt(1), action: 'trxUpdateWatchHash', myWalletAddresses: 'testAddresses', raccoonNum: parseInt(raccoonNum), watchId, trxHash } });
      if (sendUpgradeSuccess) {
        sendUpgradeSuccess(assetNameHex, raccoonNum, watchId, trxHash);
      }
    } catch (e) {
      console.error('Error sending upgrade:', e);
      if (sendUpgradeFailure) {
        sendUpgradeFailure(assetNameHex, raccoonNum, watchId, e);
      }
    }
  }

  const walletMintInventoryItem = async (assetName, watchId, inventoryItemId, attachableOrConsumable, operationId, sendUpgradeSuccess, sendUpgradeFailure) => {
    try {

      let tx = null;
      if (attachableOrConsumable === 'consumable') {
        tx = await lucid.newTx()
        .validTo(Date.now() + 2000000000)
        .payToAddress(MINT_ITEM_REQUESTS_WALLET_ADDRESS, { lovelace: MINT_CONSUMABLE_FEE })
        .complete();
      } else {
        tx = await lucid.newTx()
        .validTo(Date.now() + 2000000000)
        .payToAddress(MINT_ITEM_REQUESTS_WALLET_ADDRESS, { lovelace: MINT_ATTACHABLE_FEE })
        .complete();
      }

      const signedTx = await tx.sign().complete();
      const trxHash = await signedTx.submit();
      trxUpdateWatchHash({ variables: { assetNameHex: assetName, inventoryItemId, quantity: parseInt(1), action: 'trxUpdateWatchHash', myWalletAddresses: 'testAddresses', watchId, trxHash, operationId } });
      if (sendUpgradeSuccess) {
        sendUpgradeSuccess(assetName, 0, watchId, trxHash);
      }
    } catch (e) {
      console.error('Error sending upgrade:', e);
      if (sendUpgradeFailure) {
        sendUpgradeFailure(assetName, 0, watchId, e);
      }
    }
  }

  const walletGetSpins = async (assetName, watchId, quantity, upgradeType, getSpinsSuccess, getSpinsFailure) => {
    try {
      const token = '2b28c81dbba6d67e4b5a997c6be1212cba9d60d33f82444ab8b1f21842414e4b';

      let hasBankerCard = false;
      if (state && state.bankerCardsInWallet && state.bankerCardsInWallet.length > 0) {
        hasBankerCard = true;
      }

      let spinPrices = {
        5: {
          totalPrice: 25000000n,
          prizesWallet: 6250000n,
          futureWallet: 18750000n
        },
        24: {
          totalPrice: 100000000n,
          prizesWallet: 25000000n,
          futureWallet: 75000000n
        },
        75: {
          totalPrice: 200000000n,
          prizesWallet: 50000000n,
          futureWallet: 150000000n
        },
      }

      if (hasBankerCard) {
        spinPrices = {
          5: {
            totalPrice: 22500000n,
            prizesWallet: 5625000n,
            futureWallet: 16875000n
          },
          24: {
            totalPrice: 90000000n,
            prizesWallet: 22500000n,
            futureWallet: 67500000n
          },
          75: {
            totalPrice: 180000000n,
            prizesWallet: 45000000n,
            futureWallet: 135000000n
          },
        }
      }


      let tx = null;
      tx = await lucid.newTx()
      .validTo(Date.now() + 2000000000)
      .payToAddress(RACCOON_PRIZES_WALLET_ADDRESS, { [token]: spinPrices[quantity].prizesWallet })
      .payToAddress(INVENTORY_REQUESTS_WALLET_ADDRESS, { [token]: spinPrices[quantity].futureWallet })
      .complete();

      /*
      if (quantity === 5) {
        tx = await lucid.newTx()
        .validTo(Date.now() + 2000000000)
        .payToAddress(RACCOON_PRIZES_WALLET_ADDRESS, { [token]: 6250000n })
        .payToAddress(INVENTORY_REQUESTS_WALLET_ADDRESS, { [token]: 18750000n })
        .complete();
      } else if (quantity === 24) {
        tx = await lucid.newTx()
        .validTo(Date.now() + 2000000000)
        .payToAddress(RACCOON_PRIZES_WALLET_ADDRESS, { [token]: 25000000n })
        .payToAddress(INVENTORY_REQUESTS_WALLET_ADDRESS, { [token]: 75000000n })
        .complete();
      } else if (quantity === 75) {
        tx = await lucid.newTx()
        .validTo(Date.now() + 2000000000)
        .payToAddress(RACCOON_PRIZES_WALLET_ADDRESS, { [token]: 50000000n })
        .payToAddress(INVENTORY_REQUESTS_WALLET_ADDRESS, { [token]: 150000000n })
        .complete();
      }
      */

      const signedTx = await tx.sign().complete();
      const trxHash = await signedTx.submit();
      trxUpdateWatchHash({ variables: { assetNameHex: assetName, quantity: parseInt(1), action: 'trxUpdateWatchHash', myWalletAddresses: 'testAddresses', watchId, trxHash } });
      if (getSpinsSuccess) {
        getSpinsSuccess(assetName, 0, watchId, trxHash);
      }
    } catch (e) {
      console.error('Error sending upgrade:', e);
      if (getSpinsFailure) {
        getSpinsFailure(assetName, 0, watchId, e);
      }
    }
  }

  
  const walletForgeItems = async ({forgeItems, forgeItemsSuccess, forgeItemsFailure}) => {
    try {
      // FORGE_ITEMS_WALLET_ADDRESS

      forgeItemsSuccessCallback = forgeItemsSuccess;
      forgeItemsFailureCallback = forgeItemsFailure;

      let pairs = JSON.parse(forgeItems.pairs);

      let numItems = pairs.length;

      let numNFTDestination = 0n;
      let numInventoryDestination = 0n;
      if (pairs && pairs.length > 0) {
        pairs.forEach(pair => {
          if (pair.destination === 'nft') {
            numNFTDestination += 1n;
          } else if (pair.destination === 'inventory') {
            numInventoryDestination += 1n;
          }
        })  
      }

      let adaFeeForNFTDestinations = numNFTDestination * 4000000n;
      let adaFeeForInventoryDestinations = numInventoryDestination * 3000000n;

      let adaFee = adaFeeForNFTDestinations + adaFeeForInventoryDestinations;

      /*
      if (numItems === 1) {
        adaFee = 4000000n
      } else if (numItems === 2) {
        adaFee = 8000000n
      } else if (numItems === 3) {
        adaFee = 12000000n
      } else if (numItems === 4) {
        adaFee = 16000000n
      } else if (numItems === 5) {
        adaFee = 20000000n
      } else if (numItems === 6) {
        adaFee = 24000000n
      } else if (numItems === 7) {
        adaFee = 28000000n
      } else if (numItems === 8) {
        adaFee = 32000000n
      } else if (numItems === 9) {
        adaFee = 36000000n
      } else if (numItems === 10) {
        adaFee = 40000000n
      }
      */
      
      let tx = await lucid
      .newTx()
      .validTo(Date.now() + 2500000000);
      
      // DISABLE DURING TESTING
      tx.payToAddress(FORGE_ITEMS_WALLET_ADDRESS, { lovelace: adaFee})
      

      if (forgeItems && forgeItems.nftItems) {
        let nftItems = JSON.parse(forgeItems.nftItems);
        nftItems.forEach(nftItem => {
          const token = `${RS_UPGRADES_POLICY_ID}${nftItem.upgrade.assetNameHex}`;
          tx.payToAddress(FORGE_ITEMS_WALLET_ADDRESS, { [token]: 1})
        });
      }

      // const builtTx = await tx.complete();

      const builtTx = await tx.complete();

      const signedTx = await builtTx.sign().complete();

      setTimeout(async () => {
        const trxHash = await signedTx.submit();

        forgeItemsUpdateStatus({ variables: { forge_watch_id: forgeItems.forge_watch_id, status: 'txSubmitted', trx_hash: trxHash, operationId: forgeItems.operationId  }})

        // forgeItemsSuccess({forgeItems, trx_hash: trxHash});
        
        // SKIP SUBMITTING THE TRANSACTION
        // forgeItemsSuccess({forgeItems, trx_hash: 'testhash'});
      }, 1000);
      
    } catch (e) {
      console.error('Error walletForgeItems:', e);
      if (forgeItemsFailure) {
        forgeItemsFailure({forgeItems, e});
      }
    }
  }



  const walletForgeItemsAdv = async ({forgeItems, forgeItemsSuccess, forgeItemsFailure}) => {
    try {
      // FORGE_ITEMS_WALLET_ADDRESS

      forgeItemsSuccessCallback = forgeItemsSuccess;
      forgeItemsFailureCallback = forgeItemsFailure;

      let pairs = JSON.parse(forgeItems.pairs);

      let numItems = pairs.length;

      let numNFTDestination = 0n;
      let numInventoryDestination = 0n;
      if (pairs && pairs.length > 0) {
        pairs.forEach(pair => {
          if (pair.dest === 'nft') {
            numNFTDestination += 1n;
          } else if (pair.dest === 'inventory') {
            numInventoryDestination += 1n;
          }
        })  
      }

      let adaFeeForNFTDestinations = numNFTDestination * 4000000n;
      let adaFeeForInventoryDestinations = numInventoryDestination * 3000000n;

      let adaFee = adaFeeForNFTDestinations + adaFeeForInventoryDestinations;

      /*
      if (numItems === 1) {
        adaFee = 4000000n
      } else if (numItems === 2) {
        adaFee = 8000000n
      } else if (numItems === 3) {
        adaFee = 12000000n
      } else if (numItems === 4) {
        adaFee = 16000000n
      } else if (numItems === 5) {
        adaFee = 20000000n
      } else if (numItems === 6) {
        adaFee = 24000000n
      } else if (numItems === 7) {
        adaFee = 28000000n
      } else if (numItems === 8) {
        adaFee = 32000000n
      } else if (numItems === 9) {
        adaFee = 36000000n
      } else if (numItems === 10) {
        adaFee = 40000000n
      }
      */
      
      let tx = await lucid
      .newTx()
      .validTo(Date.now() + 2500000000);
      
      // DISABLE DURING TESTING
      tx.payToAddress(FORGE_ITEMS_WALLET_ADDRESS, { lovelace: adaFee})
      

      if (forgeItems && forgeItems.nftItems) {
        let nftItems = JSON.parse(forgeItems.nftItems);
        nftItems.forEach(nftItem => {
          const token = `${RS_UPGRADES_POLICY_ID}${nftItem.upgrade.assetNameHex}`;
          tx.payToAddress(FORGE_ITEMS_WALLET_ADDRESS, { [token]: 1})
        });
      }

      // const builtTx = await tx.complete();

      const builtTx = await tx.complete();

      const signedTx = await builtTx.sign().complete();

      setTimeout(async () => {
        const trxHash = await signedTx.submit();

        forgeItemsAdvUpdateStatus({ variables: { forge_adv_watch_id: forgeItems.forge_adv_watch_id, status: 'txSubmitted', trx_hash: trxHash, operationId: forgeItems.operationId  }})

        // forgeItemsSuccess({forgeItems, trx_hash: trxHash});
        
        // SKIP SUBMITTING THE TRANSACTION
        // forgeItemsSuccess({forgeItems, trx_hash: 'testhash'});
      }, 1000);
      
    } catch (e) {
      console.error('Error walletForgeItems:', e);
      if (forgeItemsFailure) {
        forgeItemsFailure({forgeItems, e});
      }
    }
  }


  const walletBulkAttach = async ({bulkAttach, bulkAttachSuccess, bulkAttachFailure}) => {
    try {

      let pairs = JSON.parse(bulkAttach.pairs);

      let assetNames = [];
      pairs.forEach(item => {
        if (item && item.raccoon && item.raccoon.raccoon && item.raccoon.raccoon.assetNameHex && item.itemToAttach && item.itemToAttach.nftOrInventory === 'nft' && item.itemToAttach.upgrade && item.itemToAttach.upgrade.assetNameHex) {
          let fullRaccoonAssetNameHex = `${RS_POLICY_ID}${item.raccoon.raccoon.assetNameHex}`;
          if (!assetNames.includes(fullRaccoonAssetNameHex)) {
            assetNames.push(fullRaccoonAssetNameHex);
          }

          let fullUpgradeAssetNameHex = `${RS_UPGRADES_POLICY_ID}${item.itemToAttach.upgrade.assetNameHex}`;
          assetNames.push(fullUpgradeAssetNameHex);
        } else if (item && item.raccoon && item.raccoon.raccoon && item.raccoon.raccoon.assetNameHex && item.itemToAttach && item.itemToAttach.nftOrInventory === 'inventoryItem' && item.itemToAttach.upgrade && item.itemToAttach.upgrade.status === 'inventoryItem') {
          let fullRaccoonAssetNameHex = `${RS_POLICY_ID}${item.raccoon.raccoon.assetNameHex}`;

          if (!assetNames.includes(fullRaccoonAssetNameHex)) {
            assetNames.push(fullRaccoonAssetNameHex);
          }
        }
      });

      console.log('ATTACH_UPGRADE_FEE is', ATTACH_UPGRADE_FEE);


      let numItems = 0n;
      let adaFee = 0n;
      if (pairs && pairs.forEach) {
        pairs.forEach(pair => {
          numItems += 1n;
          adaFee += ATTACH_UPGRADE_FEE;
        });
      }

      /*
      let adaFee = 0;
      if (numItems === 1) {
        adaFee = 4000000n
      } else if (numItems === 2) {
        adaFee = 8000000n
      } else if (numItems === 3) {
        adaFee = 12000000n
      } else if (numItems === 4) {
        adaFee = 16000000n
      } else if (numItems === 5) {
        adaFee = 20000000n
      } else if (numItems === 6) {
        adaFee = 24000000n
      } else if (numItems === 7) {
        adaFee = 28000000n
      } else if (numItems === 8) {
        adaFee = 32000000n
      } else if (numItems === 9) {
        adaFee = 36000000n
      } else if (numItems === 10) {
        adaFee = 40000000n
      }
      */
      
      let tx = await lucid
      .newTx()
      .validTo(Date.now() + 2000000000);
      
      // DISABLE DURING TESTING
      tx.payToAddress(ATTACH_UPGRADE_NFTS_WALLET_ADDRESS, { lovelace: adaFee})
      
      if (assetNames && assetNames.length > 0) {
        assetNames.forEach(assetName => {
          tx.payToAddress(ATTACH_UPGRADE_NFTS_WALLET_ADDRESS, { [assetName]: 1})
        });
      }

      const builtTx = await tx.complete();

      const signedTx = await builtTx.sign().complete();

      setTimeout(async () => {
        const trxHash = await signedTx.submit();
        bulkAttachSuccess({bulkAttach, trx_hash: trxHash});

        // SKIP SUBMITTING THE TRANSACTION
        // bulkAttachSuccess({bulkAttach, trx_hash: 'testhash'});
      }, 1000);

    } catch (e) {
      console.error('Error walletBulkAttach:', e);
      if (bulkAttachFailure) {
        bulkAttachFailure({bulkAttach, e});
      }
    }
  }


  const walletBulkDetach = async ({bulkDetach, bulkDetachSuccess, bulkDetachFailure}) => {
    try {

      let pairs = JSON.parse(bulkDetach.detachPairs);

      let assetNames = [];
      pairs.forEach(item => {
        let assetName = `Raccoon ${item.racNum}`;
        let assetNameHex = ascii_to_hexa(assetName);
        let fullAssetNameHex = `${RS_POLICY_ID}${assetNameHex}`;

        if (!assetNames.includes(fullAssetNameHex)) {
          assetNames.push(fullAssetNameHex);
        }
      });

      let numItems = 0n;
      let adaFee = 0n;
      if (pairs && pairs.forEach) {
        pairs.forEach(pair => {
          numItems += 1n;
          adaFee += DETACH_UPGRADE_FEE;
        });
      }
      
      let tx = await lucid
      .newTx()
      .validTo(Date.now() + 2000000000);
      
      // DISABLE DURING TESTING
      tx.payToAddress(DETACH_REQUESTS_WALLET_ADDRESS, { lovelace: adaFee})
      
      if (assetNames && assetNames.length > 0) {
        assetNames.forEach(assetName => {
          tx.payToAddress(DETACH_REQUESTS_WALLET_ADDRESS, { [assetName]: 1})
        });
      }

      const builtTx = await tx.complete();

      const signedTx = await builtTx.sign().complete();

      setTimeout(async () => {
        const trxHash = await signedTx.submit();
        bulkDetachSuccess({bulkDetach, trx_hash: trxHash});
        
        // SKIP SUBMITTING THE TRANSACTION
        // bulkDetachSuccess({bulkDetach, trx_hash: 'testhash'});
      }, 1000);
      
    } catch (e) {
      console.error('Error walletBulkDetach:', e);
      if (bulkDetachFailure) {
        bulkDetachFailure({bulkDetach, e});
      }
    }
  }



  const refetchRaccoonsSquads = async () => {
    refreshWallet();
  }

  const backgroundRefreshWallet = async () => {
    // refreshWallet({background: true});
  }

  const refreshWallet = async (parms) => {
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      setState({
        ...state,
        connectInProgress: true
      });

      

      let utxos = await lucid.wallet.getUtxos();
      if (utxos) {
        let originalRaccoonsInWallet = [];
        if (state.raccoonsInWallet && state.raccoonsInWallet.length > 0) {
          originalRaccoonsInWallet = [...state.raccoonsInWallet]
        }

        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];
        let bankerCardsInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(BANKCARD_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(BANKCARD_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  if (bankerCards.includes(assetNameAscii)) {
                    bankerCardsInWallet.push({
                      assetNameHex: assetNameHex,
                      assetNameAscii: assetNameAscii
                    });  
                  }
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
            getRaccoonsUpgradeStatus({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
          }
        }

        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();

          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          // setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0 && originalRaccoonsInWallet && originalRaccoonsInWallet.length > 0) {
          raccoonsInWallet.forEach(rac => {
            originalRaccoonsInWallet.forEach(oRac => {
              if (rac.assetNameHex === oRac.assetNameHex) {
                if (oRac.racNum) {
                  rac.racNum = oRac.racNum;
                }
                if (oRac.raccoonsInSquadILead) {
                  rac.raccoonsInSquadILead = oRac.raccoonsInSquadILead;
                }
                if (oRac.raccoonsInSquadImIn) {
                  rac.raccoonsInSquadImIn = oRac.raccoonsInSquadImIn;
                }
                if (oRac.squadILead) {
                  rac.squadILead = oRac.squadILead;
                }
                if (oRac.squadImIn) {
                  rac.squadImIn = oRac.squadImIn;  
                }
              }
            });
          });
        }

        setState({
          ...state,
          raccoonsInWallet,
          raccoonUpgradesInWallet,
          bankerCardsInWallet,
          doneInitialWalletLoad: true,
          connectInProgress: false,
          updateTime: Date.now()
        });
      } else {
        setState({
          ...state,
          connectInProgress: false,
          updateTime: Date.now()
        });
      }
    } else {
      setState({
        ...state,
        connectInProgress: false,
        updateTime: Date.now()
      });
    }
  }

  const connectNami = async () => {
    if (window && window.cardano && window.cardano.nami) {
      setState({
        ...state,
        connectInProgress: true
      });

      let api = null;
      try {
        api = await window.cardano.nami.enable();
        lucid.selectWallet(api);
        if (localStorage) {
          localStorage.setItem("preferredWallet", "Nami");
        }
      } catch (e) {
        setState({
          ...state,
          connectInProgress: false,
          updateTime: Date.now()
        });
      }

      setState({
        ...state,
        api,
        updateTime: Date.now()
      });
      setActiveWallet('Nami');
    }
  }

  const connectEternl = async () => {
    if (window && window.cardano && window.cardano.eternl) {
      setState({
        ...state,
        connectInProgress: true
      });

      let api = null;
      try {
        api = await window.cardano.eternl.enable();
        lucid.selectWallet(api);
        if (localStorage) {
          localStorage.setItem("preferredWallet", "Eternl");
        }
      } catch (e) {
        setState({
          ...state,
          connectInProgress: false,
          updateTime: Date.now()
        });
      }

      setState({
        ...state,
        api,
        updateTime: Date.now()
      });
      setActiveWallet('Eternl');
    }
  }

  const connectTyphon = async () => {
    if (window && window.cardano && window.cardano.typhoncip30) {
      setState({
        ...state,
        connectInProgress: true
      });

      let api = null;
      try {
        api = await window.cardano.typhoncip30.enable();
        lucid.selectWallet(api);
        if (localStorage) {
          localStorage.setItem("preferredWallet", "Typhon");
        }
      } catch (e) {
        setState({
          ...state,
          connectInProgress: false,
          updateTime: Date.now()
        });
      }

      setState({
        ...state,
        api,
        updateTime: Date.now()
      });
      setActiveWallet('Typhon');
    }
  }

  const connectFlint = async () => {
    if (window && window.cardano && window.cardano.flint) {
      setState({
        ...state,
        connectInProgress: true
      });

      let api = null;
      try {
        api = await window.cardano.flint.enable();
        lucid.selectWallet(api);
        if (localStorage) {
          localStorage.setItem("preferredWallet", "Flint");
        }
      } catch (e) {
        setState({
          ...state,
          connectInProgress: false,
          updateTime: Date.now()
        });
      }

      setState({
        ...state,
        api,
        updateTime: Date.now()
      });
      setActiveWallet('Flint');
    }
  }

  const connectGero = async () => {
    if (window && window.cardano && window.cardano.gerowallet) {
      setState({
        ...state,
        connectInProgress: true
      });

      let api = null;
      try {
        api = await window.cardano.gerowallet.enable();
        lucid.selectWallet(api);
        if (localStorage) {
          localStorage.setItem("preferredWallet", "Gero");
        }
      } catch (e) {
        setState({
          ...state,
          connectInProgress: false,
          updateTime: Date.now()
        });
      }

      setState({
        ...state,
        api,
        updateTime: Date.now()
      });
      setActiveWallet('Gero');
    }
  }

  const disconnectWallet = async () => {
    if (localStorage) {
      localStorage.setItem("preferredWallet", "None");
      setTimeout(() => {
        if (window && window.location && window.location.reload) {
          window.location.reload();
        }
      }, 500);
    }
    setActiveWallet(null);
  }


  const initialLoad = async () => {

    let preferredWallet = null;
    if (localStorage) {
      preferredWallet = localStorage.getItem("preferredWallet");
    }

    if (!preferredWallet || preferredWallet === 'None') {
      setState({
        ...state,
        doneInitialWalletLoad: true,
        updateTime: Date.now()
      });
      setTeam(false);
      return;
    }

    let haveNami = window.cardano && window.cardano.nami !== null;
    let haveEternl = window.cardano && window.cardano.eternl !== null;
    let haveTyphon = window.cardano && window.cardano.typhoncip30 !== null;
    let haveFlint = window.cardano && window.cardano.flint !== null;
    let haveGero = window.cardano && window.cardano.gerowallet !== null;

    let namiEnabled = false;
    if (haveNami && window.cardano && window.cardano.nami && await window.cardano.nami.isEnabled()) {
      namiEnabled = true;
    }

    let eternlEnabled = false;
    if (haveEternl && window.cardano && window.cardano.eternl && await window.cardano.eternl.isEnabled()) {
      eternlEnabled = true;
    }

    let typhonEnabled = false;
    if (haveTyphon && window.cardano && window.cardano.typhoncip30 && await window.cardano.typhoncip30.isEnabled()) {
      typhonEnabled = true;
    }
    
    let flintEnabled = false;
    if (haveFlint && window.cardano && window.cardano.flint && await window.cardano.flint.isEnabled()) {
      flintEnabled = true;
    }

    let geroEnabled = false;
    if (haveGero && window.cardano && window.cardano.gerowallet && await window.cardano.gerowallet.isEnabled()) {
      geroEnabled = true;
    }

    
    let api = null;
 
    let namiApi = null;
    let eternlApi = null;
    let typhonApi = null;
    let flintApi = null;
    let geroApi = null;

    let activeWallet = null;
    if (haveNami && namiEnabled && preferredWallet === 'Nami') {
      namiApi = await window.cardano.nami.enable();
      activeWallet = 'Nami';
    }
    if (haveEternl && eternlEnabled && preferredWallet === 'Eternl') {
      eternlApi = await window.cardano.eternl.enable();
      activeWallet = 'Eternl';
    }
    if (haveTyphon && typhonEnabled && preferredWallet === 'Typhon') {
      typhonApi = await window.cardano.typhoncip30.enable();
      activeWallet = 'Typhon';
    }
    if (haveFlint && flintEnabled && preferredWallet === 'Flint') {
      flintApi = await window.cardano.flint.enable();
      activeWallet = 'Flint';
    }
    if (haveGero && geroEnabled && preferredWallet === 'Gero') {
      geroApi = await window.cardano.gerowallet.enable();
      activeWallet = 'Gero';
    }

    if (namiApi) {
      api = namiApi;
    } else if (eternlApi) {
      api = eternlApi;
    } else if (typhonApi) {
      api = typhonApi;
    } else if (flintApi) {
      api = flintApi;
    } else if (geroApi) {
      api = geroApi;
    }

    let team = false;
    if (namiApi) {
      let rewads = await namiApi.getRewardAddresses();
      if (rewads.includes("e1420808f78fb4a30564013cbce025f45be8e2eaf78518d94359b1e78e")) {
        team = true;
      } else if (rewads.includes("e15d16aea273e27fd8fe7625f6f019c3a71bd27e635fd197c72bc961c3")) {
        team = true;
      } else if (rewads.includes("e1037050df283ebc587cc1834fe060c21759fe7dda9a900d5e13c5a0dc")) {
        team = true;
      }
    }
    if (eternlApi) {
      let rewads = await eternlApi.getRewardAddresses();
      if (rewads.includes("e1420808f78fb4a30564013cbce025f45be8e2eaf78518d94359b1e78e")) {
        team = true;
      } else if (rewads.includes("e15d16aea273e27fd8fe7625f6f019c3a71bd27e635fd197c72bc961c3")) {
        team = true;
      } else if (rewads.includes("e1037050df283ebc587cc1834fe060c21759fe7dda9a900d5e13c5a0dc")) {
        team = true;
      } else if (rewads.includes("e11503ed190aba8faa4235974671ed9b02b7422ce48709631edf4dcdc3")) {
        team = true;
      }
    }

    if (api) {
      lucid.selectWallet(api);
      setState({
        ...state,
        api,
        updateTime: Date.now()
      });
      setTeam(team);
      setActiveWallet(activeWallet);
    } else {
      setState({
        ...state,
        doneInitialWalletLoad: true,
        updateTime: Date.now()
      });
      setTeam(team);
      setActiveWallet(activeWallet);
    }
  }

  const value = {
    state,
    currentEpoch,
    numUnreadConversations,
    mailboxId,
    raccoonUpgradesApplied,
    team,
    regEpochs,
    teamEpochs,
    activeWallet,
    globalLucid,
    initialLoad,
    refetchRaccoonsSquads,
    backgroundRefreshWallet,
    walletSendConsumableUpgradeNFT,
    walletSendAttachableUpgradeNFT,
    walletSendDetachRequest,
    walletSendConsumableUpgradeFromInventory, 
    walletSendAttachableUpgradeFromInventory, 
    walletMintInventoryItem,
    walletGetSpins,
    walletForgeItems,
    walletForgeItemsAdv,
    walletBulkAttach,
    walletBulkDetach,
    connectNami,
    connectEternl,
    connectTyphon,
    connectFlint,
    connectGero,
    disconnectWallet
  };

  return <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>;
}

