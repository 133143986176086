import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useMutation,
  useQuery, 
  useLazyQuery,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import RaccoonAvatar from './RaccoonAvatar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}

const SQUAD_ADD_MY_RACCOON = gql`
  mutation SquadAddMyRaccoon($officerRaccoonNum: Int!, $raccoonNum: Int!, $myWalletAddresses: String!, $isJoinRequest: String ) {
    squadAddMyRaccoon( officerRaccoonNum: $officerRaccoonNum, raccoonNum: $raccoonNum, myWalletAddresses: $myWalletAddresses, isJoinRequest: $isJoinRequest ) {
      result
      logEntries
      newSquadInfo
      squads
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage($fromRaccoonNum: Int!, $toRaccoonNum: Int!, $messageContents: String!, $messageType: String! ) {
    sendMessage (fromRaccoonNum: $fromRaccoonNum, toRaccoonNum: $toRaccoonNum, messageContents: $messageContents, messageType: $messageType ) {
      result
      logEntries
    }
  }
`;

const GET_CONVERSATIONS = gql`
  query GetConversations($myRaccoons: String!, $myWalletAddresses: String!) {
    conversations(myRaccoons: $myRaccoons, myWalletAddresses: $myWalletAddresses) {
      result
      conversations
    }
  }
`;

const GET_RACCOON_INFO_FOR_NFT_LIST = gql`
  query GetRaccoonInfoForNFTList($raccoons: String!) {
    raccoonInfoForNFTList(raccoons: $raccoons) {
      raccoonInfo
    }
  }
`;

const GET_RACCOONS_SQUADS = gql`
  query GetRaccoonsSquads($raccoonNumbers: String!, $source: String) {
    raccoonsSquads(raccoonNumbers: $raccoonNumbers, source: $source) {
      results
      source
    }
  }
`;

const GET_RACCOONS_UPGRADE_STATUS = gql`
  query GetRaccoonsUpgradeStatus($raccoonNumbers: String!) {
    raccoonsUpgradeStatus(raccoonNumbers: $raccoonNumbers) {
      results
      upgrades
    }
  }
`;

const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;

const sortOptionsFields = [
  { value: 'Raccoon #', label: 'Raccoon #' },
  { value: 'BPM', label: 'BPM' },
  { value: 'Rank', label: 'Rank' },
  { value: 'Tier Rank', label: 'Tier Rank' },
];

const sortOptionsOrder = [
  { value: 'Descending', label: 'Descending' },
  { value: 'Ascending', label: 'Ascending' },
];

const filterOptionsProfession = [
  { value: 'All Professions', label: 'All Professions' },
  { value: 'Officers', label: 'Officers' },
  { value: 'Non-Officers', label: 'Non-Officers' },
];

const filterOptionsTier = [
  { value: 'All Tiers', label: 'All Tiers' },
  { value: 'Tier 1', label: 'Tier 1' },
  { value: 'Tier 2', label: 'Tier 2' },
  { value: 'Tier 3', label: 'Tier 3' },
];

const filterOptionsAvailability = [
  { value: 'Available', label: 'Available' },
  { value: 'In Squad', label: 'In Squad' },
  { value: 'Any', label: 'Any' },
];

const sortOrderStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: '#000', 
    color: '#efdbb8',
    outline: 'none',
    borderColor: '#444',
    boxShadow: 'none',
    borderRadius: '10px',
    "&:hover"  : {
      outline: 'none',
      borderColor: '#444',
      boxShadow: 'none',
    },
  }),
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none',
      "&:hover"  : {
        backgroundColor: '#222',
      },
    };
  },
  singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
}


const SquadAddModal = (props) => {
  let {show, close, officerRaccoonNum, squadRaccoonLeads, isJoinRequest, updateOfficerSquadInfo, hideAddButtonOnRaccoonPage} = props;

  const [tab, setTab] = useState('add');
  const [messageToRaccoon, setMessageToRaccoon] = useState("");

  const [showSentMessage, setShowSentMessage] = useState(false);
  const [showFailRaccoonNotFound, setShowFailRaccoonNotFound] = useState(false);
  const [showFailRaccoonIsOfficer, setShowFailRaccoonIsOfficer] = useState(false);

  const [toastColor, setToastColor] = useState('#e08705');

  const { state, globalLucid, refetchRaccoonsSquads } = useContext(GlobalStateContext);

  const [sortField, setSortField] = useState('Raccoon #');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [filterProfessionValue, setFilterProfessionValue] = useState('Non-Officers');
  const [filterTierValue, setFilterTierValue] = useState('All Tiers');
  const [filterAvailabilityValue, setFilterAvailabilityValue] = useState('Available');

  const [raccoonsInWallet, setRaccoonsInWallet] = useState([]);
  const [raccoonsInWalletLocal, setRaccoonsInWalletLocal] = useState([]);
  const [raccoonNumsInWalletLocal, setRaccoonNumsInWalletLocal] = useState([]);
  const [raccoonInfoLocal, setRaccoonInfoLocal] = useState([]);
  const [raccoonSquadInfo, setRaccoonSquadInfo] = useState([]);
  
  const [hideAddButton, setHideAddButton] = useState(false);

  const [doneRaf, setDoneRaf] = useState(false);

  const [getRaccoonsSquads, { loading: loadingGetRaccoonsSquads, error: errorGetRaccoonsSquads, data: dataGetRaccoonsSquads, refetch: refetchGetRaccoonsSquads }] = useLazyQuery(GET_RACCOONS_SQUADS);
  
  const [getRaccoonsUpgradeStatus, { loading: loadingGetRaccoonsUpdateStatus, error: errorGetRaccoonsUpdateStatus, data: dataGetRaccoonsUpdateStatus, refetch: refetchGetRaccoonsUpdateStatus }] = useLazyQuery(GET_RACCOONS_UPGRADE_STATUS);

  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        setDoneRaf(true);
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          // setMailboxId(response.setReadMessagesTime.mailboxId);
        }
    }
  });

  const localRefreshWallet = async (parms) => {
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
            getRaccoonsUpgradeStatus({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
            getRaccoonInfoForNFTList({variables: {raccoons: JSON.stringify(raccoonNums)}});
          }
        }

        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();
          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
        }

        setRaccoonsInWallet(raccoonsInWallet);
      }
    }
  }

  /*
  useEffect(() => {
    let raccoonsInWallet = null;
    if (state && state.raccoonsInWallet) {
      raccoonsInWallet = state.raccoonsInWallet;
    }
  
    let raccoonNumsInWallet = [];
    if (raccoonsInWallet) {
      raccoonsInWallet.forEach(rac => {
        raccoonNumsInWallet.push(rac.racNum);
      })
    }

    setRaccoonsInWalletLocal(raccoonsInWallet);
    setRaccoonNumsInWalletLocal(raccoonNumsInWallet);

    getRaccoonInfoForNFTList({variables: {raccoons: JSON.stringify(raccoonNumsInWallet)}});
  }, [show])
*/

useEffect(() => {
  if (globalLucid) {
    localRefreshWallet();
  }
}, [show])

useEffect(() => {
  if (dataGetRaccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads.results) {
    let newRaccoonsInWallet = [];
    let results = JSON.parse(dataGetRaccoonsSquads.raccoonsSquads.results);
    if (results && results.forEach ) {
      let racSquadInfo = {};
      results.forEach(result => {
        if (result && (result.raccoonsInSquadILead || result.raccoonsInSquadImIn || result.squadILead || result.squadImIn)) {
          racSquadInfo[result.racNum] = result;
        }
      });

/*
      if (raccoonsInWallet && raccoonsInWallet.forEach) {
        raccoonsInWallet.forEach(rac => {
          let newRac = {...rac};
          let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
          let racNum = parseInt(racNumStr);
          if (racSquadInfo[racNum]) {
            newRac.racNum = racSquadInfo[racNum].racNum;
            newRac.raccoonsInSquadILead = racSquadInfo[racNum].raccoonsInSquadILead;
            newRac.raccoonsInSquadImIn = racSquadInfo[racNum].raccoonsInSquadImIn;
            newRac.squadILead = racSquadInfo[racNum].squadILead;
            newRac.squadImIn = racSquadInfo[racNum].squadImIn;
          }
          newRaccoonsInWallet.push(newRac);
        });
      }
*/
      setRaccoonSquadInfo({...racSquadInfo});
    }
  }
}, [dataGetRaccoonsSquads, loadingGetRaccoonsSquads]);

  const [getRaccoonInfoForNFTList, { loading: loadingGetRaccoonInfoForNFTList, error: errorGetRaccoonInfoForNFTList, data: dataGetRaccoonInfoForNFTList, refetch: refetchGetRaccoonInfoForNFTList }] = useLazyQuery(GET_RACCOON_INFO_FOR_NFT_LIST);


  const [squadAddMyRaccoon, { data: dataSquadAddMyRaccoon, loading: loadingSquadAddMyRaccoon, error: errorSquadAddMyRaccoon }] = useMutation(SQUAD_ADD_MY_RACCOON, 
    {
      onCompleted: response => {
        setTimeout(() => {
          setHideAddButton(false);
          hideAddButtonOnRaccoonPage(false);
        }, 500);
        if (response && response.squadAddMyRaccoon && response.squadAddMyRaccoon.result) {
          if (response.squadAddMyRaccoon.result === 'Fail - Raccoon is already a member of a squad') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Raccoon already in squad. Must leave current squad first.")
            }, 200);
          } else if (response.squadAddMyRaccoon.result === 'Fail - Cannot add an officer to a squad') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Cannot add an officer to a squad.")
            }, 200);
          } else if (response.squadAddMyRaccoon.result === 'Fail - Squad is full') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("The squad is already full.")
            }, 200);
          } else if (response.squadAddMyRaccoon.result === 'success') {
            setToastColor('#14711f');
            setTimeout(() => {
              if (response.squadAddMyRaccoon && response.squadAddMyRaccoon.newSquadInfo) {
                let newSquadInfo = JSON.parse(response.squadAddMyRaccoon.newSquadInfo);
              }
              if (response.squadAddMyRaccoon && response.squadAddMyRaccoon.squads) {
                let newSquads = JSON.parse(response.squadAddMyRaccoon.squads);
                if (newSquads && newSquads.length > 0) {
                  let newRaccoonsInWalletLocal = [];
                  if (raccoonsInWallet && raccoonsInWallet.length > 0) {
                    raccoonsInWallet.forEach(racLocal => {
                      let foundMatch = null;
                      let raccoonNum = parseInt(racLocal.assetNameAscii.substring("Raccoon ".length, racLocal.assetNameAscii.length));
                      newSquads.forEach(newSquad => {
                        if (raccoonNum === newSquad.racNum) {
                          foundMatch = newSquad;
                          if (newSquad.racNum === parseInt(officerRaccoonNum)) {
                            updateOfficerSquadInfo(newSquad);
                          }
                        }
                      });
                      if (foundMatch) {
                        newRaccoonsInWalletLocal.push({
                          ...racLocal,
                          raccoonsInSquadILead: foundMatch.raccoonsInSquadILead,
                          raccoonsInSquadImIn: foundMatch.raccoonsInSquadImIn,
                          squadILead: foundMatch.squadILead,
                          squadImIn: foundMatch.squadImIn
                        })
                      } else {
                        newRaccoonsInWalletLocal.push(racLocal);
                      }
                    });
                    setRaccoonsInWalletLocal(newRaccoonsInWalletLocal);
                  }
                }
              }
              toast(`Raccoon added to squad.`);
              refetchRaccoonsSquads();
            }, 200);
          }
        }
    }
  });

  const [sendMessage, { data: dataSendMessage, loading: loadingSendMessage, error: errorSendMessage }] = useMutation(SEND_MESSAGE, 
    {
      refetchQueries: [{ query: GET_CONVERSATIONS, variables: { myRaccoons: JSON.stringify(raccoonNumsInWalletLocal), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  }],
      onCompleted: response => {
        if (response && response.sendMessage && response.sendMessage.result) {
          let result = response.sendMessage.result;
          if (result === 'Fail - to raccoon not found') {
            setShowSentMessage(false);
            setShowFailRaccoonIsOfficer(false);
            setShowFailRaccoonNotFound(true);
          } else if (result === 'Fail - Cannot invite a Captain or Genereal to join a squad') {
            setShowSentMessage(false);
            setShowFailRaccoonIsOfficer(true);
            setShowFailRaccoonNotFound(false);
          } else {
            setShowSentMessage(true);
          }
        }
    }
  });

  useEffect(() => {
    if (dataGetRaccoonInfoForNFTList && dataGetRaccoonInfoForNFTList.raccoonInfoForNFTList && dataGetRaccoonInfoForNFTList.raccoonInfoForNFTList.raccoonInfo) {
        let raccoonInfoObj = JSON.parse(dataGetRaccoonInfoForNFTList.raccoonInfoForNFTList.raccoonInfo);
        setRaccoonInfoLocal(raccoonInfoObj);
    }
  }, [dataGetRaccoonInfoForNFTList])

  const onClickSquadAddMyRaccoon = (raccoonNum) => {
    setHideAddButton(true);
    hideAddButtonOnRaccoonPage(true);
    squadAddMyRaccoon({ variables: { officerRaccoonNum: parseInt(officerRaccoonNum), raccoonNum: parseInt(raccoonNum), myWalletAddresses: 'testAddresses', isJoinRequest: `${isJoinRequest}` } });
  }

  if (!show) {
    return <div />
  }

  let newRaccoonsInWallet = [];
  if (raccoonsInWallet && raccoonsInWallet.length > 0) {
    raccoonsInWallet.forEach(rac => {
      let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
      let racNum = parseInt(racNumStr);

      let newRaccoon = {};
      if (raccoonInfoLocal[racNum]) {
        newRaccoon = {
          ...rac, 
          bpm: parseFloat(raccoonInfoLocal[racNum].bpm),
          rank: raccoonInfoLocal[racNum].rank,
          tier: raccoonInfoLocal[racNum].tier,
          tierRank: raccoonInfoLocal[racNum].tierRank
        }
      } else {
        newRaccoon = {
          ...rac, 
        }
      }

      let racSquadInfo = raccoonSquadInfo[racNum];
      if (!racSquadInfo) {
        racSquadInfo = {};
      }

      let meetsAvailabilityFilter = false;
      if (filterAvailabilityValue === 'Any') {
        meetsAvailabilityFilter = true;
      } else if (filterAvailabilityValue === 'Available' && !racSquadInfo.squadImIn && !racSquadInfo.squadILead) {
        meetsAvailabilityFilter = true;
      } if (filterAvailabilityValue === 'In Squad' && (racSquadInfo.squadImIn || racSquadInfo.squadILead)) {
        meetsAvailabilityFilter = true;
      }

      
      let meetsTierFilter = false;
      if (meetsAvailabilityFilter) {
        if (filterProfessionValue === 'All Professions') {
          meetsTierFilter = true;
        } else if (filterProfessionValue === 'Officers' && racSquadInfo.squadILead) {
          meetsTierFilter = true;
        } else if (filterProfessionValue === 'Non-Officers' && !racSquadInfo.squadILead) {
          meetsTierFilter = true;
        }
      }

      if (meetsTierFilter && meetsAvailabilityFilter) {
        if (filterTierValue === 'All Tiers') {
          newRaccoonsInWallet.push(newRaccoon);
        } else if (filterTierValue === 'Tier 1' && raccoonInfoLocal[racNum].tier === 1) {
          newRaccoonsInWallet.push(newRaccoon);
        } else if (filterTierValue === 'Tier 2' && raccoonInfoLocal[racNum].tier === 2) {
          newRaccoonsInWallet.push(newRaccoon);
        } else if (filterTierValue === 'Tier 3' && raccoonInfoLocal[racNum].tier === 3) {
          newRaccoonsInWallet.push(newRaccoon);
        }        
      }

    });
  }
  let raccoonsInWalletDisplay = newRaccoonsInWallet;

  const sortRaccoonsInWallet = (oldRaccoonsInWallet) => {
    if (sortField === 'Raccoon #' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.racNum < b.racNum) return -1;
        if (a.racNum > b.racNum) return 1;
        return 0;
      });
    } else if (sortField === 'Raccoon #' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.racNum < b.racNum) return 1;
        if (a.racNum > b.racNum) return -1;
        return 0;
      });
    } else if (sortField === 'BPM' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.bpm < b.bpm) return -1;
        if (a.bpm > b.bpm) return 1;
        return 0;
      });
    } else if (sortField === 'BPM' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.bpm < b.bpm) return 1;
        if (a.bpm > b.bpm) return -1;
        return 0;
      });
    } else if (sortField === 'Rank' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.rank < b.rank) return -1;
        if (a.rank > b.rank) return 1;
        return 0;
      });
    } else if (sortField === 'Rank' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.rank < b.rank) return 1;
        if (a.rank > b.rank) return -1;
        return 0;
      });
    } else if (sortField === 'Tier Rank' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.tierRank < b.tierRank) return -1;
        if (a.tierRank > b.tierRank) return 1;
        return 0;
      });
    } else if (sortField === 'Tier Rank' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.tierRank < b.tierRank) return 1;
        if (a.tierRank > b.tierRank) return -1;
        return 0;
      });
    }
    return oldRaccoonsInWallet;
  }

  if (raccoonsInWalletDisplay && raccoonsInWalletDisplay.forEach) {
    raccoonsInWalletDisplay.forEach(rac => {
      let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
      let racNum = parseInt(racNumStr);
      rac.racNum = racNum;
    });
  }
  raccoonsInWalletDisplay = sortRaccoonsInWallet(raccoonsInWalletDisplay);

  return (
    <>
      <div className="squad_add_modal_outside_container"  onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowSentMessage(false);
        setShowFailRaccoonIsOfficer(false);
        setShowFailRaccoonNotFound(false);
        close();
      }}>
        <div className="squad_add_modal" onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}>
          <div className="squad_add_modal_header_box">
          <div className="leaderboard_title_row">
          {!isJoinRequest &&
            <div className="global_leaderboard_title_text">
              <div className={tab === 'add' ? "squad_add_modal_header_selected" : "squad_add_modal_header_unselected"} onClick={() => setTab('add')}>ADD MY RACCOON</div>
                <div className="tier_leaderboard_text_separator">|</div>
              <div className={tab === 'invite' ? "squad_add_modal_header_selected" : "squad_add_modal_header_unselected"} onClick={() => setTab('invite')}>INVITE RACCOON</div> 
            </div>      
          }
          {isJoinRequest &&
            <div className="global_leaderboard_title_text">
              <div className={"squad_add_modal_header_selected"}>JOIN SQUAD</div>
            </div>      
          }
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
          
          {tab === 'add' && (
            <div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
              <div style={{color: '#efdbb8', marginLeft: '48px', marginRight: '10px'}}>
                Sort by:
              </div>
              <div style={{width: '160px'}}>
                <Select 
                  options={sortOptionsFields} 
                  defaultValue={{value: sortField, label: sortField}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setSortField(option.value);
                    }
                  }}
                />
              </div>
              <div style={{width: '160px', marginLeft: '30px'}}>
                <Select 
                  options={sortOptionsOrder} 
                  defaultValue={{value: sortDirection, label: sortDirection}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setSortDirection(option.value);
                    }
                  }}
                />
              </div>
              <div style={{width: '180px', marginLeft: '30px'}}>
                <Select 
                  options={filterOptionsProfession} 
                  defaultValue={{value: filterProfessionValue, label: filterProfessionValue}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setFilterProfessionValue(option.value);
                    }
                  }}
                />
              </div>
              <div style={{width: '160px', marginLeft: '30px'}}>
                <Select 
                  options={filterOptionsTier} 
                  defaultValue={{value: filterTierValue, label: filterTierValue}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setFilterTierValue(option.value);
                    }
                  }}
                />
              </div>                
              <div style={{width: '145px', marginLeft: '30px'}}>
                <Select 
                  options={filterOptionsAvailability} 
                  defaultValue={{value: filterAvailabilityValue, label: filterAvailabilityValue}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setFilterAvailabilityValue(option.value);
                    }
                  }}
                />
              </div>              
            </div>
          )}
          
          {tab === 'add' && (!raccoonsInWalletDisplay || raccoonsInWalletDisplay.length < 1) && (
            <div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
              <div className="send_invite_result">You have no non-officer Raccoons (Staff + Soldier Profession) to add.</div>
            </div>
          )}
          
          {!isJoinRequest && tab === 'add' && raccoonsInWalletDisplay && raccoonsInWalletDisplay.length > 0 && 
            <div className="squad_add_modal_nfts_container">
                {raccoonsInWalletDisplay && raccoonsInWalletDisplay.length > 0 &&
                  raccoonsInWalletDisplay.map(raccoon => {
                    let asciiName = raccoon.assetNameAscii;
                    let raccoonNum = asciiName.substring('Raccoon '.length, asciiName.length);

                    let thisRaccoonInfo = {};
                    if (thisRaccoonInfo) {
                      thisRaccoonInfo = raccoonInfoLocal[raccoonNum];
                    }

                    let racSquadInfo = raccoonSquadInfo[parseInt(raccoonNum)];
                    if (!racSquadInfo) {
                      racSquadInfo = {};
                    }

                    return (
                      <a key={`squadaddmodal_${raccoonNum}`} href={`/raccoon?r=${raccoonNum}`} className="my_nfts_nft_box" style={{textDecoration: 'none', cursor: 'initial'}}>
                        <div >
                          <img src={`https://rs9.s3.us-east-1.amazonaws.com/n/${raccoonNum}.png?cb=${Date.now()}`} className="my_nfts_nft_image" />
                          <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                            <div className="my_nfts_nft_label" style={{flex: 0.7}}>
                              <div style={{textDecoration: 'none', fontSize: '16px'}}>Raccoon #{raccoonNum}</div>
                              {racSquadInfo.squadImIn && 
                                <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}>Current Squad #{racSquadInfo.squadImIn.squadNum}</div>
                              }
                              {racSquadInfo.squadILead && 
                                <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}>Leads Squad #{racSquadInfo.squadILead.squadNum}</div>
                              }
                              {!racSquadInfo.squadImIn && !racSquadInfo.squadILead && 
                                <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}> </div>
                              }
                            </div>
                            {doneRaf && !racSquadInfo.squadImIn && !racSquadInfo.squadILead &&
                              <div className="squad_add_modal_add_button_container">
                                <div 
                                  className="squad_add_modal_add_button" 
                                  onClick={!hideAddButton ? () => onClickSquadAddMyRaccoon(raccoonNum) : undefined}
                                  style={{
                                    cursor: !hideAddButton ? 'pointer' : 'auto',
                                    backgroundImage: !hideAddButton ? undefined : 'inherit',
                                    backgroundCOlor: '1px solid #444',
                                    border: '1px solid #777'
                                  }}
                                >
                                  Add
                                </div>
                              </div>
                            }
                            {(racSquadInfo.squadImIn || racSquadInfo.squadILead) &&
                              <div className="squad_add_modal_add_button_container">
                                <div 
                                  className="squad_add_modal_view_button" 
                                  onClick={() =>  window.open(`/raccoon?r=${raccoonNum}`, '_blank').focus()}
                                >
                                  View
                                </div>
                              </div>
                            }
                          </div>
                          <div className="my_nfts_meta_row_1">
                            {thisRaccoonInfo &&
                              <>
                              <div className="my_nfts_meta_cell" style={{fontSize: '15px'}}>
                                {thisRaccoonInfo.rank && <span>Rank: {thisRaccoonInfo.rank}</span>}
                              </div>
                              <div className="my_nfts_meta_cell" style={{justifyContent: 'flex-end', fontSize: '15px'}}>
                                BPM {thisRaccoonInfo.bpm}
                              </div>                        
                              </>
                            }
                          </div>
                          <div className="my_nfts_meta_row_2">
                          {thisRaccoonInfo &&
                            <>
                            <div className="my_nfts_meta_cell">
                              {thisRaccoonInfo.rank && <span>Tier Rank: {thisRaccoonInfo.tierRank}</span>}
                            </div>
                            <div className="my_nfts_meta_cell" style={{justifyContent: 'flex-end'}}>
                              Tier {thisRaccoonInfo.tier}
                            </div>                        
                            </>
                          }
                        </div>                               
                        </div>
                      </a>
                    )
                  })
                }
            </div>
          }

          {!isJoinRequest && tab === 'invite' && raccoonsInWallet && raccoonsInWallet.length > 0 && 
            <div className="message_page_message_container" style={{backgroundColor: '#000', padding : '10px'}}>
                  <div className="message_page_row" style={{padding: '10px'}}>
                    <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center', width: '200px', minWidth: '200px'}}>
                      Invite to Squad #{squadRaccoonLeads.squadNum}
                    </div>
                  </div>
                  <div className="message_page_row" style={{padding: '10px'}}>
                    <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center', width: '200px', minWidth: '200px'}}>
                      From Raccoon #{squadRaccoonLeads.officerRaccoonNum}
                    </div>
                    <div className="message_page_row_value">
                      <RaccoonAvatar racNum={squadRaccoonLeads.officerRaccoonNum} /> 
                    </div>
                  </div>
                  <div className="message_page_row" style={{padding: '10px'}}>
                    <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center', width: '200px', minWidth: '200px'}}>
                      To (Raccoon #)
                    </div>
                    <div className="message_page_row_value">
                      <input 
                        type="text" 
                        className="global_leaderboard_search_box_text_input" 
                        placeholder="eg: 4321"
                        value={messageToRaccoon} 
                        onChange={e => {
                          let inputString = e.target.value;
                          inputString = inputString.replace(/\D/g,'');
                          if (inputString.length > 4) inputString = inputString.substring(0, 4);
                          setShowSentMessage(false);
                          setShowFailRaccoonIsOfficer(false);
                          setShowFailRaccoonNotFound(false);
                          setMessageToRaccoon(inputString);
                        }}
                        style={{
                          backgroundColor: '#222',
                          padding: '10px',
                          width: '100px',
                          flexGrow: 'inherit'
                        }}
                      />
                    </div>
                  </div>
                  <div className="message_page_row" style={{padding: '10px'}}>
                    <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center', width: '200px', minWidth: '200px'}}>
                      Actions
                    </div>
                    <div className="message_page_row_value">
                      <div>
                        <div 
                          className="squad_add_modal_add_button" style={{marginRight: '30px'}}
                          onClick={() => {
                            sendMessage({ variables: { fromRaccoonNum: parseInt(officerRaccoonNum), toRaccoonNum: parseInt(messageToRaccoon), messageContents: 'squadInvite', messageType: 'squadInvite'  } });
                          }}
                        >
                          Send Invite
                        </div>
                      </div>
                    </div>
                </div>
            </div>
          }

          {isJoinRequest && !showSentMessage && raccoonsInWalletDisplay && raccoonsInWalletDisplay.length > 0 && 
            <div className="squad_add_modal_nfts_container">
              {raccoonsInWalletDisplay && raccoonsInWalletDisplay.length > 0 &&
                raccoonsInWalletDisplay.map(raccoon => {
                  let asciiName = raccoon.assetNameAscii;
                  let raccoonNum = asciiName.substring('Raccoon '.length, asciiName.length);

                  let racSquadInfo = raccoonSquadInfo[parseInt(raccoonNum)];
                  if (!racSquadInfo) {
                    racSquadInfo = {};
                  }

                  return (
                    <div className="my_nfts_nft_box" style={{cursor: 'initial'}}>
                      <div>
                        <img src={`https://rs9.s3.us-east-1.amazonaws.com/n/${raccoonNum}.png?cb=${Date.now()}`} className="my_nfts_nft_image" />
                        <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                          <div className="my_nfts_nft_label" style={{flex: 0.7}}>
                            <div style={{textDecoration: 'none'}}>Raccoon #{raccoonNum}</div>
                              {racSquadInfo.squadImIn && 
                                <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}>Current Squad #{racSquadInfo.squadImIn.squadNum}</div>
                              }
                              {racSquadInfo.squadILead && 
                                <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}>Leads Squad #{racSquadInfo.squadILead.squadNum}</div>
                              }
                              {!racSquadInfo.squadImIn && !racSquadInfo.squadILead && 
                                <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}> </div>
                              }
                          </div>
                          {doneRaf && !racSquadInfo.squadILead && !racSquadInfo.squadImIn &&
                            <div className="squad_add_modal_add_button_container">
                              <div className="squad_add_modal_add_button" style={{}} onClick={() => {
                                sendMessage({ variables: { fromRaccoonNum: parseInt(raccoonNum), toRaccoonNum: parseInt(officerRaccoonNum), messageContents: 'squadJoinRequest', messageType: 'squadJoinRequest'  } });
                              }}>
                                Join
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }

          {!isJoinRequest && tab === 'invite' && showSentMessage && messageToRaccoon && (
            <>
              <div className="send_invite_result">An invite message has been sent to Raccoon #{messageToRaccoon}. The invitation can be seen by clicking 'My Messages' in the menu at the top right of this page. To send an invitation to another Raccoon, update the "To (Raccoon #)" value above and click "Send Invite" again.</div>
              <div className="send_invite_result">To send an invitation to another Raccoon, update the "To (Raccoon #)" value above and click "Send Invite" again.</div>
            </>
          )}

          {!isJoinRequest && tab === 'invite' && showFailRaccoonNotFound && messageToRaccoon && (
            <>
              <div className="send_invite_result">Raccoon #{messageToRaccoon} not found. Check the value of "To (Raccoon #)" and try again.</div>
            </>
          )}

          {!isJoinRequest && tab === 'invite' && showFailRaccoonIsOfficer && messageToRaccoon && (
            <>
              <div className="send_invite_result">Raccoon #{messageToRaccoon} is a Captain or General. Captains and Generals cannot be invited to join a squad.</div>
            </>
          )}

          {isJoinRequest && showSentMessage && (
            <>
              <div className="send_invite_result">A squad join request has been sent to Raccoon #{officerRaccoonNum}. The request can be seen by clicking 'My Messages' in the menu at the top right of this page.</div>
              <div className="send_invite_result">Click the 'X' button at the top right to close this screen.</div>
            </>
          )}

        </div>
        </div>
        <div className="squad_add_modal_close_button" onClick={() => {
          setShowSentMessage(false);
          setShowFailRaccoonIsOfficer(false);
          setShowFailRaccoonNotFound(false);
          close();
        }}>
          X
        </div>
      </div>
      <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={6000} />
    </>
  );
};


export default SquadAddModal;

