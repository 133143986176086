import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useQuery, 
  useLazyQuery,
  useMutation,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import UpgradeUseModal from './UpgradeUseModal';
import { detect } from 'detect-browser';
import ClipLoader from "react-spinners/ClipLoader";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { CEASE_FIRE, COMPUTE_IN_PROGRESS } from '../gameConstants';

const browser = detect();

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}

const GET_RACCOONS_SQUADS = gql`
  query GetRaccoonsSquads($raccoonNumbers: String!, $source: String) {
    raccoonsSquads(raccoonNumbers: $raccoonNumbers, source: $source) {
      results
      source
    }
  }
`;

const GET_RACCOONS_UPGRADE_STATUS = gql`
  query GetRaccoonsUpgradeStatus($raccoonNumbers: String!) {
    raccoonsUpgradeStatus(raccoonNumbers: $raccoonNumbers) {
      results
      upgrades
    }
  }
`;

const GET_UPGRADE_STATUS = gql`
  query GetUpgradeStatus($upgradeNames: String!) {
    upgradeStatus(upgradeNames: $upgradeNames) {
      results
      upgrades
    }
  }
`;

const UPGRADE_BONUS_RATES = {
  'Milk': 30,
  'Canteen': 50,
  'Vegebite': 60,
  'Baked Beans': 50,
  'PotionX': 60,
  'Gummy Raccoon': 65,
  'Mushrooms': 70
};

const GET_RACCOON_INFO_FOR_NFT_LIST = gql`
  query GetRaccoonInfoForNFTList($raccoons: String!) {
    raccoonInfoForNFTList(raccoons: $raccoons) {
      raccoonInfo
    }
  }
`;

const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;

const TRX_CREATE_WATCH = gql`
  mutation TRXCreateWatch($assetName: String!, $requestType: String!, $inventoryItemId: String, $quantity: Int!, $action: String!, $myWalletAddresses: String!, $raccoonNum: Int) {
    trxCreateWatch( assetName: $assetName, requestType: $requestType, inventoryItemId: $inventoryItemId, quantity: $quantity, action: $action, myWalletAddresses: $myWalletAddresses, raccoonNum: $raccoonNum ) {
      result
      assetNameHex
      raccoonNum
      upgradeType
      consumableOrAttachable
      requestType
      inventoryItemId
      watchId
      operationId
    }
  }
`;


const BULK_USE_CONSUMABLES = gql`
  mutation BulkUseConsumables($chosenConsumables: String!) {
    bulkUseConsumables( chosenConsumables: $chosenConsumables ) {
      result
      chosenNFTConsumables
      chosenInventoryConsumables
      watchId
    }
  }
`;


const GET_INVENTORY = gql`
  query GetInventory {
    inventory {
      id
      type
      status
      earned_method
      was_listed_raccoon_at_end_of_battle_round
    }
  }
`;



let upgradeRanks = {
  'Milk': 7,
  'Canteen': 6,
  'Vegebite': 5,
  'Baked Beans': 4,
  'PotionX': 3,
  'Gummy Raccoon': 2,
  'Mushrooms': 1,
  'Gold Ring': 24,
  'Emerald Ring': 23,
  'Ruby Ring': 22,
  'Diamond Ring': 21,
  'CompoundX Ring': 20,
  'Blue Diamond Ring': 19,
  'Light Wings': 18,
  'Dark Wings': 17,
  'Gold Wings': 16,
  'Psychedelic Wings': 15,
  'CompoundX Wings': 14,
  'Blue Diamond Wings': 13,
  'Trash Can': 12,
  'Green Squad Potion': 45,
  'Blue Squad Potion': 44,
  'Red Squad Potion': 43,
  'Purple Squad Potion': 42
}


const sortOptionsFields = [
  { value: 'Raccoon #', label: 'Raccoon #' },
  { value: 'BPM', label: 'BPM' },
  { value: 'Rank', label: 'Rank' },
  { value: 'Tier Rank', label: 'Tier Rank' },
];

const sortOptionsOrder = [
  { value: 'Descending', label: 'Descending' },
  { value: 'Ascending', label: 'Ascending' },
];

const filterOptionsProfession = [
  { value: 'All Professions', label: 'All Professions' },
  { value: 'Officers', label: 'Officers' },
  { value: 'Non-Officers', label: 'Non-Officers' },
];

const filterOptionsTier = [
  { value: 'All Tiers', label: 'All Tiers' },
  { value: 'Tier 1', label: 'Tier 1' },
  { value: 'Tier 2', label: 'Tier 2' },
  { value: 'Tier 3', label: 'Tier 3' },
];

const filterOptionsAvailability = [
  { value: 'Available', label: 'Available' },
  { value: 'In Squad', label: 'In Squad' },
  { value: 'Any', label: 'Any' },
];

const filterOptionsBoostUpcoming = [
  { value: 'No Epoch Trait Upcoming', label: 'No Epoch Trait Upcoming' },
  { value: 'Epoch Trait Upcoming', label: 'Epoch Trait Upcoming' },
  { value: 'Any', label: 'Any' },
];

const filterOptionsUpgrades = [
  { value: 'Any', label: 'Any' },
  { value: 'Consumables', label: 'Consumables' },
  { value: 'Attachables', label: 'Attachables' },
  { value: 'Wallet Upgrades', label: 'Wallet Upgrades' },
  { value: 'Squad Potions', label: 'Squad Potions' },
  { value: 'Milk', label: 'Milk' },
  { value: 'Canteen', label: 'Canteen' },
  { value: 'Vegebite', label: 'Vegebite' },
  { value: 'Baked Beans', label: 'Baked Beans' },
  { value: 'PotionX', label: 'PotionX' },
  { value: 'Gummy Raccoon', label: 'Gummy Raccoon' },
  { value: 'Mushrooms', label: 'Mushrooms' },
  { value: 'Gold Ring', label: 'Gold Ring' },
  { value: 'Emerald Ring', label: 'Emerald Ring' },
  { value: 'Ruby Ring', label: 'Ruby Ring' },
  { value: 'Diamond Ring', label: 'Diamond Ring' },
  { value: 'CompoundX Ring', label: 'CompoundX Ring' },
  { value: 'Blue Diamond Ring', label: 'Blue Diamond Ring' },
  { value: 'Light Wings', label: 'Light Wings' },
  { value: 'Dark Wings', label: 'Dark Wings' },
  { value: 'Gold Wings', label: 'Gold Wings' },
  { value: 'Psychedelic Wings', label: 'Psychedelic Wings' },
  { value: 'CompoundX Wings', label: 'CompoundX Wings' },
  { value: 'Blue Diamond Wings', label: 'Blue Diamond Wings' },
  { value: 'Trash Can', label: 'Trash Can' },
  { value: 'Any', label: 'Any' },
];


const sortOrderStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: '#000', 
    color: '#efdbb8',
    outline: 'none',
    borderColor: '#444',
    boxShadow: 'none',
    borderRadius: '10px',
    "&:hover"  : {
      outline: 'none',
      borderColor: '#444',
      boxShadow: 'none',
    },
  }),
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none',
      "&:hover"  : {
        backgroundColor: '#222',
      },
    };
  },
  singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};




const MyNFTs = (props, v2, v3) => {
  const { width, height } = useWindowSize();

  const [tab, setTab] = useState('myRaccoons');
  const [showUpgradeUseModal, setShowUpgradeUseModal] = useState(false);
  const [useUpgradeInventoryItem, setUseUpgradeInventoryItem] = useState(false);

  const [raccoonsInWallet, setRaccoonsInWallet] = useState([]);
  const [raccoonSquadInfo, setRaccoonSquadInfo] = useState([]);
  const [raccoonInfo, setRaccoonInfo] = useState([]);
  const [upgradeStatus, setUpgradeStatus] = useState([]);

  const [squadMemberRaccoonInfo, setSquadMemberRaccoonInfo] = useState([]);

  const [inventory, setInventory] = useState([]);

  const [sortField, setSortField] = useState('Raccoon #');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [filterProfessionValue, setFilterProfessionValue] = useState('All Professions');
  const [filterTierValue, setFilterTierValue] = useState('All Tiers');
  const [filterAvailabilityValue, setFilterAvailabilityValue] = useState('Any');
  const [filterBoostUpcomingValue, setFilterBoostUpcomingValue] = useState('Any');

  const [filterUpgradesMyUpgrades, setFilterUpgradesMyUpgrades] = useState('Any');
  const [filterUpgradesMyInventory, setFilterUpgradesMyUInventory] = useState('Any');

  const [availableNFTConsumableCount, setAvailableNFTConsumableCount] = useState({});
  const [availableInventoryConsumableCount, setAvailableInventoryConsumableCount] = useState({});

  const [consumablesFields, setConsumablesFields] = useState([
    { value: 'USE CONSUMABLE', label: 'USE CONSUMABLE' },
    { value: 'Milk NFT', label: 'Milk NFT' },
    { value: 'Milk Inventory', label: 'Milk Inventory' },
    { value: 'Canteen NFT', label: 'Canteen NFT' },
    { value: 'Canteen Inventory', label: 'Canteen Inventory' },
    { value: 'Vegebite NFT', label: 'Vegebite NFT' },
    { value: 'Vegebite Inventory', label: 'Vegebite Inventory' }
  ]);

  const [chosenConsumables, setChosenConsumables] = useState({});

  const { state, walletSendDetachRequest, walletMintInventoryItem, team, raccoonUpgradesApplied, globalLucid, backgroundRefreshWallet } = useContext(GlobalStateContext);

  const [toastColor, setToastColor] = useState('#e08705');
  
  const [getRaccoonsSquads, { loading: loadingGetRaccoonsSquads, error: errorGetRaccoonsSquads, data: dataGetRaccoonsSquads, refetch: refetchGetRaccoonsSquads }] = useLazyQuery(GET_RACCOONS_SQUADS);
  
  const [getRaccoonsUpgradeStatus, { loading: loadingGetRaccoonsUpdateStatus, error: errorGetRaccoonsUpdateStatus, data: dataGetRaccoonsUpdateStatus, refetch: refetchGetRaccoonsUpdateStatus }] = useLazyQuery(GET_RACCOONS_UPGRADE_STATUS);

  const [getUpgradeStatus, { loading: loadingGetUpdradeStatus, error: errorGetUpdradeStatus, data: dataGetUpdradeStatus, refetch: refetchGetUpdradeStatus }] = useLazyQuery(GET_UPGRADE_STATUS);

  const [getInventory, { loading: loadingGetInventory, error: errorGetInventory, data: dataGetInventory, refetch: refetchGetInventory }] = useLazyQuery(GET_INVENTORY);

  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          localRefreshWallet();
          setTimeout(() => {
            localRefreshWallet();
          }, 5000);
          getUpgradeStatusData();
          getInventory();
        }
    }
  });


  const [trxCreateWatch, { data: dataTRXCreateWatch, loading: loadingTRXCreateWatch, error: errorTRXCreateWatch }] = useMutation(TRX_CREATE_WATCH, 
    {
      onCompleted: response => {
        if (response && response.trxCreateWatch && response.trxCreateWatch.result === 'fail - Already has upgrade coming soon') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Raccoon already has an upcoming upgrade.")
            }, 200);
        } else if (response && response.trxCreateWatch && response.trxCreateWatch.result === 'fail 11') {
          setToastColor('#e08705');
          setTimeout(() => {
            toast("This Raccoon currently has a transaction in progress. Please wait for it to complete before creating a new one.")
          }, 200);
      } else if (response && response.trxCreateWatch && response.trxCreateWatch.result === 'success') {

          const sendDetachRequestSuccess = () => {
            setToastColor('#14711f');
            setTimeout(() => {
              toast("Detach request received. Wait 10-30 minutes for the blockchain and then for your My Raccoons list to be updated.")
              getUpgradeStatusData();
            }, 200);
            getRaccoonInfoForNFTListLazy({variables: { raccoons: JSON.stringify(raccoonNumsInWallet) }});
            getInventory();
          }

          const sendDetachRequestFailure = () => {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Failed to send detach request. Check that you have enough ADA in your wallet (at least 12 ADA) then try again and sign using your wallet extension. Also, wait 2 minutes between requests for your wallet to sync to the blockchain.")
            }, 200);
          }

          const mintInventoryItemSuccess = () => {
            setToastColor('#14711f');
            setTimeout(() => {
              toast("Mint inventory item request received. Wait 10-30 minutes for the blockchain to mint it and for it to show up in your 'My Upgrades' list..")
              getUpgradeStatusData();
            }, 200);
            getRaccoonInfoForNFTListLazy({variables: { raccoons: JSON.stringify(raccoonNumsInWallet) }});
            getInventory();
          }

          const mintInventoryItemFailure = () => {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Failed to send mint inventory request. Check that you have enough ADA in your wallet (at least 7 ADA) then try again and sign using your wallet extension. Also, wait 2 minutes between requests for your wallet to sync to the blockchain.")
            }, 200);
          }

          if (response && response.trxCreateWatch && response.trxCreateWatch.requestType === 'detachUpgrade') {
            walletSendDetachRequest(response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, sendDetachRequestSuccess, sendDetachRequestFailure);
          } else if (response && response.trxCreateWatch && response.trxCreateWatch.requestType === 'mintInventoryItem') {
            walletMintInventoryItem(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.watchId, response.trxCreateWatch.inventoryItemId, response.trxCreateWatch.consumableOrAttachable, response.trxCreateWatch.operationId, mintInventoryItemSuccess, mintInventoryItemFailure);
          }
        }
    }
  });


  const [bulkUseConsumables, { data: dataBulkUseConsumables, loading: loadingBulkUseConsumables, error: errorBulkUseConsumables }] = useMutation(BULK_USE_CONSUMABLES, 
    {
      onCompleted: response => {
    }
  });


  const localRefreshWallet = async (parms) => {
    let fromApi = false;
    if (parms && parms.fromApi) {
      fromApi = true;
    }
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
            getRaccoonsUpgradeStatus({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
          }
        }

        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();
          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          if (fromApi) {
            setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
          }
        }

        setRaccoonsInWallet(raccoonsInWallet);
      }
    }
  }

  useEffect(() => {
    if (dataGetUpdradeStatus && dataGetUpdradeStatus.upgradeStatus && dataGetUpdradeStatus.upgradeStatus.upgrades) {
      if (raccoonsInWallet && raccoonsInWallet.length > 0) {
        /*
        let upgrades = JSON.parse(dataGetUpdradeStatus.upgradeStatus.upgrades);

        let newRaccoonConsumableSelectValues = {};
        upgrades.forEach(upgrade => {
          let upgradeName = upgrade.assetName;
          if (upgradeName.indexOf(' #') > 0) {
            upgradeName = upgradeName.substring(0, upgradeName.indexOf(' #'));
          }
          let isConsumable = false;
          if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Milk') >= 0) {
            isConsumable = true;
          } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Canteen') >= 0) {
            isConsumable = true;
          } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Vegebite') >= 0) {
            isConsumable = true;
          } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Beans') >= 0) {
            isConsumable = true;
          } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Potion') >= 0) {
            isConsumable = true;
          } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gummy Raccoon') >= 0) {
            isConsumable = true;
          } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Mushrooms') >= 0) {
            isConsumable = true;
          }  

          if (isConsumable) {
            // newRaccoonConsumableSelectValues[]
          }
          */

          raccoonsInWallet.forEach(raccoon => {
            let raccoonNum = raccoon.assetNameAscii.substring("Raccoon ".length, raccoon.assetNameAscii.length);
            let upgradesAppliedToThisRaccoon = null;
            if (raccoonUpgradesApplied[raccoonNum] && raccoonUpgradesApplied[raccoonNum].length > 0) {
              upgradesAppliedToThisRaccoon = [];
              raccoonUpgradesApplied[raccoonNum].forEach(upgradeForThisRaccoon => {
                if (upgradeForThisRaccoon && upgradeForThisRaccoon.reason === 'applied') {
                  upgradesAppliedToThisRaccoon.push(upgradeForThisRaccoon);
                }
              });
            }

            let upgradesWaitingForThisRaccoon = null;
            if (raccoonUpgradesApplied[raccoonNum] && raccoonUpgradesApplied[raccoonNum].length > 0) {
              upgradesWaitingForThisRaccoon = [];
              raccoonUpgradesApplied[raccoonNum].forEach(upgradeForThisRaccoon => {
                if (upgradeForThisRaccoon && upgradeForThisRaccoon.reason === 'watching' && upgradeForThisRaccoon.status === 'waitingForBlockchain') {
                  upgradesWaitingForThisRaccoon.push(upgradeForThisRaccoon);
                }
              });
            }
          });
      }
    }
    

  }, [dataGetUpdradeStatus]);

  const refreshInventory = () => {
    getInventory();
  }

  const refreshRaccoonInfo = () => {
    getRaccoonInfoForNFTListLazy({variables: { raccoons: JSON.stringify(raccoonNumsInWallet) }});
    getInventory();
  }

  const getUpgradeStatusData = async () => {
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getUpgradeStatus({variables: {upgradeNames: JSON.stringify(raccoonUpgradesInWallet)}});
          }
        }
      }
    }
  }

  let raccoonUpgradesInWallet = null;
  if (state && state.raccoonUpgradesInWallet) {
    raccoonUpgradesInWallet = state.raccoonUpgradesInWallet;
  }



  const sortUpgrades = (upgrades) => {
    return upgrades.sort((a, b) => {
      let upgradeNameA = a.assetNameAscii;
      let upgradeTypeA = 'None';
      if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Milk') >= 0) {
        upgradeTypeA = 'Milk';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Canteen') >= 0) {
        upgradeTypeA = 'Canteen';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Vegebite') >= 0) {
        upgradeTypeA = 'Vegebite';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Beans') >= 0) {
        upgradeTypeA = 'Baked Beans';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Potion') >= 0) {
        upgradeTypeA = 'PotionX';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Gummy Raccoon') >= 0) {
        upgradeTypeA = 'Gummy Raccoon';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Mushrooms') >= 0) {
        upgradeTypeA = 'Mushrooms';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Gold Ring') >= 0) {
        upgradeTypeA = 'Gold Ring';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Emerald Ring') >= 0) {
        upgradeTypeA = 'Emerald Ring';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Ruby Ring') >= 0) {
        upgradeTypeA = 'Ruby Ring';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Diamond Ring') >= 0) {
        upgradeTypeA = 'Diamond Ring';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('CompoundX Ring') >= 0) {
        upgradeTypeA = 'CompoundX Ring';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Blue Diamond Ring') >= 0) {
        upgradeTypeA = 'Blue Diamond Ring';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Light Wings') >= 0) {
        upgradeTypeA = 'Light Wings';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Dark Wings') >= 0) {
        upgradeTypeA = 'Dark Wings';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Gold Wings') >= 0) {
        upgradeTypeA = 'Gold Wings';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Psychedelic Wings') >= 0) {
        upgradeTypeA = 'Psychedelic Wings';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('CompoundX Wings') >= 0) {
        upgradeTypeA = 'CompoundX Wings';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Blue Diamond Wings') >= 0) {
        upgradeTypeA = 'Blue Diamond Wings';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Trash Can') >= 0) {
        upgradeTypeA = 'Trash Can';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Green Squad Potion') >= 0) {
        upgradeTypeA = 'Green Squad Potion';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Blue Squad Potion') >= 0) {
        upgradeTypeA = 'Blue Squad Potion';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Red Squad Potion') >= 0) {
        upgradeTypeA = 'Red Squad Potion';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Purple Squad Potion') >= 0) {
        upgradeTypeA = 'Purple Squad Potion';
      }

      
      let upgradeNameB = b.assetNameAscii;
      let upgradeTypeB = 'None';
      if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Milk') >= 0) {
        upgradeTypeB = 'Milk';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Canteen') >= 0) {
        upgradeTypeB = 'Canteen';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Vegebite') >= 0) {
        upgradeTypeB = 'Vegebite';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Beans') >= 0) {
        upgradeTypeB = 'Baked Beans';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Potion') >= 0) {
        upgradeTypeB = 'PotionX';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Gummy Raccoon') >= 0) {
        upgradeTypeB = 'Gummy Raccoon';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Mushrooms') >= 0) {
        upgradeTypeB = 'Mushrooms';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Gold Ring') >= 0) {
        upgradeTypeB = 'Gold Ring';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Emerald Ring') >= 0) {
        upgradeTypeB = 'Emerald Ring';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Ruby Ring') >= 0) {
        upgradeTypeB = 'Ruby Ring';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Diamond Ring') >= 0) {
        upgradeTypeB = 'Diamond Ring';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('CompoundX Ring') >= 0) {
        upgradeTypeB = 'CompoundX Ring';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Blue Diamond Ring') >= 0) {
        upgradeTypeB = 'Blue Diamond Ring';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Light Wings') >= 0) {
        upgradeTypeB = 'Light Wings';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Dark Wings') >= 0) {
        upgradeTypeB = 'Dark Wings';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Gold Wings') >= 0) {
        upgradeTypeB = 'Gold Wings';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Psychedelic Wings') >= 0) {
        upgradeTypeB = 'Psychedelic Wings';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('CompoundX Wings') >= 0) {
        upgradeTypeB = 'CompoundX Wings';
      } else if (upgradeNameB && upgradeNameB.indexOf && upgradeNameB.indexOf('Blue Diamond Wings') >= 0) {
        upgradeTypeB = 'Blue Diamond Wings';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Trash Can') >= 0) {
        upgradeTypeB = 'Trash Can';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Green Squad Potion') >= 0) {
        upgradeTypeB = 'Green Squad Potion';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Blue Squad Potion') >= 0) {
        upgradeTypeB = 'Blue Squad Potion';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Red Squad Potion') >= 0) {
        upgradeTypeB = 'Red Squad Potion';
      } else if (upgradeNameA && upgradeNameA.indexOf && upgradeNameA.indexOf('Purple Squad Potion') >= 0) {
        upgradeTypeB = 'Purple Squad Potion';
      }

      if (upgradeRanks[upgradeTypeA] < upgradeRanks[upgradeTypeB]) return -1;
      if (upgradeRanks[upgradeTypeA] > upgradeRanks[upgradeTypeB]) return 1;
      return 0;
    });
    return upgrades;
  }



  if  (raccoonUpgradesInWallet && raccoonUpgradesInWallet.length > 0 && filterUpgradesMyUpgrades && filterUpgradesMyUpgrades !== 'Any') {
    let filteredUpgrades = [];
    raccoonUpgradesInWallet.forEach(upgrade => {
      if (filterUpgradesMyUpgrades === 'Any') {
        filteredUpgrades.push(upgrade);
      } else if (filterUpgradesMyUpgrades === 'Consumables') {
        if (upgrade.assetNameAscii.includes('Milk') || upgrade.assetNameAscii.includes('Canteen') || upgrade.assetNameAscii.includes('Vegebite') || upgrade.assetNameAscii.includes('Baked Beans') || upgrade.assetNameAscii.includes('PotionX') || upgrade.assetNameAscii.includes('Gummy Raccoon') || upgrade.assetNameAscii.includes('Mushrooms')) {
          filteredUpgrades.push(upgrade);
        }
      } else if (filterUpgradesMyUpgrades === 'Attachables') {
        if (upgrade.assetNameAscii.includes(' Ring') || upgrade.assetNameAscii.includes(' Wings')) {
          filteredUpgrades.push(upgrade);
        }
      } else if (filterUpgradesMyUpgrades === 'Wallet Upgrades') {
        if (upgrade.assetNameAscii.includes('Trash Can')) {
          filteredUpgrades.push(upgrade);
        }
      } else if (filterUpgradesMyUpgrades === 'Squad Potions') {
        if (upgrade.assetNameAscii.includes('Squad Potion')) {
          filteredUpgrades.push(upgrade);
        }
      } else if (upgrade.assetNameAscii.includes(filterUpgradesMyUpgrades)) {
        filteredUpgrades.push(upgrade);
      }
    });
    raccoonUpgradesInWallet = filteredUpgrades;
  }

  if (raccoonUpgradesInWallet && raccoonUpgradesInWallet.length > 0) {
    raccoonUpgradesInWallet = sortUpgrades(raccoonUpgradesInWallet);
  }
  




  const sortInventory = (upgrades) => {
    if (!upgrades) return upgrades;
    return upgrades.sort((a, b) => {
      if (upgradeRanks[a.type] < upgradeRanks[b.type]) return -1;
      if (upgradeRanks[a.type] > upgradeRanks[b.type]) return 1;
      return 0;
    });
    return upgrades;
  }


  let useInventory = [];

  if (inventory && inventory.length > 0) {
    inventory.forEach(inv => {
      useInventory.push({
        id: inv.id, 
        status: inv.status,
        type: inv.type,
        earned_method: inv.earned_method
      })
    });
    useInventory = sortInventory(useInventory);
  }

  if  (useInventory && useInventory.length > 0 && filterUpgradesMyInventory && filterUpgradesMyInventory !== 'Any') {
    let filteredUpgrades = [];
    useInventory.forEach(upgrade => {
      if (filterUpgradesMyInventory === 'Any') {
        filteredUpgrades.push(upgrade);
      } else if (filterUpgradesMyInventory === 'Consumables') {
        if (upgrade.type.includes('Milk') || upgrade.type.includes('Canteen') || upgrade.type.includes('Vegebite') || upgrade.type.includes('Baked Beans') || upgrade.type.includes('PotionX') || upgrade.type.includes('Gummy Raccoon') || upgrade.type.includes('Mushrooms')) {
          filteredUpgrades.push(upgrade);
        }
      } else if (filterUpgradesMyInventory === 'Attachables') {
        if (upgrade.type.includes(' Ring') || upgrade.type.includes(' Wings')) {
          filteredUpgrades.push(upgrade);
        }
      } else if (filterUpgradesMyInventory === 'Wallet Upgrades') {
        if (upgrade.type.includes('Trash Can')) {
          filteredUpgrades.push(upgrade);
        }
      } else if (filterUpgradesMyInventory === 'Squad Potions') {
        if (upgrade.type.includes('Squad Potion')) {
          filteredUpgrades.push(upgrade);
        }
      } else if (upgrade.type.includes(filterUpgradesMyInventory)) {
        filteredUpgrades.push(upgrade);
      }
    });
    useInventory = filteredUpgrades;
  }

  let numInventory = 0;
  if (useInventory && useInventory.length > 0) {
    numInventory = useInventory.length;
  }

  let numUpgrades = 0;
  if (raccoonUpgradesInWallet && raccoonUpgradesInWallet.length > 0) {
    numUpgrades = raccoonUpgradesInWallet.length
  }


  let raccoonNumsInWallet = [];
  if (raccoonsInWallet) {
    raccoonsInWallet.forEach(rac => {
      let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
      let racNum = parseInt(racNumStr);
      raccoonNumsInWallet.push(racNum);
    })
  }

  let officersInWallet = [];
  if (raccoonsInWallet) {
    raccoonsInWallet.forEach(rac => {
      let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
      let racNum = parseInt(racNumStr);
      let squadInfo = raccoonSquadInfo[racNum];
      if (squadInfo && squadInfo.raccoonsInSquadILead) {
        officersInWallet.push({
          ...rac,
          ...squadInfo
        });
      }
    })
  }

  const { data: dataGetRaccoonInfoForNFTList, loading: loadingGetRaccoonInfoForNFTList, error: errorGetRaccoonInfoForNFTList } = useQuery(GET_RACCOON_INFO_FOR_NFT_LIST, {
    variables: { raccoons: JSON.stringify(raccoonNumsInWallet) }
  });

  const [getRaccoonInfoForNFTListLazy, { data: dataGetRaccoonInfoForNFTListLazy }] = useLazyQuery(GET_RACCOON_INFO_FOR_NFT_LIST);

  const [getSquadMemberRaccoonInfo, { loading: loadingGetSquadMemberRaccoonInfo, error: errorGetSquadMemberRaccoonInfo, data: dataGetSquadMemberRaccoonInfo, refetch: refetchGetSquadMemberRaccoonInfo }] = useLazyQuery(GET_RACCOON_INFO_FOR_NFT_LIST);

  useEffect(() => {
    if (dataGetRaccoonInfoForNFTList && dataGetRaccoonInfoForNFTList.raccoonInfoForNFTList && dataGetRaccoonInfoForNFTList.raccoonInfoForNFTList.raccoonInfo) {
        let raccoonInfoObj = JSON.parse(dataGetRaccoonInfoForNFTList.raccoonInfoForNFTList.raccoonInfo);
        setRaccoonInfo(raccoonInfoObj);
    }
  }, [dataGetRaccoonInfoForNFTList]);
  




  useEffect(() => {
    if (dataGetRaccoonInfoForNFTListLazy && dataGetRaccoonInfoForNFTListLazy.raccoonInfoForNFTList && dataGetRaccoonInfoForNFTListLazy.raccoonInfoForNFTList.raccoonInfo) {
      let raccoonInfoObj = JSON.parse(dataGetRaccoonInfoForNFTListLazy.raccoonInfoForNFTList.raccoonInfo);
      setRaccoonInfo(raccoonInfoObj);
    }
  }, [dataGetRaccoonInfoForNFTListLazy])


  useEffect(() => {
    getRaccoonInfoForNFTListLazy({variables: { raccoons: JSON.stringify(raccoonNumsInWallet) }});
  }, []);




  useEffect(() => {
    if (dataGetSquadMemberRaccoonInfo && dataGetSquadMemberRaccoonInfo.raccoonInfoForNFTList && dataGetSquadMemberRaccoonInfo.raccoonInfoForNFTList.raccoonInfo) {
        let raccoonInfoObj = JSON.parse(dataGetSquadMemberRaccoonInfo.raccoonInfoForNFTList.raccoonInfo);
        setSquadMemberRaccoonInfo(raccoonInfoObj);
    }
  }, [dataGetSquadMemberRaccoonInfo])

  useEffect(() => {
    if (dataGetUpdradeStatus && dataGetUpdradeStatus.upgradeStatus && dataGetUpdradeStatus.upgradeStatus.upgrades) {
        let upgrades = JSON.parse(dataGetUpdradeStatus.upgradeStatus.upgrades);
        setUpgradeStatus(upgrades);
    }
  }, [dataGetUpdradeStatus])

  useEffect(() => {
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 8000);
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 15000);
  }, []);

  useEffect(() => {
    if (globalLucid) {
      localRefreshWallet({fromApi: true});
    }
  }, [state.api]);

  useEffect(() => {
    if (dataGetInventory && dataGetInventory.inventory) {
      setInventory(dataGetInventory.inventory);
    }
  }, [dataGetInventory])
  
  useEffect(() => {
    if (dataGetRaccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads.results) {
      let newRaccoonsInWallet = [];
      let results = JSON.parse(dataGetRaccoonsSquads.raccoonsSquads.results);
      if (results && results.forEach ) {
        let racSquadInfo = {};
        results.forEach(result => {
          if (result && (result.raccoonsInSquadILead || result.raccoonsInSquadImIn || result.squadILead || result.squadImIn)) {
            racSquadInfo[result.racNum] = result;
          }
        });

/*
        if (raccoonsInWallet && raccoonsInWallet.forEach) {
          raccoonsInWallet.forEach(rac => {
            let newRac = {...rac};
            let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
            let racNum = parseInt(racNumStr);
            if (racSquadInfo[racNum]) {
              newRac.racNum = racSquadInfo[racNum].racNum;
              newRac.raccoonsInSquadILead = racSquadInfo[racNum].raccoonsInSquadILead;
              newRac.raccoonsInSquadImIn = racSquadInfo[racNum].raccoonsInSquadImIn;
              newRac.squadILead = racSquadInfo[racNum].squadILead;
              newRac.squadImIn = racSquadInfo[racNum].squadImIn;
            }
            newRaccoonsInWallet.push(newRac);
          });
        }
*/
        setRaccoonSquadInfo({...racSquadInfo});
      }
    }
  }, [dataGetRaccoonsSquads, loadingGetRaccoonsSquads]);

  useEffect(() => {
  }, [chosenConsumables])

  useEffect(() => {
    updateCounts();
    
  }, [inventory, state.raccoonUpgradesInWallet])

  const updateCounts = () => {
    let ruiw = [];
    if (state && state.raccoonUpgradesInWallet) {
      ruiw = state.raccoonUpgradesInWallet;
    }

    let nftConsumableCounts = {};
    if (raccoonUpgradesInWallet && raccoonUpgradesInWallet.length > 0) {
      raccoonUpgradesInWallet.forEach((up, i) => {
        if (up.assetNameAscii.indexOf('Milk') >= 0) {
          nftConsumableCounts['Milk'] = nftConsumableCounts['Milk'] ? nftConsumableCounts['Milk'] + 1 : 1;
        } else if (up.assetNameAscii.indexOf('Canteen') >= 0) {
          nftConsumableCounts['Canteen'] = nftConsumableCounts['Canteen'] ? nftConsumableCounts['Canteen'] + 1 : 1;
        } else if (up.assetNameAscii.indexOf('Vegebite') >= 0) {
          nftConsumableCounts['Vegebite'] = nftConsumableCounts['Vegebite'] ? nftConsumableCounts['Vegebite'] + 1 : 1;
        } else if (up.assetNameAscii.indexOf('Baked Beans') >= 0) {
          nftConsumableCounts['Baked Beans'] = nftConsumableCounts['Baked Beans'] ? nftConsumableCounts['Baked Beans'] + 1 : 1;
        } else if (up.assetNameAscii.indexOf('PotionX') >= 0) {
          nftConsumableCounts['PotionX'] = nftConsumableCounts['PotionX'] ? nftConsumableCounts['PotionX'] + 1 : 1;
        } else if (up.assetNameAscii.indexOf('Gummy Raccoon') >= 0) {
          nftConsumableCounts['Gummy Raccoon'] = nftConsumableCounts['Gummy Raccoon'] ? nftConsumableCounts['Gummy Raccoon'] + 1 : 1;
        } else if (up.assetNameAscii.indexOf('Mushrooms') >= 0) {
          nftConsumableCounts['Mushrooms'] = nftConsumableCounts['Mushrooms'] ? nftConsumableCounts['Mushrooms'] + 1 : 1;
        }
      });
    }

    let inventoryConsumableCounts = {};
    if (inventory && inventory.length > 0) {
      inventory.forEach((inv, i) => {
        inventoryConsumableCounts[inv.type] = inventoryConsumableCounts[inv.type] ? inventoryConsumableCounts[inv.type] + 1 : 1;
      });
    }

    let newConsumablesFields = [
      { value: 'USE CONSUMABLE', label: 'USE CONSUMABLE' },
      { value: `Mushrooms NFT (${nftConsumableCounts['Mushrooms'] ? nftConsumableCounts['Mushrooms'] : 0})`, label: `Mushrooms NFT (${nftConsumableCounts['Mushrooms'] ? nftConsumableCounts['Mushrooms'] : 0})` },      
      { value: `Mushrooms Inventory (${inventoryConsumableCounts['Mushrooms'] ? inventoryConsumableCounts['Mushrooms'] : 0})`, label: `Mushrooms INV (${inventoryConsumableCounts['Mushrooms'] ? inventoryConsumableCounts['Mushrooms'] : 0})` },      
      { value: `Gummy Raccoon NFT (${nftConsumableCounts['Gummy Raccoon'] ? nftConsumableCounts['Gummy Raccoon'] : 0})`, label: `Gummy Raccoon NFT (${nftConsumableCounts['Gummy Raccoon'] ? nftConsumableCounts['Gummy Raccoon'] : 0})` },      
      { value: `Gummy Raccoon Inventory (${inventoryConsumableCounts['Gummy Raccoon'] ? inventoryConsumableCounts['Gummy Raccoon'] : 0})`, label: `Gummy Raccoon INV (${inventoryConsumableCounts['Gummy Raccoon'] ? inventoryConsumableCounts['Gummy Raccoon'] : 0})` },      
      { value: `PotionX NFT (${nftConsumableCounts['PotionX'] ? nftConsumableCounts['PotionX'] : 0})`, label: `PotionX NFT (${nftConsumableCounts['PotionX'] ? nftConsumableCounts['PotionX'] : 0})` },      
      { value: `PotionX Inventory (${inventoryConsumableCounts['PotionX'] ? inventoryConsumableCounts['PotionX'] : 0})`, label: `PotionX INV (${inventoryConsumableCounts['PotionX'] ? inventoryConsumableCounts['PotionX'] : 0})` },      
      { value: `Baked Beans NFT (${nftConsumableCounts['Baked Beans'] ? nftConsumableCounts['Baked Beans'] : 0})`, label: `Baked Beans NFT (${nftConsumableCounts['Baked Beans'] ? nftConsumableCounts['Baked Beans'] : 0})` },      
      { value: `Baked Beans Inventory (${inventoryConsumableCounts['Baked Beans'] ? inventoryConsumableCounts['Baked Beans'] : 0})`, label: `Baked Beans INV (${inventoryConsumableCounts['Baked Beans'] ? inventoryConsumableCounts['Baked Beans'] : 0})` },      
      { value: `Vegebite NFT (${nftConsumableCounts['Vegebite'] ? nftConsumableCounts['Vegebite'] : 0})`, label: `Vegebite NFT (${nftConsumableCounts['Vegebite'] ? nftConsumableCounts['Vegebite'] : 0})` },      
      { value: `Vegebite Inventory (${inventoryConsumableCounts['Vegebite'] ? inventoryConsumableCounts['Vegebite'] : 0})`, label: `Vegebite INV (${inventoryConsumableCounts['Vegebite'] ? inventoryConsumableCounts['Vegebite'] : 0})` },      
      { value: `Canteen NFT (${nftConsumableCounts['Canteen'] ? nftConsumableCounts['Canteen'] : 0})`, label: `Canteen NFT (${nftConsumableCounts['Canteen'] ? nftConsumableCounts['Canteen'] : 0})` },      
      { value: `Canteen Inventory (${inventoryConsumableCounts['Canteen'] ? inventoryConsumableCounts['Canteen'] : 0})`, label: `Canteen INV (${inventoryConsumableCounts['Canteen'] ? inventoryConsumableCounts['Canteen'] : 0})` },      
      { value: `Milk NFT (${nftConsumableCounts['Milk'] ? nftConsumableCounts['Milk'] : 0})`, label: `Milk NFT (${nftConsumableCounts['Milk'] ? nftConsumableCounts['Milk'] : 0})` },      
      { value: `Milk Inventory (${inventoryConsumableCounts['Milk'] ? inventoryConsumableCounts['Milk'] : 0})`, label: `Milk INV (${inventoryConsumableCounts['Milk'] ? inventoryConsumableCounts['Milk'] : 0})` },
    ];

    setConsumablesFields(newConsumablesFields);
    setAvailableNFTConsumableCount(nftConsumableCounts);
    setAvailableInventoryConsumableCount(inventoryConsumableCounts);

  }

  const detachAttachment = (raccoonNum, detachRaccoonInfo, upgradeType) => {
    trxCreateWatch({ variables: { assetName: `detach-${upgradeType}`, requestType: 'detachUpgrade', quantity: parseInt(1), action: 'trxCreateWatch', myWalletAddresses: 'testAddresses', raccoonNum: parseInt(raccoonNum)  } });
  }

  const mintInventoryItem = async (inventoryItem) => {
    trxCreateWatch({ variables: { assetName: `mint-${inventoryItem.type}`, requestType: 'mintInventoryItem', quantity: parseInt(1), action: 'trxCreateWatch', myWalletAddresses: 'testAddresses', inventoryItemId:inventoryItem.id  } });
  }

  if (browser && browser.name) {
    if (browser.name === 'firefox' || browser.name === 'safari') {
      return (
        <div className="home_wrapper">
          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
          </div>

          <center>
            <div className="global_leaderboard_title_text" style={{marginTop: '100px', textAlign: 'center', width: '100%', alignItems: 'center', justifyContent: 'center', flex: 1}}>
              Only Chrome, Brave and Edge browsers can view this page
            </div>  
          </center>    

        </div>
      )
    }
  }

  let newRaccoonsInWallet = [];
  if (raccoonsInWallet && raccoonsInWallet.length > 0) {
    raccoonsInWallet.forEach(rac => {
      let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
      let racNum = parseInt(racNumStr);

      let newRaccoon = {};
      if (raccoonInfo[racNum]) {
        newRaccoon = {
          ...rac, 
          bpm: parseFloat(raccoonInfo[racNum].bpm),
          rank: raccoonInfo[racNum].rank,
          tier: raccoonInfo[racNum].tier,
          tierRank: raccoonInfo[racNum].tierRank
        }
      } else {
        newRaccoon = {
          ...rac, 
        }
      }

      let racSquadInfo = raccoonSquadInfo[racNum];
      if (!racSquadInfo) {
        racSquadInfo = {};
      }


      let meetsBoostUpcomingFilter = false;
      if (filterBoostUpcomingValue === 'Any') {
        meetsBoostUpcomingFilter = true;
      } else if (filterBoostUpcomingValue === 'No Epoch Trait Upcoming' && !raccoonInfo[racNum].upcoming_left_hand_trait_match && !raccoonInfo[racNum].upcoming_right_hand_trait_match && !raccoonInfo[racNum].upcoming_wardrobe_trait_match && !raccoonInfo[racNum].upcoming_head_trait_match && !raccoonInfo[racNum].upcoming_mouth_trait_match && !raccoonInfo[racNum].upcoming_eyewear_trait_match && !raccoonInfo[racNum].upcoming_eye_trait_match) {
        meetsBoostUpcomingFilter = true;
      } if (filterBoostUpcomingValue === 'Epoch Trait Upcoming' && (raccoonInfo[racNum].upcoming_left_hand_trait_match || raccoonInfo[racNum].upcoming_right_hand_trait_match || raccoonInfo[racNum].upcoming_wardrobe_trait_match || raccoonInfo[racNum].upcoming_head_trait_match || raccoonInfo[racNum].upcoming_mouth_trait_match || raccoonInfo[racNum].upcoming_eyewear_trait_match || raccoonInfo[racNum].upcoming_eye_trait_match)) {
        meetsBoostUpcomingFilter = true;
      }

      let meetsAvailabilityFilter = false;
      if (filterAvailabilityValue === 'Any') {
        meetsAvailabilityFilter = true;
      } else if (filterAvailabilityValue === 'Available' && !racSquadInfo.squadImIn && !racSquadInfo.squadILead) {
        meetsAvailabilityFilter = true;
      } if (filterAvailabilityValue === 'In Squad' && (racSquadInfo.squadImIn || racSquadInfo.squadILead)) {
        meetsAvailabilityFilter = true;
      }

      let meetsTierFilter = false;
      if (meetsAvailabilityFilter) {
        if (filterProfessionValue === 'All Professions') {
          meetsTierFilter = true;
        } else if (filterProfessionValue === 'Officers' && racSquadInfo.squadILead) {
          meetsTierFilter = true;
        } else if (filterProfessionValue === 'Non-Officers' && !racSquadInfo.squadILead) {
          meetsTierFilter = true;
        }
      }

      
      if (meetsTierFilter && meetsAvailabilityFilter && meetsBoostUpcomingFilter) {
        if (filterTierValue === 'All Tiers') {
          newRaccoonsInWallet.push(newRaccoon);
        } else if (filterTierValue === 'Tier 1' && raccoonInfo[racNum].tier === 1) {
          newRaccoonsInWallet.push(newRaccoon);
        } else if (filterTierValue === 'Tier 2' && raccoonInfo[racNum].tier === 2) {
          newRaccoonsInWallet.push(newRaccoon);
        } else if (filterTierValue === 'Tier 3' && raccoonInfo[racNum].tier === 3) {
          newRaccoonsInWallet.push(newRaccoon);
        }        
      }

    });
  }
  let raccoonsInWalletDisplay = newRaccoonsInWallet;

  let numRaccoons = 0;
  if (raccoonsInWalletDisplay && raccoonsInWalletDisplay.length > 0) {
    numRaccoons = raccoonsInWalletDisplay.length;
  }

  const sortRaccoonsInWallet = (oldRaccoonsInWallet) => {
    if (sortField === 'Raccoon #' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.racNum < b.racNum) return -1;
        if (a.racNum > b.racNum) return 1;
        return 0;
      });
    } else if (sortField === 'Raccoon #' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.racNum < b.racNum) return 1;
        if (a.racNum > b.racNum) return -1;
        return 0;
      });
    } else if (sortField === 'BPM' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.bpm < b.bpm) return -1;
        if (a.bpm > b.bpm) return 1;
        return 0;
      });
    } else if (sortField === 'BPM' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.bpm < b.bpm) return 1;
        if (a.bpm > b.bpm) return -1;
        return 0;
      });
    } else if (sortField === 'Rank' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.rank < b.rank) return -1;
        if (a.rank > b.rank) return 1;
        return 0;
      });
    } else if (sortField === 'Rank' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.rank < b.rank) return 1;
        if (a.rank > b.rank) return -1;
        return 0;
      });
    } else if (sortField === 'Tier Rank' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.tierRank < b.tierRank) return -1;
        if (a.tierRank > b.tierRank) return 1;
        return 0;
      });
    } else if (sortField === 'Tier Rank' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.tierRank < b.tierRank) return 1;
        if (a.tierRank > b.tierRank) return -1;
        return 0;
      });
    }
    return oldRaccoonsInWallet;
  }

  if (raccoonsInWalletDisplay && raccoonsInWalletDisplay.forEach) {
    raccoonsInWalletDisplay.forEach(rac => {
      let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
      let racNum = parseInt(racNumStr);
      rac.racNum = racNum;
    });
  }
  raccoonsInWalletDisplay = sortRaccoonsInWallet(raccoonsInWalletDisplay);


  const renderChosenConsumables = () => {
    let consumables = [];
    for (var racNum in chosenConsumables) {
      if (chosenConsumables.hasOwnProperty(racNum)) {
        consumables.push({
          racNum,
          upgrade: chosenConsumables[racNum].indexOf(' NFT') >= 0 ? chosenConsumables[racNum].substring(0, chosenConsumables[racNum].indexOf(' NFT')) : chosenConsumables[racNum].substring(0, chosenConsumables[racNum].indexOf(' Inventory')),
          type: chosenConsumables[racNum].indexOf(' NFT') >= 0 ? 'NFT' : 'Inventory'
        })
      }
    }

    return (
      <div style={{marginTop: '20px'}}>
        {consumables.map((consumable, index) => {
          return (
            <div key={`consum_${index}`} style={{color: '#efdbb8', marginTop: '5px'}}>
              - Raccoon #{consumable.racNum} will receive a {consumable.upgrade} ({consumable.type === 'NFT' ? "from an NFT" : "from your inventory"})
            </div>
          )
        })}
      </div>
    )
  }


  const renderSubmitButton = () => {
    let consumables = [];
    for (var racNum in chosenConsumables) {
      if (chosenConsumables.hasOwnProperty(racNum)) {
        consumables.push({
          racNum,
          upgrade: chosenConsumables[racNum].indexOf(' NFT') >= 0 ? chosenConsumables[racNum].substring(0, chosenConsumables[racNum].indexOf(' NFT')) : chosenConsumables[racNum].substring(0, chosenConsumables[racNum].indexOf(' Inventory')),
          type: chosenConsumables[racNum].indexOf(' NFT') >= 0 ? 'NFT' : 'Inventory'
        })
      }
    }

    return (
      <div style={{marginTop: '20px'}}>
        <button className={consumables && consumables.length > 0 ? "forgeItemsBtn" : "forgeItemsBtnDisabled"} style={{fontSize: '18px'}} onClick={consumables && consumables.length > 0  ? (e) => {
          e.preventDefault();
          e.stopPropagation();
          bulkUseConsumables({variables: {chosenConsumables: JSON.stringify(chosenConsumables)}});
        } : undefined}>
          Use Consumables
        </button>
      </div>
    )
  }


  return (
    <>
      <div className="home_wrapper">
        
        <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

        <div className="logo_container">
          <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
        </div>


        <div className="global_leaderboard_header_box">
          <div className="leaderboard_title_row">
          <div className="global_leaderboard_title_text">
              <div className={tab === 'myRaccoons' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => { setTab('myRaccoons');}} style={{fontSize: '29px'}}>CONSUMABLES TO APPLY</div>
            </div>      
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
        </div>

        <div className="global_leaderboard_header_box" style={{marginTop: '0px', padding: '20px 30px'}}>
          <div style={{color: '#efdbb8'}}>
            Select consumables using the pulldown options under each of your raccoons below. 
          </div>
          {renderChosenConsumables()}
          {renderSubmitButton()}
        </div>


        <div className="global_leaderboard_header_box">
          <div className="leaderboard_title_row">
          <div className="global_leaderboard_title_text">
              <div className={tab === 'myRaccoons' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => { setTab('myRaccoons');}} style={{fontSize: '29px'}}>MY RACCOONS {numRaccoons > 0 ? `(${numRaccoons})` : ''}</div>
            </div>      
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
        </div>


        {!state.doneInitialWalletLoad && (
          <div className="my_nfts_container">
            <ClipLoader
              color={'#FFF'}
              loading={true}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        
        {tab === 'myRaccoons' && (
          <div style={{marginTop: '10px', paddingLeft: '5%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
            <div style={{color: '#efdbb8', marginLeft: '48px', marginRight: '10px'}}>
              Sort by:
            </div>
            <div style={{width: '160px'}}>
              <Select 
                options={sortOptionsFields} 
                defaultValue={{value: sortField, label: sortField}}
                styles={sortOrderStyles}
                onChange={(option) => {
                  if (option && option.value) {
                    setSortField(option.value);
                  }
                }}
              />
            </div>
            <div style={{width: '160px', marginLeft: '30px'}}>
              <Select 
                options={sortOptionsOrder} 
                defaultValue={{value: sortDirection, label: sortDirection}}
                styles={sortOrderStyles}
                onChange={(option) => {
                  if (option && option.value) {
                    setSortDirection(option.value);
                  }
                }}
              />
            </div>
            <div style={{width: '180px', marginLeft: '30px'}}>
              <Select 
                options={filterOptionsProfession} 
                defaultValue={{value: filterProfessionValue, label: filterProfessionValue}}
                styles={sortOrderStyles}
                onChange={(option) => {
                  if (option && option.value) {
                    setFilterProfessionValue(option.value);
                  }
                }}
              />
            </div>
            <div style={{width: '160px', marginLeft: '30px'}}>
              <Select 
                options={filterOptionsTier} 
                defaultValue={{value: filterTierValue, label: filterTierValue}}
                styles={sortOrderStyles}
                onChange={(option) => {
                  if (option && option.value) {
                    setFilterTierValue(option.value);
                  }
                }}
              />
            </div>            
            <div style={{width: '145px', marginLeft: '30px'}}>
                <Select 
                  options={filterOptionsAvailability} 
                  defaultValue={{value: filterAvailabilityValue, label: filterAvailabilityValue}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setFilterAvailabilityValue(option.value);
                    }
                  }}
                />
            </div>   
            <div style={{width: '230px', marginLeft: '30px'}}>
                <Select 
                  options={filterOptionsBoostUpcoming} 
                  defaultValue={{value: filterBoostUpcomingValue, label: filterBoostUpcomingValue}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setFilterBoostUpcomingValue(option.value);
                    }
                  }}
                />
            </div>   
          </div>
        )}

        {tab === 'myUpgrades' && (
          <div style={{marginTop: '10px', paddingLeft: '5%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
            <div style={{color: '#efdbb8', marginLeft: '48px', marginRight: '10px'}}>
              Filter by:
            </div>
            <div style={{width: '180px', marginLeft: '30px'}}>
              <Select 
                options={filterOptionsUpgrades} 
                defaultValue={{value: filterUpgradesMyUpgrades, label: filterUpgradesMyUpgrades}}
                styles={sortOrderStyles}
                onChange={(option) => {
                  if (option && option.value) {
                    setFilterUpgradesMyUpgrades(option.value);
                  }
                }}
              />
            </div>
          </div>
        )}

        {tab === 'myInventory' && (
          <div style={{marginTop: '10px', paddingLeft: '5%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
            <div style={{color: '#efdbb8', marginLeft: '48px', marginRight: '10px'}}>
              Filter by:
            </div>
            <div style={{width: '180px', marginLeft: '30px'}}>
              <Select 
                options={filterOptionsUpgrades} 
                defaultValue={{value: filterUpgradesMyInventory, label: filterUpgradesMyInventory}}
                styles={sortOrderStyles}
                onChange={(option) => {
                  if (option && option.value) {
                    setFilterUpgradesMyUInventory(option.value);
                  }
                }}
              />
            </div>
          </div>
        )}

        {tab === 'myRaccoons' && 
          <div className="my_nfts_container">
          {state.doneInitialWalletLoad && raccoonsInWalletDisplay && raccoonsInWalletDisplay.length > 0 &&
            raccoonsInWalletDisplay.map((raccoon, i) => {
              let asciiName = raccoon.assetNameAscii;
              let raccoonNum = asciiName.substring('Raccoon '.length, asciiName.length);

              let thisRaccoonInfo = {};
              if (thisRaccoonInfo) {
                thisRaccoonInfo = raccoonInfo[raccoonNum];
              }

              let hasDetachInProgress = false;
              if (thisRaccoonInfo && (thisRaccoonInfo['requesthRequestType'])) {
                if (thisRaccoonInfo['requesthRequestType'].includes('detach')) {
                  hasDetachInProgress = true;
                }
              }

              if (thisRaccoonInfo && (thisRaccoonInfo['watchRequestType'])) {
                if (thisRaccoonInfo['watchRequestType'].includes('detach')) {
                  hasDetachInProgress = true;
                }
              }

              let upgradesAppliedToThisRaccoon = null;
              if (raccoonUpgradesApplied[raccoonNum] && raccoonUpgradesApplied[raccoonNum].length > 0) {
                upgradesAppliedToThisRaccoon = [];
                raccoonUpgradesApplied[raccoonNum].forEach(upgradeForThisRaccoon => {
                  if (upgradeForThisRaccoon && upgradeForThisRaccoon.reason === 'applied') {
                    upgradesAppliedToThisRaccoon.push(upgradeForThisRaccoon);
                  }
                });
              }

              let upgradesWaitingForThisRaccoon = null;
              if (raccoonUpgradesApplied[raccoonNum] && raccoonUpgradesApplied[raccoonNum].length > 0) {
                upgradesWaitingForThisRaccoon = [];
                raccoonUpgradesApplied[raccoonNum].forEach(upgradeForThisRaccoon => {
                  if (upgradeForThisRaccoon && upgradeForThisRaccoon.reason === 'watching' && upgradeForThisRaccoon.status === 'waitingForBlockchain') {
                    upgradesWaitingForThisRaccoon.push(upgradeForThisRaccoon);
                  }
                });
              }

              let upgradeStrings = [];
              if (upgradesAppliedToThisRaccoon && upgradesAppliedToThisRaccoon.length > 0) {
                upgradesAppliedToThisRaccoon.forEach(upgrade => {
                  if (upgrade.assetName) {
                    if (upgrade.assetName.indexOf(' Battle Points') >= 0) {
                      upgradeStrings.push(`${upgrade.assetName} for epoch ${upgrade.epoch}`);
                    } else {
                      upgradeStrings.push(`${upgrade.assetName}: ${UPGRADE_BONUS_RATES[upgrade.upgradeType]}% boost for epoch ${upgrade.epoch}`);
                    }
                  } else {
                    if (upgrade.upgradeType.indexOf(' Battle Points') >= 0) {
                      upgradeStrings.push(`${upgrade.upgradeType} for epoch ${upgrade.epoch}`);
                    } else {
                      upgradeStrings.push(`${upgrade.upgradeType}: ${UPGRADE_BONUS_RATES[upgrade.upgradeType]}% boost for epoch ${upgrade.epoch}`);
                    }
                  }
                  
                });
              }


              let upgradeWatchingStrings = [];
              if (upgradesWaitingForThisRaccoon && upgradesWaitingForThisRaccoon.length > 0) {
                upgradesWaitingForThisRaccoon.forEach(upgrade => {
                  let upgradeName = upgrade.assetName;
                  let upgradeType = 'None';
                  if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Milk') >= 0) {
                    upgradeType = 'Milk';
                  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Canteen') >= 0) {
                    upgradeType = 'Canteen';
                  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Vegebite') >= 0) {
                    upgradeType = 'Vegebite';
                  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Beans') >= 0) {
                    upgradeType = 'Baked Beans';
                  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Potion') >= 0) {
                    upgradeType = 'PotionX';
                  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gummy Raccoon') >= 0) {
                    upgradeType = 'Gummy Raccoon';
                  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Mushrooms') >= 0) {
                    upgradeType = 'Mushrooms';
                  }                    

                  if (upgrade.assetName && upgrade.assetName.indexOf('detach-') >= 0) {
                    upgradeWatchingStrings.push(`${upgrade.assetName.replaceAll('-', ' ')}`);
                  } else if (upgrade.assetName && (upgrade.assetName.indexOf(' Ring') >= 0 || upgrade.assetName.indexOf(' Wings') >= 0)) {
                    upgradeWatchingStrings.push(`${upgrade.assetName.replaceAll('-', ' ')}`);
                  } else if (upgrade.assetName && (upgrade.assetName.indexOf(' Battle Points') >= 0)) {
                    upgradeWatchingStrings.push(`${upgrade.assetName}`);
                  } else {
                    upgradeWatchingStrings.push(`${upgrade.assetName}: ${UPGRADE_BONUS_RATES[upgradeType]}% boost`);
                  }
                });
              }

              let racSquadInfo = raccoonSquadInfo[parseInt(raccoonNum)];
              if (!racSquadInfo) {
                racSquadInfo = {};
              }

              let epochBonusTraitMatchStrings = [];
              let haveUpcomingEpochBonusUpgrades = false;
              let epochForBonuses = 0;
              if (thisRaccoonInfo) {
                if (thisRaccoonInfo.upcoming_left_hand_trait_match) {
                  epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_left_hand_trait_match);
                  haveUpcomingEpochBonusUpgrades = true;
                  epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                }
                if (thisRaccoonInfo.upcoming_right_hand_trait_match) {
                  epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_right_hand_trait_match);
                  haveUpcomingEpochBonusUpgrades = true;
                  epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                }
                if (thisRaccoonInfo.upcoming_wardrobe_trait_match) {
                  epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_wardrobe_trait_match);
                  haveUpcomingEpochBonusUpgrades = true;
                  epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                }
              }

              let shouldShowForgeIcon = ((upgradesAppliedToThisRaccoon && upgradesAppliedToThisRaccoon.length > 0) || (upgradesWaitingForThisRaccoon && upgradesWaitingForThisRaccoon.length > 0));

              return (
                <div className="my_nfts_nft_box" key={`db+${asciiName}_${i}`}>
                  <div >
                    <img src={`https://rs9.s3.us-east-1.amazonaws.com/n/${raccoonNum}.png?cb=${Date.now()}`} className="my_nfts_nft_image" />
                    <div className="my_nfts_nft_label">
                      Raccoon #{raccoonNum}
                      {racSquadInfo.squadImIn && 
                        <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}>Current Squad #{racSquadInfo.squadImIn.squadNum}</div>
                      }
                      {racSquadInfo.squadILead && 
                        <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}>Leads Squad #{racSquadInfo.squadILead.squadNum}</div>
                      }
                      {!racSquadInfo.squadImIn && !racSquadInfo.squadILead && 
                        <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}> </div>
                      }
                    </div>
                    <div className="my_nfts_meta_row_1">
                      {thisRaccoonInfo &&
                        <>
                        <div className="my_nfts_meta_cell">
                          {thisRaccoonInfo.rank && <span>Rank: <span>{thisRaccoonInfo.rank}</span></span>}
                        </div>
                        <div className="my_nfts_meta_cell" style={{justifyContent: 'flex-end'}}>
                          BPM {parseFloat(thisRaccoonInfo.bpm).toFixed(2)}
                        </div>                        
                        </>
                      }
                    </div>
                    <div className="my_nfts_meta_row_2">
                      {thisRaccoonInfo &&
                        <>
                        <div className="my_nfts_meta_cell">
                          {thisRaccoonInfo.rank && <span>Tier Rank: {thisRaccoonInfo.tierRank}</span>}
                        </div>
                        <div className="my_nfts_meta_cell" style={{justifyContent: 'flex-end'}}>
                          Tier {thisRaccoonInfo.tier}
                        </div>                        
                        </>
                      }
                    </div>     
                    <div className="my_nfts_meta_row_2" style={{alignItems: 'center', width: '100%', marginTop: '25px'}} id="TEST2 TEST"> 
                      {thisRaccoonInfo &&
                        <>
                          <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                            <div style={{width: '100%'}}>
                              <Select 
                                options={consumablesFields} 
                                value={chosenConsumables[raccoonNum] ? {value: chosenConsumables[raccoonNum], label: chosenConsumables[raccoonNum]} : {value: 'USE CONSUMABLE', label: 'USE CONSUMABLE'}}
                                valueBAD={() => {
                                  if (chosenConsumables && chosenConsumables[raccoonNum]) {
                                    let val = chosenConsumables[raccoonNum] && chosenConsumables[raccoonNum].indexOf(' NFT') >= 0 ? `${chosenConsumables[raccoonNum]} NFT` : `${chosenConsumables[raccoonNum]} Inventory`;
                                    return {value: chosenConsumables[raccoonNum] && chosenConsumables[raccoonNum].indexOf(' NFT') >= 0 ? `${chosenConsumables[raccoonNum]} NFT` : `${chosenConsumables[raccoonNum]} Inventory`, label: chosenConsumables[raccoonNum] && chosenConsumables[raccoonNum].indexOf(' NFT') >= 0 ? `${chosenConsumables[raccoonNum]} NFT` : `${chosenConsumables[raccoonNum]} Inventory`}
                                  } else {
                                    return "USE CONSUMABLE"
                                    // return {value: 'USE CONSUMABLE', label: 'USE CONSUMABLE'}
                                  }
                                }}
                                styles={sortOrderStyles}
                                onChange={(option) => {
                                  if (option && option.value) {
                                    let type = option.value.indexOf(' NFT') >= 0 ? 'NFT' : 'Inventory';
                                    let upgrade = type === 'NFT' ? option.value.substring(0, option.value.indexOf(' NFT')) : option.value.substring(0, option.value.indexOf(' Inventory'));
                                    let numAvailable = 0;
                                    if (type === 'NFT') {
                                      numAvailable = availableNFTConsumableCount[upgrade] ? availableNFTConsumableCount[upgrade]  : 0;
                                    } else {
                                      numAvailable = availableInventoryConsumableCount[upgrade] ? availableInventoryConsumableCount[upgrade] : 0;
                                    }
                                    if (numAvailable < 1) {
                                      setToastColor('#e08705');
                                      setTimeout(() => {
                                        toast("No consumables of that type available. Choose a different consumable.")
                                      }, 200);
                                      return;
                                    }
                                    let chosenConsumablesNew = chosenConsumables;
                                    chosenConsumablesNew[raccoonNum] = option.value;
                                    setChosenConsumables({...chosenConsumablesNew});
                                  }
                                }}
                              />
                            </div>
                             <div style={{color: '#efdbb8', fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginLeft: '15px'}} onClick={() => {
                                let newConsumables = {};
                                for (var racNum in chosenConsumables) {
                                  if (chosenConsumables.hasOwnProperty(racNum) && racNum !== raccoonNum) {
                                    newConsumables[raccoonNum] = chosenConsumables[racNum];
                                  }
                                }
                                setChosenConsumables(newConsumables);
                             }}>
                                X
                             </div>
                          </div>                       
                        </>
                      }
                    </div> 
                    <div className="my_nfts_meta_row_2">
                      {/*thisRaccoonInfo &&
                        <>
                        <div className="my_nfts_meta_cell">
                          {!CEASE_FIRE && !COMPUTE_IN_PROGRESS && !hasDetachInProgress && thisRaccoonInfo.ring && thisRaccoonInfo.ring !== 'None' && <span style={{backgroundColor: '#6b0000', color: '#fff', padding: '5px', borderRadius: '5px', fontSize: '12px'}} onClick={(e) => {e.preventDefault();e.stopPropagation();detachAttachment(raccoonNum, thisRaccoonInfo, 'ring')}}>Detach {thisRaccoonInfo.ring}</span>}
                          {(!thisRaccoonInfo.ring || thisRaccoonInfo.ring === 'None') && <span style={{color: '#fff', padding: '5px', borderRadius: '5px', fontSize: '12px'}}>&nbsp;</span>}
                        </div>
                        <div className="my_nfts_meta_cell" style={{justifyContent: 'flex-end'}}>
                          {!CEASE_FIRE && !COMPUTE_IN_PROGRESS && !hasDetachInProgress && thisRaccoonInfo.wings && thisRaccoonInfo.wings !== 'None' && <span style={{backgroundColor: '#6b0000', color: '#fff', padding: '5px', borderRadius: '5px', fontSize: '12px'}} onClick={(e) => {e.preventDefault();e.stopPropagation();detachAttachment(raccoonNum, thisRaccoonInfo, 'wings')}}>Detach {thisRaccoonInfo.wings}</span>}
                          {(!thisRaccoonInfo.wings || thisRaccoonInfo.wings === 'None') && <span style={{color: '#fff', padding: '5px', borderRadius: '5px', fontSize: '12px'}}>&nbsp;</span>}
                        </div>                        
                        </>
                    */}
                    </div>                                      
                    {shouldShowForgeIcon && (
                      <div className="nft_box_applied_upgrade_container">
                        <Tooltip placement="top" content={
                          <div className="applied_upgrade_tooltip">
                            <>
                            {upgradeStrings && upgradeStrings.length > 0 && (
                              <>
                                <div style={{marginBottom: '5px'}}>Upcoming upgrades:</div>
                                  {upgradeStrings && upgradeStrings.map((upgradeStr, i) => {
                                    return (
                                      <div key={`up_${i}`}>{upgradeStr}</div>
                                    )
                                  })}
                              </>
                            )}
                            {upgradeWatchingStrings && upgradeWatchingStrings.length > 0 && (
                              <>
                                <div style={{marginBottom: '5px', marginTop: '5px'}}>Waiting for blockchain:</div>
                                  {upgradeWatchingStrings && upgradeWatchingStrings.map((upgradeStr, i) => {
                                    return (
                                      <div key={`up_${i}`}>{upgradeStr}</div>
                                    )
                                  })}
                              </>
                            )}
                            </>
                          </div>
                        }>
                          <div className="nft_box_applied_upgrade_img_container">
                            <img src="forgeb.png" className="connect_button_icon_upgrades" />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                    {haveUpcomingEpochBonusUpgrades && (
                      <div className="nft_box_upcoming_boost_container" style={{right: shouldShowForgeIcon ? '36px' : undefined}}>
                        <Tooltip placement="top" content={
                          <div className="upcoming_boost_tooltip">
                            <>
                                <div style={{}}>Trait boosts in upcoming snapshot (epoch {epochForBonuses}):</div>
                                {epochBonusTraitMatchStrings && epochBonusTraitMatchStrings.map((bonus, j) => {
                                  if (j < epochBonusTraitMatchStrings.length - 1) {
                                    return <span key={`up_bo_${j}`}>{bonus}, </span>  
                                  } else {
                                    return <span key={`up_bo_${j}`}>{bonus}</span>
                                  }
                                })}
                            </>
                          </div>
                        }>
                          <div>
                            <img src="boosticon.gif" className="upcoming_boost_icon" />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              )
            })
          }
          {state.doneInitialWalletLoad && (!raccoonsInWalletDisplay || raccoonsInWalletDisplay.length <= 0) &&
            <div className="send_invite_result">You do not have any Raccoon NFTs in any of your connected wallets.</div>
          }
          </div>
        }


        {tab === 'mySquads' && 
          <div className="my_squads_container">
          {state.doneInitialWalletLoad && officersInWallet && officersInWallet.length > 0 &&
            officersInWallet.map(officer => {

              let asciiName = officer.assetNameAscii;
              let raccoonNum = asciiName.substring('Raccoon '.length, asciiName.length);
              let officerRaccoonNum = raccoonNum;

              let thisRaccoonInfo = {};
              if (thisRaccoonInfo) {
                thisRaccoonInfo = raccoonInfo[raccoonNum];
              }

              let racSquadInfo = raccoonSquadInfo[parseInt(raccoonNum)];
              if (!racSquadInfo) {
                racSquadInfo = {};
              }

              return (
                <div className="my_squads_squad_row" key={`officer_member_${raccoonNum}`}>
                  <a href={`/raccoon?r=${raccoonNum}`} className="my_nfts_nft_box" key={asciiName} style={{width: `${width/5}px`, maxWidth: `${width/6.5}px`}}>
                    <div>
                      <img src={`https://rs9.s3.us-east-1.amazonaws.com/n/${raccoonNum}.png?cb=${Date.now()}`} className="my_nfts_nft_image" />
                      <div className="my_nfts_nft_label" style={{fontSize: '15px'}}>
                        Raccoon #{raccoonNum}
                        {racSquadInfo.squadImIn && 
                          <div className="squad_add_modal_small_text" style={{minHeight: '20px', fontSize: '13px'}}>Current Squad #{racSquadInfo.squadImIn.squadNum}</div>
                        }
                        {racSquadInfo.squadILead && 
                          <div className="squad_add_modal_small_text" style={{minHeight: '20px', fontSize: '13px'}}>Leads Squad #{racSquadInfo.squadILead.squadNum}</div>
                        }
                        {!racSquadInfo.squadImIn && !racSquadInfo.squadILead && 
                          <div className="squad_add_modal_small_text" style={{minHeight: '20px', fontSize: '13px'}}> </div>
                        }
                      </div>
                      <div className="my_nfts_meta_row_1">
                        {thisRaccoonInfo &&
                          <>
                          <div className="my_nfts_meta_cell" style={{fontSize: '13px'}}>
                            {thisRaccoonInfo.rank && <span>Rank #{thisRaccoonInfo.rank}</span>}
                          </div>
                          <div className="my_nfts_meta_cell" style={{justifyContent: 'flex-end', fontSize: '13px'}}>
                            BPM {parseFloat(thisRaccoonInfo.bpm).toFixed(2)}
                          </div>                        
                          </>
                        }
                      </div>
                      <div className="my_nfts_meta_row_2" style={{alignItems: 'center', width: '100%', marginTop: '25px'}} id="TEST2 TEST"> 
                        {thisRaccoonInfo &&
                          <>
                            <div style={{width: '100%'}}>
                              <Select 
                                options={sortOptionsFields} 
                                defaultValue={{value: 'SELECT CONSUMABLE', label: 'SELECT CONSUMABLE'}}
                                styles={sortOrderStyles}
                                onChange={(option) => {
                                  if (option && option.value) {
                                    setSortField(option.value);
                                  }
                                }}
                              />
                            </div>                       
                          </>
                        }
                      </div> 
                    </div>
                  </a>
                  {officer.raccoonsInSquadILead && officer.raccoonsInSquadILead.length > 0 && officer.raccoonsInSquadILead.map((squadMember, i) => {
                    let raccoonNum = squadMember.raccoonNum;

                    if (raccoonNum === squadMember.officerRaccoonNum) return <div key={`squad_memberidx_${officerRaccoonNum}_${i}`} />;

                    let thisSquadMemberRaccoonInfo = null;
                    if (squadMemberRaccoonInfo[raccoonNum]) {
                      thisSquadMemberRaccoonInfo = squadMemberRaccoonInfo[raccoonNum];
                    }

                    let racSquadInfo = raccoonSquadInfo[parseInt(raccoonNum)];
                    if (!racSquadInfo) {
                      racSquadInfo = {};
                    }

                    return(
                      <div className="my_squads_squad_member" key={`squad_memberidx_${officerRaccoonNum}_${i}`}>
                        <a href={`/raccoon?r=${raccoonNum}`} className="my_nfts_squad_member_nft_box" key={asciiName} style={{width: `${width/7}px`}}>
                          <div>
                            <img src={`https://rs9.s3.us-east-1.amazonaws.com/n/${raccoonNum}.png?cb=${Date.now()}`} className="my_nfts_nft_image" />
                            <div className="my_nfts_nft_label" style={{fontSize: '15px'}}>
                              Raccoon #{raccoonNum}
                              {/*racSquadInfo.squadImIn && 
                                <div className="squad_add_modal_small_text" style={{minHeight: '20px', fontSize: '13px'}}>Current Squad #{squadMember.squadNum}</div>
                              */}
                            </div>
                            <div className="my_nfts_meta_row_1">
                              {thisSquadMemberRaccoonInfo &&
                                <>
                                <div className="my_nfts_meta_cell" style={{fontSize: '13px'}}>
                                  {thisSquadMemberRaccoonInfo.rank && <span>Rank #{thisSquadMemberRaccoonInfo.rank}</span>}
                                </div>
                                <div className="my_nfts_meta_cell" style={{justifyContent: 'flex-end', fontSize: '13px'}}>
                                  BPM {parseFloat(thisSquadMemberRaccoonInfo.bpm).toFixed(2)}
                                </div>                        
                                </>
                              }
                            </div>
                            <div className="my_nfts_meta_row_2" style={{alignItems: 'center', width: '100%', marginTop: '25px'}} id="TEST2 TEST"> 
                              {thisRaccoonInfo &&
                                <>
                                  <div style={{width: '100%'}}>
                                    <Select 
                                      options={consumablesFields} 
                                      defaultValue={{value: 'APPLY CONSUMABLE', label: 'APPLY CONSUMABLE'}}
                                      styles={sortOrderStyles}
                                      onChange={(option) => {
                                        if (option && option.value) {
                                          // set(option.value);
                                        }
                                      }}
                                    />
                                  </div>                       
                                </>
                              }
                            </div> 
                          </div>
                        </a>
                      </div>
                    )
                  })

                  }
                </div>
              )
            })
          }
          {state.doneInitialWalletLoad && (!officersInWallet || officersInWallet.length <= 0) &&
            <div className="send_invite_result" style={{marginLeft: '25px'}}>You do not have any officers in your wallet.</div>
          }
          </div>
        }
        
        <UpgradeUseModal show={showUpgradeUseModal} upgradeName={showUpgradeUseModal} refreshRaccoonInfo={refreshRaccoonInfo} refreshInventory={refreshInventory} inventoryItem={useUpgradeInventoryItem} raccoonsInWallet={raccoonsInWallet} raccoonInfoLocal={raccoonInfo} raccoonSquadInfo={raccoonSquadInfo} raccoonUpgradesApplied={raccoonUpgradesApplied} getUpgradeStatusData={getUpgradeStatusData} parentSetToastColor={setToastColor} close={() => {setUseUpgradeInventoryItem(null); setShowUpgradeUseModal(null);}} />

        <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={9000} />

      </div>
    </>
  );
};


export default MyNFTs;

