import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useMutation,
  useQuery, 
  useLazyQuery,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';

const UPGRADE_BONUS_RATES = {
  'Milk': 30,
  'Canteen': 50,
  'Vegebite': 60,
  'Baked Beans': 50,
  'PotionX': 60,
  'Gummy Raccoon': 65,
  'Mushrooms': 70,
  'Gold Ring': 20,
  'Emerald Ring': 25,
  'Ruby Ring': 30,
  'Diamond Ring': 35,
  'CompoundX Ring': 40,
  'Blue Diamond Ring': 45,
  'Light Wings': 50,
  'Dark Wings': 55,
  'Gold Wings': 60,
  'Psychedelic Wings': 65,
  'CompoundX Wings': 70,
  'Blue Diamond Wings': 75,
};


const TRX_CREATE_WATCH = gql`
  mutation TRXCreateWatch($assetName: String!, $inventoryItemId: String, $requestType: String!, $quantity: Int!, $action: String!, $myWalletAddresses: String!, $raccoonNum: Int) {
    trxCreateWatch( assetName: $assetName, inventoryItemId: $inventoryItemId, requestType: $requestType, quantity: $quantity, action: $action, myWalletAddresses: $myWalletAddresses, raccoonNum: $raccoonNum ) {
      result
      assetNameHex
      raccoonNum
      upgradeType
      consumableOrAttachable
      requestType
      inventoryItemId
      watchId
    }
  }
`;

const USE_INVENTORY_CONSUMABLE = gql`
  mutation UseInventoryConsumable($inventoryItemId: String!, $raccoonNum: Int) {
    useInventoryConsumable(inventoryItemId: $inventoryItemId, raccoonNum: $raccoonNum) {
      result
      raccoonNum
      inventoryItemId
    }
  }
`;


const sortOptionsFields = [
  { value: 'Raccoon #', label: 'Raccoon #' },
  { value: 'BPM', label: 'BPM' },
  { value: 'Rank', label: 'Rank' },
  { value: 'Tier Rank', label: 'Tier Rank' },
];

const sortOptionsOrder = [
  { value: 'Descending', label: 'Descending' },
  { value: 'Ascending', label: 'Ascending' },
];

const filterOptionsProfession = [
  { value: 'All Professions', label: 'All Professions' },
  { value: 'Officers', label: 'Officers' },
  { value: 'Non-Officers', label: 'Non-Officers' },
];

const filterOptionsTier = [
  { value: 'All Tiers', label: 'All Tiers' },
  { value: 'Tier 1', label: 'Tier 1' },
  { value: 'Tier 2', label: 'Tier 2' },
  { value: 'Tier 3', label: 'Tier 3' },
];

const filterOptionsAvailability = [
  { value: 'Available', label: 'Available' },
  { value: 'In Squad', label: 'In Squad' },
  { value: 'Any', label: 'Any' },
];

const filterOptionsBoostUpcoming = [
  { value: 'No Epoch Trait Upcoming', label: 'No Epoch Trait Upcoming' },
  { value: 'Epoch Trait Upcoming', label: 'Epoch Trait Upcoming' },
  { value: 'Any', label: 'Any' },
];

const sortOrderStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: '#000', 
    color: '#efdbb8',
    outline: 'none',
    borderColor: '#444',
    boxShadow: 'none',
    borderRadius: '10px',
    "&:hover"  : {
      outline: 'none',
      borderColor: '#444',
      boxShadow: 'none',
    },
  }),
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none',
      "&:hover"  : {
        backgroundColor: '#222',
      },
    };
  },
  singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
}


const UpgradeUseModal = (props) => {
  let {show, close, upgradeName, inventoryItem, raccoonsInWallet, raccoonInfoLocal, raccoonSquadInfo, raccoonUpgradesApplied, getUpgradeStatusData, parentSetToastColor, refreshRaccoonInfo, refreshInventory, inventoryItemId} = props;

  const [tab, setTab] = useState('add');
  const [messageToRaccoon, setMessageToRaccoon] = useState("");

  const [sortField, setSortField] = useState('Raccoon #');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [filterProfessionValue, setFilterProfessionValue] = useState('All Professions');
  const [filterTierValue, setFilterTierValue] = useState('All Tiers');
  const [filterAvailabilityValue, setFilterAvailabilityValue] = useState('Any');
  const [filterBoostUpcomingValue, setFilterBoostUpcomingValue] = useState('Any');

  const [hideUseButton, setHideUseButton] = useState(false);

  const [toastColor, setToastColor] = useState('#e08705');

  const { state, walletSendConsumableUpgradeNFT, walletSendAttachableUpgradeNFT, walletSendConsumableUpgradeFromInventory, walletSendAttachableUpgradeFromInventory, currentEpoch } = useContext(GlobalStateContext);

  let raccoonNumsInWallet = [];
  if (raccoonsInWallet) {
    raccoonsInWallet.forEach(rac => {
      raccoonNumsInWallet.push(rac.racNum);
    })
  }

  function onUseInventoryConsumable(inventoryItem, raccoonNum) {
    consumeInventoryConsumable({ variables: { inventoryItemId: inventoryItem.id, raccoonNum }});
  }

  const [consumeInventoryConsumable, { data: dataUseInventoryConsumable, loading: loadingUseInventoryConsumable, error: errorUseInventoryConsumable }] = useMutation(USE_INVENTORY_CONSUMABLE, 
    {
      onCompleted: response => {
        setHideUseButton(false);

        if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'success') {
          setToastColor('#14711f');
          if (parentSetToastColor) {
            parentSetToastColor('#14711f');
          }
          setTimeout(() => {
            toast("Upgrade applied.")
            getUpgradeStatusData();
          }, 200);
        } else if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'fail - Already has upgrade coming soon') {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That Raccoon already has a consumable upgrade applied.")
            getUpgradeStatusData();
          }, 200);
        } else if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'fail - Already has too many battle point upgrades applied') {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That Raccoon already has too many battle point upgrades applied.")
            getUpgradeStatusData();
          }, 200);
        } else if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'fail - That Raccoon already has that upgrade applied') {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That Raccoon already has that consumable upgrade applied.")
            getUpgradeStatusData();
          }, 200);
        }
      }
    }
  );


  const [trxCreateWatch, { data: dataTRXCreateWatch, loading: loadingTRXCreateWatch, error: errorTRXCreateWatch }] = useMutation(TRX_CREATE_WATCH, 
    {
      onCompleted: response => {
        setTimeout(() => {
          setHideUseButton(false);
        }, 1000);
        if (response && response.trxCreateWatch && response.trxCreateWatch.result === 'fail - Already has upgrade coming soon') {
            setToastColor('#e08705');
            if (parentSetToastColor) {
              parentSetToastColor('#e08705');
            }
            setTimeout(() => {
              toast("Raccoon already has an upcoming upgrade.")
            }, 200);
        } else  if (response && response.trxCreateWatch && response.trxCreateWatch.result.indexOf('Raccoon already wearing an attachable') >= 0) {
            setToastColor('#e08705');
            if (parentSetToastColor) {
              parentSetToastColor('#e08705');
            }
            setTimeout(() => {
              toast("Raccoon already has an attachable. Detach their existing attachable first.")
            }, 200);
        } else  if (response && response.trxCreateWatch && response.trxCreateWatch.result.indexOf('fail 11 - upgrade already applied to a Raccoon and waiting for blockchain') >= 0) {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That upgrade item is already in progress of being used on a Raccoon and is waiting for the blockchain to process it.")
          }, 200);
        } else if (response && response.trxCreateWatch && response.trxCreateWatch.result.indexOf('fail - That Raccoon already has that upgrade applied') >= 0) {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That Raccoon already has that consumable upgrade applied.")
            getUpgradeStatusData();
          }, 200);
        } else if (response && response.trxCreateWatch && response.trxCreateWatch.result === 'success' && response.trxCreateWatch.assetNameHex) {

          const sendUpgradeSuccess = () => {
            setToastColor('#14711f');
            if (parentSetToastColor) {
              parentSetToastColor('#14711f');
            }
            setTimeout(() => {
              toast("Upgrade sent. Wait 10-30 minutes for the blockchain and then for your My Raccoons list to be updated.")
              getUpgradeStatusData();
            }, 200);
            refreshRaccoonInfo();
            refreshInventory();
          }

          const sendUpgradeFailure = () => {
            setToastColor('#e08705');
            if (parentSetToastColor) {
              parentSetToastColor('#e08705');
            }
            setTimeout(() => {
              toast("Failed to send upgrade. Please make sure you have enough ADA in your wallet (6 for consumables, 12 for attachables) Try again and sign using your wallet extension. Also, wait 2 minutes between requests for your wallet to sync to the blockchain.")
            }, 200);
          }

          if (response.trxCreateWatch.requestType === 'useInventoryItem') {
            if (response.trxCreateWatch.consumableOrAttachable === 'consumable') {
              walletSendConsumableUpgradeFromInventory(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.inventoryItemId, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, upgradeType, sendUpgradeSuccess, sendUpgradeFailure);
            } else if (response.trxCreateWatch.consumableOrAttachable === 'attachable') {
              walletSendAttachableUpgradeFromInventory(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.inventoryItemId, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, upgradeType, sendUpgradeSuccess, sendUpgradeFailure);
            }
          } else {
            if (response.trxCreateWatch.consumableOrAttachable === 'consumable') {
              walletSendConsumableUpgradeNFT(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, sendUpgradeSuccess, sendUpgradeFailure);
            } else if (response.trxCreateWatch.consumableOrAttachable === 'attachable') {
              walletSendAttachableUpgradeNFT(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, sendUpgradeSuccess, sendUpgradeFailure);
            }
          }
          
        }
    }
  });







  if (!show) {
    return <div />
  }

  let numFixedBattlePoints = 0;

  let upgradeType = 'None';
  if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Milk') >= 0) {
    upgradeType = 'Milk';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Canteen') >= 0) {
    upgradeType = 'Canteen';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Vegebite') >= 0) {
    upgradeType = 'Vegebite';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Beans') >= 0) {
    upgradeType = 'Baked Beans';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Potion') >= 0) {
    upgradeType = 'PotionX';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gummy Raccoon') >= 0) {
    upgradeType = 'Gummy Raccoon';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Mushrooms') >= 0) {
    upgradeType = 'Mushrooms';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gold Ring') >= 0) {
    upgradeType = 'Gold Ring';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Emerald Ring') >= 0) {
    upgradeType = 'Emerald Ring';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Ruby Ring') >= 0) {
    upgradeType = 'Ruby Ring';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Diamond Ring') === 0 || upgradeName.indexOf('Diamond Ring') === 3) {
    upgradeType = 'Diamond Ring';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('CompoundX Ring') >= 0) {
    upgradeType = 'CompoundX Ring';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Blue Diamond Ring') >= 0) {
    upgradeType = 'Blue Diamond Ring';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Light Wings') >= 0) {
    upgradeType = 'Light Wings';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Dark Wings') >= 0) {
    upgradeType = 'Dark Wings';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gold Wings') >= 0) {
    upgradeType = 'Gold Wings';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Psychedelic Wings') >= 0) {
    upgradeType = 'Psychedelic Wings';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('CompoundX Wings') >= 0) {
    upgradeType = 'CompoundX Wings';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Blue Diamond Wings') >= 0) {
    upgradeType = 'Blue Diamond Wings';
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('10 Battle Points') >= 0) {
    upgradeType = '10 Battle Points';
    numFixedBattlePoints = 10;
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('20 Battle Points') >= 0) {
    upgradeType = '20 Battle Points';
    numFixedBattlePoints = 20;
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('50 Battle Points') >= 0) {
    upgradeType = '50 Battle Points';
    numFixedBattlePoints = 50;
  } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('100 Battle Points') >= 0) {
    upgradeType = '100 Battle Points';
    numFixedBattlePoints = 100;
  }

  let numEpochs = 2;
  if (upgradeType === 'Baked Beans' || upgradeType === 'PotionX' || upgradeType === 'Gummy Raccoon' || upgradeType === 'Mushrooms') {
      numEpochs = 3;
  } else if (upgradeType === '10 Battle Points' || upgradeType === '20 Battle Points' || upgradeType === '50 Battle Points' || upgradeType === '100 Battle Points') {
    numEpochs = 1;
  }

  let newRaccoonsInWallet = [];
  if (raccoonsInWallet && raccoonsInWallet.length > 0) {
    raccoonsInWallet.forEach(rac => {
      let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
      let racNum = parseInt(racNumStr);

      let newRaccoon = {};
      if (raccoonInfoLocal[racNum]) {
        newRaccoon = {
          ...rac, 
          bpm: parseFloat(raccoonInfoLocal[racNum].bpm),
          rank: raccoonInfoLocal[racNum].rank,
          tier: raccoonInfoLocal[racNum].tier,
          tierRank: raccoonInfoLocal[racNum].tierRank
        }
      } else {
        newRaccoon = {
          ...rac, 
        }
      }

      let racSquadInfo = raccoonSquadInfo[racNum];
      if (!racSquadInfo) {
        racSquadInfo = {};
      }


      let meetsBoostUpcomingFilter = false;
      if (filterBoostUpcomingValue === 'Any') {
        meetsBoostUpcomingFilter = true;
      } else if (filterBoostUpcomingValue === 'No Epoch Trait Upcoming' && !raccoonInfoLocal[racNum].upcoming_left_hand_trait_match && !raccoonInfoLocal[racNum].upcoming_right_hand_trait_match && !raccoonInfoLocal[racNum].upcoming_wardrobe_trait_match && !raccoonInfoLocal[racNum].upcoming_head_trait_match && !raccoonInfoLocal[racNum].upcoming_mouth_trait_match && !raccoonInfoLocal[racNum].upcoming_eyewear_trait_match && !raccoonInfoLocal[racNum].upcoming_eye_trait_match) {
        meetsBoostUpcomingFilter = true;
      } if (filterBoostUpcomingValue === 'Epoch Trait Upcoming' && (raccoonInfoLocal[racNum].upcoming_left_hand_trait_match || raccoonInfoLocal[racNum].upcoming_right_hand_trait_match || raccoonInfoLocal[racNum].upcoming_wardrobe_trait_match || raccoonInfoLocal[racNum].upcoming_head_trait_match || raccoonInfoLocal[racNum].upcoming_mouth_trait_match || raccoonInfoLocal[racNum].upcoming_eyewear_trait_match || raccoonInfoLocal[racNum].upcoming_eye_trait_match)) {
        meetsBoostUpcomingFilter = true;
      }

      let meetsAvailabilityFilter = false;
      if (filterAvailabilityValue === 'Any') {
        meetsAvailabilityFilter = true;
      } else if (filterAvailabilityValue === 'Available' && !racSquadInfo.squadImIn && !racSquadInfo.squadILead) {
        meetsAvailabilityFilter = true;
      } if (filterAvailabilityValue === 'In Squad' && (racSquadInfo.squadImIn || racSquadInfo.squadILead)) {
        meetsAvailabilityFilter = true;
      }

      
      let meetsTierFilter = false;
      if (meetsAvailabilityFilter) {
        if (filterProfessionValue === 'All Professions') {
          meetsTierFilter = true;
        } else if (filterProfessionValue === 'Officers' && racSquadInfo.squadILead) {
          meetsTierFilter = true;
        } else if (filterProfessionValue === 'Non-Officers' && !racSquadInfo.squadILead) {
          meetsTierFilter = true;
        }
      }

      if (meetsTierFilter && meetsAvailabilityFilter && meetsBoostUpcomingFilter) {
        if (filterTierValue === 'All Tiers') {
          newRaccoonsInWallet.push(newRaccoon);
        } else if (filterTierValue === 'Tier 1' && raccoonInfoLocal[racNum].tier === 1) {
          newRaccoonsInWallet.push(newRaccoon);
        } else if (filterTierValue === 'Tier 2' && raccoonInfoLocal[racNum].tier === 2) {
          newRaccoonsInWallet.push(newRaccoon);
        } else if (filterTierValue === 'Tier 3' && raccoonInfoLocal[racNum].tier === 3) {
          newRaccoonsInWallet.push(newRaccoon);
        }        
      }

    });
  }
  let raccoonsInWalletDisplay = newRaccoonsInWallet;

  const sortRaccoonsInWallet = (oldRaccoonsInWallet) => {
    if (sortField === 'Raccoon #' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.racNum < b.racNum) return -1;
        if (a.racNum > b.racNum) return 1;
        return 0;
      });
    } else if (sortField === 'Raccoon #' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.racNum < b.racNum) return 1;
        if (a.racNum > b.racNum) return -1;
        return 0;
      });
    } else if (sortField === 'BPM' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.bpm < b.bpm) return -1;
        if (a.bpm > b.bpm) return 1;
        return 0;
      });
    } else if (sortField === 'BPM' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.bpm < b.bpm) return 1;
        if (a.bpm > b.bpm) return -1;
        return 0;
      });
    } else if (sortField === 'Rank' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.rank < b.rank) return -1;
        if (a.rank > b.rank) return 1;
        return 0;
      });
    } else if (sortField === 'Rank' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.rank < b.rank) return 1;
        if (a.rank > b.rank) return -1;
        return 0;
      });
    } else if (sortField === 'Tier Rank' && sortDirection === 'Ascending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.tierRank < b.tierRank) return -1;
        if (a.tierRank > b.tierRank) return 1;
        return 0;
      });
    } else if (sortField === 'Tier Rank' && sortDirection === 'Descending') {
      return oldRaccoonsInWallet.sort((a, b) => {
        if (a.tierRank < b.tierRank) return 1;
        if (a.tierRank > b.tierRank) return -1;
        return 0;
      });
    }
    return oldRaccoonsInWallet;
  }

  if (raccoonsInWalletDisplay && raccoonsInWalletDisplay.forEach) {
    raccoonsInWalletDisplay.forEach(rac => {
      let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
      let racNum = parseInt(racNumStr);
      rac.racNum = racNum;
    });
  }
  raccoonsInWalletDisplay = sortRaccoonsInWallet(raccoonsInWalletDisplay);

  let epochsString = `${currentEpoch} and ${currentEpoch + 1}`;
  if (numEpochs === 3) {
    epochsString = `${currentEpoch}, ${currentEpoch + 1} and ${currentEpoch + 2}`;
  } else if (numEpochs === 4) {
    epochsString = `${currentEpoch}, ${currentEpoch + 1}, ${currentEpoch + 2} and ${currentEpoch + 3}`;
  } else if (numEpochs === 5) {
    epochsString = `${currentEpoch}, ${currentEpoch + 1}, ${currentEpoch + 2}, ${currentEpoch + 3} and ${currentEpoch + 4}`;
  }

  const callUseOrMintInventoryItem = ({useOrMint, inventoryItemId, raccoonNum}) => {
    // useOrMintInventoryItem({ variables: { useOrMint: 'use', inventoryItemId: inventoryItem.id, raccoonNum: parseInt(raccoonNum)  } });
  }

  return (
    <>
      <div className="squad_add_modal_outside_container"  onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        close();
      }}>
        <div className="squad_add_modal" onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <div className="squad_add_modal_header_box">
          <div className="leaderboard_title_row">
            <div className="global_leaderboard_title_text">
              <div className={"squad_add_modal_header_selected"}>SELECT A RACCOON</div>
            </div>      
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
          {(upgradeType.indexOf(' Ring') < 0 && upgradeType.indexOf(' Wings') < 0 && upgradeType.indexOf(' Battle Points') < 0) &&
            <div className={"upgrade_will_give_text"}>This {upgradeType} Upgrade will give a {UPGRADE_BONUS_RATES[upgradeType]}% points earnings boost for Epochs {epochsString}</div>
          }
          {(upgradeType.indexOf(' Ring') >= 0 || upgradeType.indexOf(' Wings') >= 0) &&
            <div className={"upgrade_will_give_text"}>This {upgradeType} Upgrade will give a {UPGRADE_BONUS_RATES[upgradeType]}% points earnings boost while worn</div>
          }
          {(upgradeType.indexOf(' Battle Points') >= 0) &&
            <div className={"upgrade_will_give_text"}>This {upgradeType} Upgrade will give {numFixedBattlePoints} extra points at the end of the current epoch</div>
          }

            <div style={{marginLeft: '35px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
              <div style={{color: '#efdbb8', marginRight: '10px'}}>
                Sort by:
              </div>
              <div style={{width: '160px'}}>
                <Select 
                  options={sortOptionsFields} 
                  defaultValue={{value: sortField, label: sortField}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setSortField(option.value);
                    }
                  }}
                />
              </div>
              <div style={{width: '160px', marginLeft: '30px'}}>
                <Select 
                  options={sortOptionsOrder} 
                  defaultValue={{value: sortDirection, label: sortDirection}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setSortDirection(option.value);
                    }
                  }}
                />
              </div>
              <div style={{width: '180px', marginLeft: '30px'}}>
                <Select 
                  options={filterOptionsProfession} 
                  defaultValue={{value: filterProfessionValue, label: filterProfessionValue}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setFilterProfessionValue(option.value);
                    }
                  }}
                />
              </div>
              <div style={{width: '160px', marginLeft: '30px'}}>
                <Select 
                  options={filterOptionsTier} 
                  defaultValue={{value: filterTierValue, label: filterTierValue}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setFilterTierValue(option.value);
                    }
                  }}
                />
              </div>                
              <div style={{width: '145px', marginLeft: '30px'}}>
                <Select 
                  options={filterOptionsAvailability} 
                  defaultValue={{value: filterAvailabilityValue, label: filterAvailabilityValue}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setFilterAvailabilityValue(option.value);
                    }
                  }}
                />
              </div>   
              <div style={{width: '230px', marginLeft: '30px'}}>
                <Select 
                  options={filterOptionsBoostUpcoming} 
                  defaultValue={{value: filterBoostUpcomingValue, label: filterBoostUpcomingValue}}
                  styles={sortOrderStyles}
                  onChange={(option) => {
                    if (option && option.value) {
                      setFilterBoostUpcomingValue(option.value);
                    }
                  }}
                />
              </div>            
            </div>

            <div className="squad_add_modal_nfts_container" style={{padding: '0px 20px 20px 20px', marginTop: '15px', height: '67vh'}}>
              {raccoonsInWalletDisplay && raccoonsInWalletDisplay.length > 0 &&
                raccoonsInWalletDisplay.map(raccoon => {
                  let asciiName = raccoon.assetNameAscii;
                  let raccoonNum = asciiName.substring('Raccoon '.length, asciiName.length);

                  let thisRaccoonInfo = {};
                  if (thisRaccoonInfo) {
                    thisRaccoonInfo = raccoonInfoLocal[raccoonNum];
                  }

                  let isConsumableItem = false;
                  let isBattlePointsUpgrade = false;
                  let isAttachableItem = false;
                  let numPointsForThisBattlePointsUpgrade = 0;

                  if (upgradeType && (upgradeType.indexOf('Milk') >= 0 || upgradeType.indexOf('Canteen') >= 0 || upgradeType.indexOf('Vegebite') >= 0 || upgradeType.indexOf('Baked Beans') >= 0 || upgradeType.indexOf('PotionX') >= 0 || upgradeType.indexOf('Gummy Raccoon') >= 0 || upgradeType.indexOf('Mushrooms') >= 0 || upgradeType.indexOf(' Battle Points') >= 0)) {
                    isConsumableItem = true;
                  } else if (upgradeType && (upgradeType.indexOf(' Ring') >= 0 || upgradeType.indexOf(' Wings') >= 0)) {
                    isAttachableItem = true;
                  }

                  if (upgradeType && upgradeType.indexOf(' Battle Points') >= 0) {
                    isBattlePointsUpgrade = true;
                    if (upgradeType === '10 Battle Points') {
                      numPointsForThisBattlePointsUpgrade = 10;
                    } else if (upgradeType === '20 Battle Points') {
                      numPointsForThisBattlePointsUpgrade = 20;
                    } else if (upgradeType === '50 Battle Points') {
                      numPointsForThisBattlePointsUpgrade = 50;
                    } else if (upgradeType === '100 Battle Points') {
                      numPointsForThisBattlePointsUpgrade = 100;
                    }
                  }

                  let hasConsumableUpgradesComing = false;
                  let shouldBlockBecauseTooManyBattlePointUpgrades = false;
                  let numBattlePointsUpgradesAlready = 0;
                  let numBattlePointsUpgradePointsAlready = 0;
                  let hasWings = false;
                  let hasRing = false;

                  if (thisRaccoonInfo && ((thisRaccoonInfo['ring'] && thisRaccoonInfo['ring'] !== 'None'))) {
                    hasRing = true;
                  }
                  if (thisRaccoonInfo && ((thisRaccoonInfo['wings'] && thisRaccoonInfo['wings'] !== 'None'))) {
                    hasWings = true;
                  }


                  if (thisRaccoonInfo && (thisRaccoonInfo['watchAssetName'])) {
                    if (thisRaccoonInfo['watchAssetName'].includes(' Ring')) {
                      hasRing = true;
                    } else if (thisRaccoonInfo['watchAssetName'].includes(' Wings')) {
                      hasWings = true;
                    }
                  }


                  let upgradesAppliedToThisRaccoon = null;
                  if (raccoonUpgradesApplied[raccoonNum] && raccoonUpgradesApplied[raccoonNum].length > 0) {
                    upgradesAppliedToThisRaccoon = [];
                    raccoonUpgradesApplied[raccoonNum].forEach(upgradeForThisRaccoon => {
                      if (upgradeForThisRaccoon && upgradeForThisRaccoon.reason === 'applied') {
                        if (upgradeForThisRaccoon.upgradeType.indexOf(' Battle Points') < 0) {
                          hasConsumableUpgradesComing = true;
                        } else {
                          numBattlePointsUpgradesAlready++;
                          if (upgradeForThisRaccoon.upgradeType === '10 Battle Points') {
                            numBattlePointsUpgradePointsAlready += 10;
                          } else if (upgradeForThisRaccoon.upgradeType === '20 Battle Points') {
                            numBattlePointsUpgradePointsAlready += 20;
                          } else if (upgradeForThisRaccoon.upgradeType === '50 Battle Points') {
                            numBattlePointsUpgradePointsAlready += 50;
                          } else if (upgradeForThisRaccoon.upgradeType === '100 Battle Points') {
                            numBattlePointsUpgradePointsAlready += 100;
                          }
                        }
                        upgradesAppliedToThisRaccoon.push(upgradeForThisRaccoon);
                      }
                    });
                  }
                  
                  let upgradesWaitingForThisRaccoon = null;
                  if (raccoonUpgradesApplied[raccoonNum] && raccoonUpgradesApplied[raccoonNum].length > 0) {
                    upgradesWaitingForThisRaccoon = [];
                    raccoonUpgradesApplied[raccoonNum].forEach(upgradeForThisRaccoon => {
                      if (upgradeForThisRaccoon && upgradeForThisRaccoon.reason === 'watching' && upgradeForThisRaccoon.status === 'waitingForBlockchain') {
                        if (upgradeForThisRaccoon.upgradeType.indexOf(' Battle Points') < 0) {
                          hasConsumableUpgradesComing = true;
                        }
                        upgradesWaitingForThisRaccoon.push(upgradeForThisRaccoon);
                      }
                    });
                  }
    
                  let upgradeStrings = [];
                  if (upgradesAppliedToThisRaccoon && upgradesAppliedToThisRaccoon.length > 0) {
                    upgradesAppliedToThisRaccoon.forEach(upgrade => {
                      if (upgrade.upgradeType.indexOf(' Battle Points') < 0) {
                        hasConsumableUpgradesComing = true;
                      }

                      if (upgrade.assetName) {
                          if (upgrade.assetName.indexOf(' Battle Points') >= 0) {
                            upgradeStrings.push(`${upgrade.assetName} for epoch ${upgrade.epoch}`);
                          } else {
                            upgradeStrings.push(`${upgrade.assetName}: ${UPGRADE_BONUS_RATES[upgrade.upgradeType]}% boost for epoch ${upgrade.epoch}`);
                          }
                        } else {
                          if (upgrade.upgradeType.indexOf(' Battle Points') >= 0) {
                            upgradeStrings.push(`${upgrade.upgradeType} for epoch ${upgrade.epoch}`);
                          } else {
                            upgradeStrings.push(`${upgrade.upgradeType}: ${UPGRADE_BONUS_RATES[upgrade.upgradeType]}% boost for epoch ${upgrade.epoch}`);
                          }
                      }
                    });
                  }

                  let firstOneHundredPoints = false;
                  if (numBattlePointsUpgradesAlready === 0 && numPointsForThisBattlePointsUpgrade === 100) {
                      firstOneHundredPoints = true;   
                  }

                  if (numBattlePointsUpgradesAlready >= 3 && isConsumableItem && upgradeType.indexOf(' Battle Points') >= 0) {
                    shouldBlockBecauseTooManyBattlePointUpgrades = true;
                  }

                  if ((numBattlePointsUpgradePointsAlready + numPointsForThisBattlePointsUpgrade) > 60 && !firstOneHundredPoints) {
                    shouldBlockBecauseTooManyBattlePointUpgrades = true;
                  }


                  let upgradeWatchingStrings = [];
                  if (upgradesWaitingForThisRaccoon && upgradesWaitingForThisRaccoon.length > 0) {
                    upgradesWaitingForThisRaccoon.forEach(upgrade => {
                      let upgradeName = upgrade.assetName;
                      let upgradeType = 'None';
                      if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Milk') >= 0) {
                        upgradeType = 'Milk';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Canteen') >= 0) {
                        upgradeType = 'Canteen';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Vegebite') >= 0) {
                        upgradeType = 'Vegebite';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Beans') >= 0) {
                        upgradeType = 'Baked Beans';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Potion') >= 0) {
                        upgradeType = 'PotionX';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gummy Raccoon') >= 0) {
                        upgradeType = 'Gummy Raccoon';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Mushrooms') >= 0) {
                        upgradeType = 'Mushrooms';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('10 Battle Points') >= 0) {
                        upgradeType = '10 Battle Points';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('20 Battle Points') >= 0) {
                        upgradeType = '20 Battle Points';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('50 Battle Points') >= 0) {
                        upgradeType = '50 Battle Points';
                      } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('100 Battle Points') >= 0) {
                        upgradeType = '100 Battle Points';
                      }                                 
                      upgradeWatchingStrings.push(`${upgrade.assetName}: ${UPGRADE_BONUS_RATES[upgradeType]}% boost`);
                    });
                  }

                  let racSquadInfo = raccoonSquadInfo[parseInt(raccoonNum)];
                  if (!racSquadInfo) {
                    racSquadInfo = {};
                  }

                  let epochBonusTraitMatchStrings = [];
                  let haveUpcomingEpochBonusUpgrades = false;
                  let epochForBonuses = 0;
                  if (thisRaccoonInfo) {
                    if (thisRaccoonInfo.upcoming_left_hand_trait_match) {
                      epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_left_hand_trait_match);
                      haveUpcomingEpochBonusUpgrades = true;
                      epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                    }
                    if (thisRaccoonInfo.upcoming_right_hand_trait_match) {
                      epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_right_hand_trait_match);
                      haveUpcomingEpochBonusUpgrades = true;
                      epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                    }
                    if (thisRaccoonInfo.upcoming_wardrobe_trait_match) {
                      epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_wardrobe_trait_match);
                      haveUpcomingEpochBonusUpgrades = true;
                      epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                    }
                    if (thisRaccoonInfo.upcoming_head_trait_match) {
                      epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_head_trait_match);
                      haveUpcomingEpochBonusUpgrades = true;
                      epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                    }
                    if (thisRaccoonInfo.upcoming_mouth_trait_match) {
                      epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_mouth_trait_match);
                      haveUpcomingEpochBonusUpgrades = true;
                      epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                    }
                    if (thisRaccoonInfo.upcoming_eyewear_trait_match) {
                      epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_eyewear_trait_match);
                      haveUpcomingEpochBonusUpgrades = true;
                      epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                    }
                    if (thisRaccoonInfo.upcoming_eye_trait_match) {
                      epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_eye_trait_match);
                      haveUpcomingEpochBonusUpgrades = true;
                      epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
                    }
                  }

                  let shouldShowForgeIcon = ((upgradesAppliedToThisRaccoon && upgradesAppliedToThisRaccoon.length > 0) || (upgradesWaitingForThisRaccoon && upgradesWaitingForThisRaccoon.length > 0));

                  return (
                    <a key={`squadaddmodal_${raccoonNum}`} href={`/raccoon?r=${raccoonNum}`} className="my_nfts_nft_box" style={{cursor: 'initial'}}>
                      <div >
                        <img src={`https://rs9.s3.us-east-1.amazonaws.com/n/${raccoonNum}.png?cb=${Date.now()}`} className="my_nfts_nft_image" />
                        <div style={{display: 'flex', flexDirection: 'row', flex: 1, padding: '5px 0px 0px 0px'}}>
                          <div className="my_nfts_nft_label" style={{flex: 0.7}}>
                            <div>Raccoon #{raccoonNum}</div>
                            {racSquadInfo.squadImIn && 
                                <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}>Current Squad #{racSquadInfo.squadImIn.squadNum}</div>
                            }
                            {racSquadInfo.squadILead && 
                              <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}>Leads Squad #{racSquadInfo.squadILead.squadNum}</div>
                            }
                            {!racSquadInfo.squadImIn && !racSquadInfo.squadILead && 
                              <div className="squad_add_modal_small_text" style={{minHeight: '20px'}}> </div>
                            }                            
                          </div>
                          <div className="squad_add_modal_add_button_container" style={{marginTop: '0'}}>
                            {((isConsumableItem /*&& (!hasConsumableUpgradesComing || isBattlePointsUpgrade)*/) || (isAttachableItem && !hasRing && !hasWings)) && !shouldBlockBecauseTooManyBattlePointUpgrades && (
                              <div 
                                className="squad_add_modal_add_button" 
                                style={{
                                  cursor: (!hideUseButton && ((isConsumableItem /*&& (!hasConsumableUpgradesComing || isBattlePointsUpgrade)*/) || (isAttachableItem && !hasRing && !hasWings))) ? 'pointer' : 'auto',
                                  backgroundImage: (!hideUseButton && ((isConsumableItem /*&& (!hasConsumableUpgradesComing || isBattlePointsUpgrade)*/) || (isAttachableItem && !hasRing && !hasWings))) ? undefined : 'inherit',
                                  backgroundCOlor: '1px solid #444',
                                  border: '1px solid #777'
                                }}
                                onClick={() => {
                                  setHideUseButton(true);

                                  let shouldRun = (!hideUseButton && ((isConsumableItem /*&& (!hasConsumableUpgradesComing || isBattlePointsUpgrade)*/) || (isAttachableItem && !hasRing && !hasWings)));

                                  if (shouldRun) {
                                    if (isConsumableItem) {
                                      if (inventoryItem) {
                                        onUseInventoryConsumable(inventoryItem, parseInt(raccoonNum));
                                      } else {
                                        trxCreateWatch({ variables: { assetName: upgradeName, requestType: 'useUpgradeNFT', quantity: parseInt(1), action: 'trxCreateWatch', myWalletAddresses: 'testAddresses', raccoonNum: parseInt(raccoonNum)  } });
                                      }
                                    } else if (isAttachableItem) {
                                      if (!inventoryItem) {
                                        trxCreateWatch({ variables: { assetName: upgradeName, requestType: 'useUpgradeNFT', quantity: parseInt(1), action: 'trxCreateWatch', myWalletAddresses: 'testAddresses', raccoonNum: parseInt(raccoonNum)  } });
                                      } else {
                                        trxCreateWatch({ variables: { assetName: inventoryItem.type, requestType: 'useInventoryItem', inventoryItemId: inventoryItem.id, quantity: parseInt(1), action: 'trxCreateWatch', myWalletAddresses: 'testAddresses', raccoonNum: parseInt(raccoonNum) }});
                                      }
                                    }
                                  }
       

                                  

                                }}
                              >
                                Use
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="my_nfts_meta_row_1" style={{marginTop: '0px'}}>
                            {thisRaccoonInfo &&
                              <>
                              <div className="my_nfts_meta_cell" style={{fontSize: '15px'}}>
                                {thisRaccoonInfo.rank && <span>Rank: {thisRaccoonInfo.rank}</span>}
                              </div>
                              <div className="my_nfts_meta_cell" style={{justifyContent: 'flex-end', fontSize: '15px'}}>
                                BPM {thisRaccoonInfo.bpm}
                              </div>                        
                              </>
                            }
                        </div>
                        <div className="my_nfts_meta_row_2">
                          {thisRaccoonInfo &&
                            <>
                            <div className="my_nfts_meta_cell">
                              {thisRaccoonInfo.rank && <span>Tier Rank: {thisRaccoonInfo.tierRank}</span>}
                            </div>
                            {thisRaccoonInfo.tier && (
                              <div className="my_nfts_meta_cell" style={{justifyContent: 'flex-end'}}>
                                Tier {thisRaccoonInfo.tier}
                              </div>                        
                            )}
                            </>
                          }
                        </div>                            
                        {shouldShowForgeIcon && (
                          <div className="nft_box_applied_upgrade_container">
                            <Tooltip placement="bottom" content={
                              <div className="applied_upgrade_tooltip">
                                <>
                                {upgradeStrings && upgradeStrings.length > 0 && (
                                  <>
                                    <div style={{marginBottom: '5px'}}>Upcoming upgrades:</div>
                                      {upgradeStrings && upgradeStrings.map((upgradeStr, i) => {
                                        return (
                                          <div key={`up_${i}`}>{upgradeStr}</div>
                                        )
                                      })}
                                  </>
                                )}
                                {upgradeWatchingStrings && upgradeWatchingStrings.length > 0 && (
                                  <>
                                    <div style={{marginBottom: '5px'}}>Waiting for blockchain:</div>
                                      {upgradeWatchingStrings && upgradeWatchingStrings.map((upgradeStr, i) => {
                                        return (
                                          <div key={`up_${i}`}>{upgradeStr}</div>
                                        )
                                      })}
                                  </>
                                )}
                                </>
                              </div>
                            }>
                              <div className="nft_box_applied_upgrade_img_container">
                                <img src="forgeb.png" className="connect_button_icon_upgrades" />
                              </div>
                            </Tooltip>
                          </div>
                        )}   
                        {haveUpcomingEpochBonusUpgrades && (
                          <div className="nft_box_upcoming_boost_container" style={{right: shouldShowForgeIcon ? '36px' : undefined}}>
                            <Tooltip placement="bottom" content={
                              <div className="upcoming_boost_tooltip">
                                <>
                                    <div style={{}}>Trait boosts in upcoming snapshot (epoch {epochForBonuses}):</div>
                                    {epochBonusTraitMatchStrings && epochBonusTraitMatchStrings.map((bonus, j) => {
                                      if (j < epochBonusTraitMatchStrings.length - 1) {
                                        return <span key={`up_bo_${j}`}>{bonus}, </span>  
                                      } else {
                                        return <span key={`up_bo_${j}`}>{bonus}</span>
                                      }
                                    })}
                                </>
                              </div>
                            }>
                              <div>
                                <img src="boosticon.gif" className="upcoming_boost_icon" />
                              </div>
                            </Tooltip>
                          </div>
                        )}                
                      </div>
                    </a>
                  )
                })
              }
            </div>
        </div>
        </div>
        <div className="squad_add_modal_close_button" onClick={() => close()}>
          X
        </div>
      </div>
      <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={9000} />
    </>
  );
};


export default UpgradeUseModal;

