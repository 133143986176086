import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useQuery, 
  useLazyQuery,
  useMutation,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import SelectRaccoonModal from './SelectRaccoonModal';
import SelectItemModal from './SelectItemModal';
import { detect } from 'detect-browser';
import ClipLoader from "react-spinners/ClipLoader";
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';

const myWallets = ['stake1u9w3dt4zw038lk87wcjlduqecwn3h5n7vd0ar97890ykrscya9zms', 'stake1u88rs565mlmlrkz4z8ssv39jrgddaujglgtu5vcreg9872cmzxvx6'] // stakepool, namiwallet


const browser = detect();

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}

const GET_RACCOONS_SQUADS = gql`
  query GetRaccoonsSquads($raccoonNumbers: String!, $source: String) {
    raccoonsSquads(raccoonNumbers: $raccoonNumbers, source: $source) {
      results
      source
    }
  }
`;

const GET_RACCOONS_UPGRADE_STATUS = gql`
  query GetRaccoonsUpgradeStatus($raccoonNumbers: String!) {
    raccoonsUpgradeStatus(raccoonNumbers: $raccoonNumbers) {
      results
      upgrades
    }
  }
`;

const GET_UPGRADE_STATUS = gql`
  query GetUpgradeStatus($upgradeNames: String!) {
    upgradeStatus(upgradeNames: $upgradeNames) {
      results
      upgrades
    }
  }
`;

const UPGRADE_BONUS_RATES = {
  'Milk': 30,
  'Canteen': 50,
  'Vegebite': 60,
  'Baked Beans': 50,
  'PotionX': 60,
  'Gummy Raccoon': 65,
  'Mushrooms': 70
};

const GET_RACCOON_INFO_FOR_NFT_LIST = gql`
  query GetRaccoonInfoForNFTList($raccoons: String!) {
    raccoonInfoForNFTList(raccoons: $raccoons) {
      raccoonInfo
    }
  }
`;

const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;

const TRX_CREATE_WATCH = gql`
  mutation TRXCreateWatch($assetName: String!, $requestType: String!, $inventoryItemId: String, $quantity: Int!, $action: String!, $myWalletAddresses: String!, $raccoonNum: Int) {
    trxCreateWatch( assetName: $assetName, requestType: $requestType, inventoryItemId: $inventoryItemId, quantity: $quantity, action: $action, myWalletAddresses: $myWalletAddresses, raccoonNum: $raccoonNum ) {
      result
      assetNameHex
      raccoonNum
      upgradeType
      consumableOrAttachable
      requestType
      inventoryItemId
      watchId
    }
  }
`;


const GET_INVENTORY = gql`
  query GetInventory {
    inventory {
      id
      type
      status
      earned_method
      was_listed_raccoon_at_end_of_battle_round
    }
  }
`;

const BULK_DETACH = gql`
  mutation BulkDetach($detachPairs: String!) {
    bulkDetach( detachPairs: $detachPairs ) {
      result
      detachPairs
      bulk_detach_watch_id
      operationId
    }
  }
`;


const BULK_DETACH_UPDATE_STATUS = gql`
  mutation BulkDetachUpdateStatus($bulk_detach_watch_id: String!, $status: String!, $trx_hash: String, $operationId: String) {
    bulkDetachUpdateStatus( bulk_detach_watch_id: $bulk_detach_watch_id, status: $status, trx_hash: $trx_hash, operationId: $operationId ) {
      result
      detachPairs
      bulk_detach_watch_id
      trx_hash
    }
  }
`;



const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};




const BulkDetach = (props, v2, v3) => {
  const { width, height } = useWindowSize();

  const [tab, setTab] = useState('myRaccoons');
  const [showForgeSelectItemModal, setShowForgeSelectItemModal] = useState(false);
  
  const [cellNumberToSelectItemFor, setCellNumberToSelectItemFor] = useState(null);
  const [cellItems, setCellItems] = useState({});

  const [raccoonsInWallet, setRaccoonsInWallet] = useState([]);
  const [raccoonSquadInfo, setRaccoonSquadInfo] = useState([]);
  const [raccoonInfo, setRaccoonInfo] = useState([]);
  const [upgradeStatus, setUpgradeStatus] = useState([]);

  const [detachPairs, setDetachPairs] = useState([]);

  const [squadMemberRaccoonInfo, setSquadMemberRaccoonInfo] = useState([]);

  const [inventory, setInventory] = useState([]);

  const [sortField, setSortField] = useState('Raccoon #');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [filterProfessionValue, setFilterProfessionValue] = useState('All Professions');
  const [filterTierValue, setFilterTierValue] = useState('All Tiers');
  const [filterAvailabilityValue, setFilterAvailabilityValue] = useState('Any');
  const [filterBoostUpcomingValue, setFilterBoostUpcomingValue] = useState('Any');

  const [filterUpgradesMyUpgrades, setFilterUpgradesMyUpgrades] = useState('Any');
  const [filterUpgradesMyInventory, setFilterUpgradesMyUInventory] = useState('Any');

  const [walletAddress, setWalletAddrress] = useState(null);

  const { state, walletSendDetachRequest, walletMintInventoryItem, walletBulkDetach, team, raccoonUpgradesApplied, globalLucid, backgroundRefreshWallet } = useContext(GlobalStateContext);

  const [toastColor, setToastColor] = useState('#e08705');
  
  const [getRaccoonsSquads, { loading: loadingGetRaccoonsSquads, error: errorGetRaccoonsSquads, data: dataGetRaccoonsSquads, refetch: refetchGetRaccoonsSquads }] = useLazyQuery(GET_RACCOONS_SQUADS);
  
  const [getRaccoonsUpgradeStatus, { loading: loadingGetRaccoonsUpdateStatus, error: errorGetRaccoonsUpdateStatus, data: dataGetRaccoonsUpdateStatus, refetch: refetchGetRaccoonsUpdateStatus }] = useLazyQuery(GET_RACCOONS_UPGRADE_STATUS);

  const [getUpgradeStatus, { loading: loadingGetUpdradeStatus, error: errorGetUpdradeStatus, data: dataGetUpdradeStatus, refetch: refetchGetUpdradeStatus }] = useLazyQuery(GET_UPGRADE_STATUS);

  const [getInventory, { loading: loadingGetInventory, error: errorGetInventory, data: dataGetInventory, refetch: refetchGetInventory }] = useLazyQuery(GET_INVENTORY);



  const [bulkDetachUpdateStatus, { data: dataBulkDetachUpdateStatus, loading: loadingBulkDetachUpdateStatus, error: errorBulkDetachUpdateStatus }] = useMutation(BULK_DETACH_UPDATE_STATUS, 
    {
      onCompleted: response => {
        if (response && response.bulkDetachUpdateStatus && response.bulkDetachUpdateStatus.result === 'success') {
          setToastColor('#14711f');
          setTimeout(() => {
            toast("Bulk detach request added to queue. Please wait 5 - 15 minutes for the minter to complete your request.")
          }, 200);
          return;
        } else {
          setTimeout(() => {
            toast("Sorry, there was an error completing your bulk detach request. Ensure that you have 10 ADA in your wallet for every item that you wish to attach.")
          }, 200);
          return;
        }
    }
  });



  const [bulkDetach, { data: dataBulkDetach, loading: loadingBulkDetach, error: errorBulkDetach }] = useMutation(BULK_DETACH, 
    {
      onCompleted: response => {
        if (response && response.bulkDetach && response.bulkDetach.result === 'success') {

          const bulkDetachSuccess = ({bulkDetach, trx_hash}) => {
            bulkDetachUpdateStatus({ variables: { bulk_detach_watch_id: bulkDetach.bulk_detach_watch_id, status: 'txSubmitted', trx_hash, operationId: response.bulkDetach.operationId  }})
          }

          const bulkDetachFailure = ({bulkDetach, e}) => {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Sorry, there was an error creating your bulk detach request.")
            }, 200);
            return;
          }

          walletBulkDetach({bulkDetach: response.bulkDetach, bulkDetachSuccess, bulkDetachFailure})
        }  else {
          setToastColor('#e08705');
          setTimeout(() => {
            toast("Sorry, there was an error creating your bulk detach request")
          }, 200);
          return;
        }
    }
  });



  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          localRefreshWallet();
          setTimeout(() => {
            localRefreshWallet();
          }, 5000);
          getUpgradeStatusData();
          getInventory();
        }
    }
  });



  const localRefreshWallet = async (parms) => {
    let fromApi = false;
    if (parms && parms.fromApi) {
      fromApi = true;
    }
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
            getRaccoonsUpgradeStatus({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
          }
        }

        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();
          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          if (fromApi) {
            setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
          }
        }

        setRaccoonsInWallet(raccoonsInWallet);
      }
    }
  }

  let raccoonNumsInWallet = [];
  if (raccoonsInWallet) {
    raccoonsInWallet.forEach(rac => {
      let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
      let racNum = parseInt(racNumStr);
      raccoonNumsInWallet.push(racNum);
    })
  }
  
  const { data: dataGetRaccoonInfoForNFTList, loading: loadingGetRaccoonInfoForNFTList, error: errorGetRaccoonInfoForNFTList } = useQuery(GET_RACCOON_INFO_FOR_NFT_LIST, {
    variables: { raccoons: JSON.stringify(raccoonNumsInWallet) }
  });


  const refreshInventory = () => {
    getInventory();
  }


  const getUpgradeStatusData = async () => {
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getUpgradeStatus({variables: {upgradeNames: JSON.stringify(raccoonUpgradesInWallet)}});
          }
        }
      }
    }
  }

  let raccoonUpgradesInWallet = null;
  if (state && state.raccoonUpgradesInWallet) {
    raccoonUpgradesInWallet = state.raccoonUpgradesInWallet;
  }


  useEffect(() => {
    if (dataGetUpdradeStatus && dataGetUpdradeStatus.upgradeStatus && dataGetUpdradeStatus.upgradeStatus.upgrades) {
        let upgrades = JSON.parse(dataGetUpdradeStatus.upgradeStatus.upgrades);
        setUpgradeStatus(upgrades);
    }
  }, [dataGetUpdradeStatus])

  useEffect(() => {
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 8000);
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 15000);
  }, []);

  useEffect(() => {
    if (globalLucid) {
      localRefreshWallet({fromApi: true});
    }
  }, [state.api]);

  const setWalletAddr = async () => {
    if (globalLucid) {
      if (globalLucid && globalLucid.wallet) {
        let addr = await globalLucid.wallet.rewardAddress();
        setWalletAddrress(addr);
      }
    }
  }
  
  useEffect(() => {
    setWalletAddr();
  }, [globalLucid, state.api]);

  useEffect(() => {
    if (dataGetInventory && dataGetInventory.inventory) {
      setInventory(dataGetInventory.inventory);
    }
  }, [dataGetInventory])
  
  useEffect(() => {
    if (dataGetRaccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads.results) {
      let newRaccoonsInWallet = [];
      let results = JSON.parse(dataGetRaccoonsSquads.raccoonsSquads.results);
      if (results && results.forEach ) {
        let racSquadInfo = {};
        results.forEach(result => {
          if (result && (result.raccoonsInSquadILead || result.raccoonsInSquadImIn || result.squadILead || result.squadImIn)) {
            racSquadInfo[result.racNum] = result;
          }
        });

/*
        if (raccoonsInWallet && raccoonsInWallet.forEach) {
          raccoonsInWallet.forEach(rac => {
            let newRac = {...rac};
            let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
            let racNum = parseInt(racNumStr);
            if (racSquadInfo[racNum]) {
              newRac.racNum = racSquadInfo[racNum].racNum;
              newRac.raccoonsInSquadILead = racSquadInfo[racNum].raccoonsInSquadILead;
              newRac.raccoonsInSquadImIn = racSquadInfo[racNum].raccoonsInSquadImIn;
              newRac.squadILead = racSquadInfo[racNum].squadILead;
              newRac.squadImIn = racSquadInfo[racNum].squadImIn;
            }
            newRaccoonsInWallet.push(newRac);
          });
        }
*/
        setRaccoonSquadInfo({...racSquadInfo});
      }
    }
  }, [dataGetRaccoonsSquads, loadingGetRaccoonsSquads]);


  useEffect(() => {
    if (dataGetRaccoonInfoForNFTList && dataGetRaccoonInfoForNFTList.raccoonInfoForNFTList && dataGetRaccoonInfoForNFTList.raccoonInfoForNFTList.raccoonInfo) {
        let raccoonInfoObj = JSON.parse(dataGetRaccoonInfoForNFTList.raccoonInfoForNFTList.raccoonInfo);
        setRaccoonInfo(raccoonInfoObj);
    }
  }, [dataGetRaccoonInfoForNFTList]);


  const onBulkDetach = () => {
    bulkDetach({ variables: {detachPairs: JSON.stringify(detachPairs) } })
  }


  const selectRaccoon = ({boxNumber, raccoon}) => {
    let otherBoxNumber = boxNumber + 1;
    if (boxNumber % 2 === 0) {
      otherBoxNumber = boxNumber - 1;
    }

    let racNum = parseInt(raccoon.assetNameAscii.substring("Raccoon ".length - 1, raccoon.assetNameAscii.length));

    const thisRaccoonInfo = raccoonInfo[racNum];

    if ((!thisRaccoonInfo['wings'] || thisRaccoonInfo['wings'] === 'None') && (!thisRaccoonInfo['ring'] || thisRaccoonInfo['ring'] === 'None')) {
      setToastColor('#e08705');
      setTimeout(() => {
        toast("That Raccoon has no attachables to detach");
      }, 200);
      return;
    }

    setCellNumberToSelectItemFor(null);

    let oldCellItems = cellItems;

    oldCellItems[boxNumber] = {raccoon};

    setCellItems({...oldCellItems});
  }


  const selectItem = ({boxNumber, nftOrInventory, upgrade}) => {
    let otherBoxNumber = boxNumber + 1;
    if (boxNumber % 2 === 0) {
      otherBoxNumber = boxNumber - 1;
    }

    setCellNumberToSelectItemFor(null);

    let oldCellItems = cellItems;

    oldCellItems[boxNumber] = {nftOrInventory, upgrade};

    setCellItems({...oldCellItems});
  }


  const toggleDetach = ({racNum, raccoonInfo, attachmentName, boxNumber}) => {

    let exists = false;
    detachPairs.forEach(dp => {
      if (dp.racNum === racNum && dp.attachmentName === attachmentName) {
        exists = true;
      }
    });

    if (exists) {
      let newDPs = [];
      detachPairs.forEach(dp => {
        if (dp.racNum !== racNum) {
          newDPs.push(dp);
        } else if (dp.attachmentName !== attachmentName) {
          newDPs.push(dp);
        }
      });
      setDetachPairs(newDPs);
    }

    if (!exists) {
      let newDPs = [...detachPairs];
      newDPs.push({racNum, raccoonInfo, attachmentName, boxNumber})
      setDetachPairs(newDPs);
    }

  }


  const clearCell = (cellNumber) => {
    let newDetachPairs = [];
    detachPairs.forEach(pair => {
      if (pair.boxNumber === cellNumber + 1 || pair.boxNumber === cellNumber + 2) {
        // skip
      } else {
        newDetachPairs.push(pair)
      }
    });
    setDetachPairs([...newDetachPairs]);

    let newCellItems = [];
    if (cellItems && cellItems[parseInt(cellNumber)]) {
      for (var cellNum in cellItems) {
        if (cellItems.hasOwnProperty(cellNum)) {
          if (parseInt(cellNum) !== parseInt(cellNumber) && parseInt(cellNum) !== parseInt(cellNumber) + 1 && parseInt(cellNum) !== parseInt(cellNumber) + 2) {
            newCellItems[cellNum] = cellItems[cellNum];
          }
        }
      }
    }
    setCellItems({...newCellItems});
  }


  if (browser && browser.name) {
    if (browser.name === 'firefox' || browser.name === 'safari') {
      return (
        <div className="home_wrapper">
          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
          </div>

          <center>
            <div className="global_leaderboard_title_text" style={{marginTop: '100px', textAlign: 'center', width: '100%', alignItems: 'center', justifyContent: 'center', flex: 1}}>
              Only Chrome, Brave and Edge browsers can view this page
            </div>  
          </center>    

        </div>
      )
    }
  }


  if (!walletAddress) {
    return (
        <>
        <div className="home_wrapper">
          
          <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
          </div>

          </div>
        </>
    )
  }


  /*
  if (!walletAddress || !myWallets.includes(walletAddress)) {
    return (
        <>
        <div className="home_wrapper">
          
          <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
          </div>

          </div>
        </>
    )
  }
  */


  const getUpgradeType = upgradeName => {
    let upgradeType = 'None';
    if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Milk') >= 0) {
      upgradeType = 'Milk';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Canteen') >= 0) {
      upgradeType = 'Canteen';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Vegebite') >= 0) {
      upgradeType = 'Vegebite';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Beans') >= 0) {
      upgradeType = 'Baked Beans';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Potion') >= 0) {
      upgradeType = 'PotionX';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gummy') >= 0) {
      upgradeType = 'Gummy Raccoon';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Mushrooms') >= 0) {
      upgradeType = 'Mushrooms';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gold Ring') >= 0) {
      upgradeType = 'Gold Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Emerald Ring') >= 0) {
      upgradeType = 'Emerald Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Ruby Ring') >= 0) {
      upgradeType = 'Ruby Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Diamond Ring') === 0 || upgradeName.indexOf('Diamond Ring') === 3) {
      upgradeType = 'Diamond Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('CompoundX Ring') >= 0) {
      upgradeType = 'CompoundX Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Blue Diamond Ring') >= 0) {
      upgradeType = 'Blue Diamond Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Light Wings') >= 0) {
      upgradeType = 'Light Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Dark Wings') >= 0) {
      upgradeType = 'Dark Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gold Wings') >= 0) {
      upgradeType = 'Gold Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Psychedelic Wings') >= 0) {
      upgradeType = 'Psychedelic Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('CompoundX Wings') >= 0) {
      upgradeType = 'CompoundX Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Blue Diamond Wings') >= 0) {
      upgradeType = 'Blue Diamond Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('10 Battle Points') >= 0) {
      upgradeType = '10 Battle Points';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('20 Battle Points') >= 0) {
      upgradeType = '20 Battle Points';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('50 Battle Points') >= 0) {
      upgradeType = '50 Battle Points';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('100 Battle Points') >= 0) {
      upgradeType = '100 Battle Points';
    }
    return upgradeType;
  }

  


  const getForgedItem = (upgradeType) => {
    let imgSrc = null;
    let resultItemUpgradeType = null;
    if (upgradeType === 'Milk') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif';
      resultItemUpgradeType = 'Canteen';
    } else if (upgradeType === 'Canteen') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif';
      resultItemUpgradeType = 'Vegebite';
    } else if (upgradeType === 'Vegebite') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif';
      resultItemUpgradeType = 'Baked Beans';
    } else if (upgradeType === 'Baked Beans') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif';
      resultItemUpgradeType = 'PotionX';
    } else if (upgradeType === 'PotionX') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif';
      resultItemUpgradeType = 'Gummy Raccoon';
    } else if (upgradeType === 'Gummy Raccoon') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif';
      resultItemUpgradeType = 'Mushrooms';
    } else if (upgradeType === 'Gold Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif';
      resultItemUpgradeType = 'Emerald Ring';
    } else if (upgradeType === 'Emerald Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif';
      resultItemUpgradeType = 'Ruby Ring';
    } else if (upgradeType === 'Ruby Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif';
      resultItemUpgradeType = 'Diamond Ring';
    } else if (upgradeType === 'Diamond Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif';
      resultItemUpgradeType = 'CompoundX Ring';
    } else if (upgradeType === 'CompoundX Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif';
      resultItemUpgradeType = 'Blue Diamond Ring';
    } else if (upgradeType === 'Light Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif';
      resultItemUpgradeType = 'Dark Wings';
    } else if (upgradeType === 'Dark Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif';
      resultItemUpgradeType = 'Gold Wings';
    } else if (upgradeType === 'Gold Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif';
      resultItemUpgradeType = 'Psychedelic Wings';
    } else if (upgradeType === 'Psychedelic Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif';
      resultItemUpgradeType = 'CompoundX Wings';
    } else if (upgradeType === 'CompoundX Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif';
      resultItemUpgradeType = 'Blue Diamond Wings';
    } 
    return {
      imgSrc,
      resultItemUpgradeType
    };
  }



  const renderCellItemAsRaccoon = (boxNumber, cellItem) => {
    if (cellItem && cellItem.upgrade) {
      return renderCellItem(boxNumber, cellItem);
    }
    
    let asciiName = cellItem.raccoon.assetNameAscii;
    let raccoonNum = asciiName.substring('Raccoon '.length, asciiName.length);

    let thisRaccoonInfo = {};
    if (thisRaccoonInfo) {
      thisRaccoonInfo = raccoonInfo[raccoonNum];
    }

    let hasDetachInProgress = false;
    if (thisRaccoonInfo && (thisRaccoonInfo['requesthRequestType'])) {
      if (thisRaccoonInfo['requesthRequestType'].includes('detach')) {
        hasDetachInProgress = true;
      }
    }

    if (thisRaccoonInfo && (thisRaccoonInfo['watchRequestType'])) {
      if (thisRaccoonInfo['watchRequestType'].includes('detach')) {
        hasDetachInProgress = true;
      }
    }

    let upgradesAppliedToThisRaccoon = null;
    if (raccoonUpgradesApplied[raccoonNum] && raccoonUpgradesApplied[raccoonNum].length > 0) {
      upgradesAppliedToThisRaccoon = [];
      raccoonUpgradesApplied[raccoonNum].forEach(upgradeForThisRaccoon => {
        if (upgradeForThisRaccoon && upgradeForThisRaccoon.reason === 'applied') {
          upgradesAppliedToThisRaccoon.push(upgradeForThisRaccoon);
        }
      });
    }

    let upgradesWaitingForThisRaccoon = null;
    if (raccoonUpgradesApplied[raccoonNum] && raccoonUpgradesApplied[raccoonNum].length > 0) {
      upgradesWaitingForThisRaccoon = [];
      raccoonUpgradesApplied[raccoonNum].forEach(upgradeForThisRaccoon => {
        if (upgradeForThisRaccoon && upgradeForThisRaccoon.reason === 'watching' && upgradeForThisRaccoon.status === 'waitingForBlockchain') {
          upgradesWaitingForThisRaccoon.push(upgradeForThisRaccoon);
        }
      });
    }

    let upgradeStrings = [];
    if (upgradesAppliedToThisRaccoon && upgradesAppliedToThisRaccoon.length > 0) {
      upgradesAppliedToThisRaccoon.forEach(upgrade => {
        if (upgrade.assetName) {
          if (upgrade.assetName.indexOf(' Battle Points') >= 0) {
            upgradeStrings.push(`${upgrade.assetName} for epoch ${upgrade.epoch}`);
          } else {
            upgradeStrings.push(`${upgrade.assetName}: ${UPGRADE_BONUS_RATES[upgrade.upgradeType]}% boost for epoch ${upgrade.epoch}`);
          }
        } else {
          if (upgrade.upgradeType.indexOf(' Battle Points') >= 0) {
            upgradeStrings.push(`${upgrade.upgradeType} for epoch ${upgrade.epoch}`);
          } else {
            upgradeStrings.push(`${upgrade.upgradeType}: ${UPGRADE_BONUS_RATES[upgrade.upgradeType]}% boost for epoch ${upgrade.epoch}`);
          }
        }
        
      });
    }

    let upgradeWatchingStrings = [];
    if (upgradesWaitingForThisRaccoon && upgradesWaitingForThisRaccoon.length > 0) {
      upgradesWaitingForThisRaccoon.forEach(upgrade => {
        let upgradeName = upgrade.assetName;
        let upgradeType = 'None';
        if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Milk') >= 0) {
          upgradeType = 'Milk';
        } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Canteen') >= 0) {
          upgradeType = 'Canteen';
        } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Vegebite') >= 0) {
          upgradeType = 'Vegebite';
        } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Beans') >= 0) {
          upgradeType = 'Baked Beans';
        } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Potion') >= 0) {
          upgradeType = 'PotionX';
        } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gummy') >= 0) {
          upgradeType = 'Gummy Raccoon';
        } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Mushrooms') >= 0) {
          upgradeType = 'Mushrooms';
        }                    

        if (upgrade.assetName && upgrade.assetName.indexOf('detach-') >= 0) {
          upgradeWatchingStrings.push(`${upgrade.assetName.replaceAll('-', ' ')}`);
        } else if (upgrade.assetName && (upgrade.assetName.indexOf(' Ring') >= 0 || upgrade.assetName.indexOf(' Wings') >= 0)) {
          upgradeWatchingStrings.push(`${upgrade.assetName.replaceAll('-', ' ')}`);
        } else if (upgrade.assetName && (upgrade.assetName.indexOf(' Battle Points') >= 0)) {
          upgradeWatchingStrings.push(`${upgrade.assetName}`);
        } else {
          upgradeWatchingStrings.push(`${upgrade.assetName}: ${UPGRADE_BONUS_RATES[upgradeType]}% boost`);
        }
      });
    }

    let racSquadInfo = raccoonSquadInfo[parseInt(raccoonNum)];
    if (!racSquadInfo) {
      racSquadInfo = {};
    }

    let epochBonusTraitMatchStrings = [];
    let haveUpcomingEpochBonusUpgrades = false;
    let epochForBonuses = 0;
    if (thisRaccoonInfo) {
      if (thisRaccoonInfo.upcoming_left_hand_trait_match) {
        epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_left_hand_trait_match);
        haveUpcomingEpochBonusUpgrades = true;
        epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
      }
      if (thisRaccoonInfo.upcoming_right_hand_trait_match) {
        epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_right_hand_trait_match);
        haveUpcomingEpochBonusUpgrades = true;
        epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
      }
      if (thisRaccoonInfo.upcoming_wardrobe_trait_match) {
        epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_wardrobe_trait_match);
        haveUpcomingEpochBonusUpgrades = true;
        epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
      }
      if (thisRaccoonInfo.upcoming_head_trait_match) {
        epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_head_trait_match);
        haveUpcomingEpochBonusUpgrades = true;
        epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
      }
      if (thisRaccoonInfo.upcoming_mouth_trait_match) {
        epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_mouth_trait_match);
        haveUpcomingEpochBonusUpgrades = true;
        epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
      }
      if (thisRaccoonInfo.upcoming_eyewear_trait_match) {
        epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_eyewear_trait_match);
        haveUpcomingEpochBonusUpgrades = true;
        epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
      }
      if (thisRaccoonInfo.upcoming_eye_trait_match) {
        epochBonusTraitMatchStrings.push(thisRaccoonInfo.upcoming_eye_trait_match);
        haveUpcomingEpochBonusUpgrades = true;
        epochForBonuses = thisRaccoonInfo.upcoming_epoch_for_matches;
      }
    }

    let shouldShowForgeIcon = ((upgradesAppliedToThisRaccoon && upgradesAppliedToThisRaccoon.length > 0) || (upgradesWaitingForThisRaccoon && upgradesWaitingForThisRaccoon.length > 0));

    return (
      <div>
        <div className="forge_box" onClick={() => setCellNumberToSelectItemFor(boxNumber)}>
          <div style={{position: 'relative'}}>
            <img src={`https://rs9.s3.us-east-1.amazonaws.com/n/${raccoonNum}.png?cb=${Date.now()}`} className="my_nfts_nft_image" style={{marginTop: '2px', borderRadius: '5px'}} />
            {shouldShowForgeIcon && (
              <div className="nft_box_applied_upgrade_container">
                <Tooltip placement="top" content={
                  <div className="applied_upgrade_tooltip">
                    <>
                    {upgradeStrings && upgradeStrings.length > 0 && (
                      <>
                        <div style={{marginBottom: '5px'}}>Upcoming upgrades:</div>
                          {upgradeStrings && upgradeStrings.map((upgradeStr, i) => {
                            return (
                              <div key={`up_${i}`}>{upgradeStr}</div>
                            )
                          })}
                      </>
                    )}
                    {upgradeWatchingStrings && upgradeWatchingStrings.length > 0 && (
                      <>
                        <div style={{marginBottom: '5px', marginTop: '5px'}}>Waiting for blockchain:</div>
                          {upgradeWatchingStrings && upgradeWatchingStrings.map((upgradeStr, i) => {
                            return (
                              <div key={`up_${i}`}>{upgradeStr}</div>
                            )
                          })}
                      </>
                    )}
                    </>
                  </div>
                }>
                  <div className="nft_box_applied_upgrade_img_container">
                    <img src="forgeb.png" className="connect_button_icon_upgrades" style={{height: '18px', marginRight: '2px'}} />
                  </div>
                </Tooltip>
              </div>
            )}
            {haveUpcomingEpochBonusUpgrades && (
              <div className="nft_box_upcoming_boost_container" style={{right: shouldShowForgeIcon ? '24px' : undefined}}>
                <Tooltip placement="top" content={
                  <div className="upcoming_boost_tooltip">
                    <>
                        <div style={{}}>Trait boosts in upcoming snapshot (epoch {epochForBonuses}):</div>
                        {epochBonusTraitMatchStrings && epochBonusTraitMatchStrings.map((bonus, j) => {
                          if (j < epochBonusTraitMatchStrings.length - 1) {
                            return <span key={`up_bo_${j}`}>{bonus}, </span>  
                          } else {
                            return <span key={`up_bo_${j}`}>{bonus}</span>
                          }
                        })}
                    </>
                  </div>
                }>
                  <div>
                    <img src="boosticon.gif" className="upcoming_boost_icon" style={{height: '20px'}} />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>      
        </div>
        <div style={{color: '#e8954c', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, marginTop: '5px'}}><div>{asciiName}</div><div><span style={{cursor: 'pointer', color: '#efdbb8'}} onClick={() => clearCell(boxNumber)}>Clear</span></div></div>
      </div>
    )
  }


  const renderCellItem = (boxNumber, cellItem) => {
    if (cellItem && cellItem.raccoon) {
      return renderCellItemAsRaccoon(boxNumber, cellItem);
    }

    let asciiName = cellItem.upgrade.assetNameAscii;
    let imgSrc = null;
    let upgradeNum = 0;
    let asciiPrefix = null;
    if (asciiName.indexOf('Milk') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Milk.gif';
      asciiPrefix = 'RS Milk #';
      upgradeNum = asciiName.substring('RS Milk '.length, asciiName.length);
    } else if (asciiName.indexOf('Canteen') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif';
      asciiPrefix = 'RS Canteen #';
      upgradeNum = asciiName.substring('RS Canteen '.length, asciiName.length);
    } else if (asciiName.indexOf('Vegebite') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif';
      asciiPrefix = 'RS Vegebite #';
      upgradeNum = asciiName.substring('RS Vegebite '.length, asciiName.length);
    } else if (asciiName.indexOf('Baked Beans') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif';
      asciiPrefix = 'RS Baked Beans #';
      upgradeNum = asciiName.substring('RS Baked Beans '.length, asciiName.length);
    } else if (asciiName.indexOf('PotionX') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif';
      asciiPrefix = 'RS PotionX #';
      upgradeNum = asciiName.substring('RS PotionX '.length, asciiName.length);
    } else if (asciiName.indexOf('Gummy') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif';
      asciiPrefix = 'RS Gummy Raccoon #';
      upgradeNum = asciiName.substring('RS Gummy Raccoon '.length, asciiName.length);
    } else if (asciiName.indexOf('Mushrooms') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif';
      asciiPrefix = 'RS Mushrooms #';
      upgradeNum = asciiName.substring('RS Mushrooms '.length, asciiName.length);
    } else if (asciiName.indexOf('Gold Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldRing.gif';
      asciiPrefix = 'RS Gold Ring #';
      upgradeNum = asciiName.substring('RS Gold Ring '.length, asciiName.length);
    } else if (asciiName.indexOf('Emerald Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif';
      asciiPrefix = 'RS Emerald Ring #';
      upgradeNum = asciiName.substring('RS Emerald Ring '.length, asciiName.length);
    } else if (asciiName.indexOf('Ruby Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif';
      asciiPrefix = 'RS Ruby Ring #';
      upgradeNum = asciiName.substring('RS Ruby Ring '.length, asciiName.length);
    } else if (asciiName.indexOf('Diamond Ring') === 0 || asciiName.indexOf('Diamond Ring') === 3) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif';
      asciiPrefix = 'RS Diamond Ring #';
      upgradeNum = asciiName.substring('RS Diamond Ring '.length, asciiName.length);
    } else if (asciiName.indexOf('CompoundX Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif';
      asciiPrefix = 'RS CompoundX Ring #';
      upgradeNum = asciiName.substring('RS CompoundX Ring '.length, asciiName.length);
    } else if (asciiName.indexOf('Blue Diamond Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif';
      asciiPrefix = 'RS Blue Diamond Ring #';
      upgradeNum = asciiName.substring('RS Blue Diamond Ring '.length, asciiName.length);
    } else if (asciiName.indexOf('Light Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/LightWings.gif';
      asciiPrefix = 'RS Light Wings #';
      upgradeNum = asciiName.substring('RS Light Wings '.length, asciiName.length);
    } else if (asciiName.indexOf('Dark Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif';
      asciiPrefix = 'RS Dark Wings #';
      upgradeNum = asciiName.substring('RS Dark Wings '.length, asciiName.length);
    } else if (asciiName.indexOf('Gold Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif';
      asciiPrefix = 'RS Gold Wings #';
      upgradeNum = asciiName.substring('RS Gold Wings '.length, asciiName.length);
    } else if (asciiName.indexOf('Psychedelic Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif';
      asciiPrefix = 'RS Psychedelic Wings #';
      upgradeNum = asciiName.substring('RS Psychedelic Wings '.length, asciiName.length);
    } else if (asciiName.indexOf('CompoundX Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif';
      asciiPrefix = 'RS CompoundX Wings #';
      upgradeNum = asciiName.substring('RS CompoundX Wings '.length, asciiName.length);
    } else if (asciiName.indexOf('Blue Diamond Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif';
      asciiPrefix = 'RS Blue Diamond Wings #';
      upgradeNum = asciiName.substring('RS Blue Diamond Wings '.length, asciiName.length);
    }

    return (
      <div>
        <div className="forge_box" onClick={() => setCellNumberToSelectItemFor(boxNumber)}>
          <img src={imgSrc} className="my_nfts_nft_image_upgrades" />
        </div>
        <div style={{color: '#e8954c', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, marginTop: '5px'}}><div>{asciiName}</div><div>&nbsp;</div></div>
      </div>
    )
  }

  const renderDetachableWing = (boxNumber, cellItem, raccoonCellItem) => {
    if (!raccoonCellItem || !raccoonCellItem.raccoon || raccoonCellItem.raccoon.assetNameAscii.indexOf('Raccoon ') < 0) {
      return <div />;
    }

    let racNum = parseInt(raccoonCellItem.raccoon.assetNameAscii.substring("Raccoon ".length - 1, raccoonCellItem.raccoon.assetNameAscii.length));

    const thisRaccoonInfo = raccoonInfo[racNum];

    if (!thisRaccoonInfo) {
      return <div />;
    }

    let asciiName = thisRaccoonInfo.wings;
 
    let imgSrc= null;
    let attachmentName = '';
    if (asciiName) {
      if (asciiName.indexOf('Light Wings') > -1) {
        attachmentName = 'Light Wings';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/LightWings.gif';
      } else if (asciiName.indexOf('Dark Wings') > -1) {
        attachmentName = 'Dark Wings';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif';
      } else if (asciiName.indexOf('Gold Wings') > -1) {
        attachmentName = 'Gold Wings';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif';
      } else if (asciiName.indexOf('Psychedelic Wings') > -1) {
        attachmentName = 'Psychedelic Wings';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif';
      } else if (asciiName.indexOf('CompoundX Wings') > -1) {
        attachmentName = 'CompoundX Wings';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif';
      } else if (asciiName.indexOf('Blue Diamond Wings') > -1) {
        attachmentName = 'Blue Diamond Wings';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif';
      }
    }
    

    let existingPair = false;
    detachPairs.forEach(dp => {
      if (dp.racNum === racNum && dp.attachmentName === attachmentName) {
        existingPair = true;
      }
    });

    return (
      <div style={{width: '270px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <div className="forge_box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', border: existingPair ? '3px solid #0f0' : '3px solid #333'}} onClick={imgSrc ? () => toggleDetach({racNum, raccoonInfo: thisRaccoonInfo, attachmentName, boxNumber}) : undefined}>
          {imgSrc && <img src={imgSrc} className="my_nfts_nft_image_upgrades" />}
          {!imgSrc && <span>no wings</span>}
        </div>
        {imgSrc && !existingPair && <div style={{color: '#e8954c', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, marginTop: '5px'}}><div>Click box to</div><div>Detach {attachmentName}</div></div>}
        {imgSrc && existingPair && <div style={{color: '#e8954c', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, marginTop: '5px'}}><div>Click box to NOT </div><div>Detach {attachmentName}</div></div>}
        {!imgSrc && <div style={{color: '#e8954c', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, marginTop: '5px'}}>&nbsp;</div>}
      </div>
    )
  }

  const renderDetachableRing = (boxNumber, cellItem, raccoonCellItem) => {

    if (!raccoonCellItem || !raccoonCellItem.raccoon || raccoonCellItem.raccoon.assetNameAscii.indexOf('Raccoon ') < 0) {
      return <div />;
    }

    let racNum = parseInt(raccoonCellItem.raccoon.assetNameAscii.substring("Raccoon ".length - 1, raccoonCellItem.raccoon.assetNameAscii.length));

    const thisRaccoonInfo = raccoonInfo[racNum];

    let asciiName = thisRaccoonInfo.ring;


    let imgSrc= null;
    let attachmentName = '';
    if (asciiName) {
      if (asciiName.indexOf('Gold Ring') > -1) {
        attachmentName = 'Gold Ring';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldRing.gif';
      } else if (asciiName.indexOf('Emerald Ring') > -1) {
        attachmentName = 'Emerald Ring';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif';
      } else if (asciiName.indexOf('Ruby Ring') > -1) {
        attachmentName = 'Ruby Ring';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif';
      } else if (asciiName.indexOf('Diamond Ring') === 0 || asciiName.indexOf('Diamond Ring') === 3) {
        attachmentName = 'Diamond Ring';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif';
      } else if (asciiName.indexOf('CompoundX Ring') > -1) {
        attachmentName = 'CompoundX Ring';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif';
      } else if (asciiName.indexOf('Blue Diamond Ring') > -1) {
        attachmentName = 'Blue Diamond Ring';
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif';
      } 
    }

    let existingPair = false;
    detachPairs.forEach(dp => {
      if (dp.racNum === racNum && dp.attachmentName === attachmentName) {
        existingPair = true;
      }
    });
    
    return (
      <div style={{width: '270px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <div className="forge_box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', border: existingPair ? '3px solid #0f0' : '3px solid #333', marginLeft: '50px'}}  onClick={imgSrc ? () => toggleDetach({racNum, raccoonInfo: thisRaccoonInfo, attachmentName, boxNumber}) : undefined}>
          {imgSrc && <img src={imgSrc} className="my_nfts_nft_image_upgrades" />}
          {!imgSrc && <span>no ring</span>}
        </div>
        {imgSrc && !existingPair && <div style={{color: '#e8954c', marginLeft: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, marginTop: '5px'}}><div>Click box to</div><div>Detach {attachmentName}</div></div>}
        {imgSrc && existingPair && <div style={{color: '#e8954c', marginLeft: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, marginTop: '5px'}}><div>Click box to NOT </div><div>Detach {attachmentName}</div></div>}
        {!imgSrc && <div style={{color: '#e8954c', marginLeft: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, marginTop: '5px'}}>&nbsp;</div>}
      </div>
    )
  }

  const renderResultCell = (cellLeft, cellRight) => {

    if (!cellItems[cellLeft] || !cellItems[cellRight]) {
      return (
        <div />
      )
    }

    if (!cellItems[cellLeft].upgrade || !cellItems[cellRight].upgrade) {
      return (
        <div />
      )
    }

    let leftCellUpgradeType = null;
    if (cellItems[cellLeft].upgrade.assetNameAscii) {
      leftCellUpgradeType = getUpgradeType(cellItems[cellLeft].upgrade.assetNameAscii);
    }

    let rightCellUpgradeType = null;
    if (cellItems[cellRight].upgrade.assetNameAscii) {
      rightCellUpgradeType = getUpgradeType(cellItems[cellRight].upgrade.assetNameAscii);
    }

    if (leftCellUpgradeType !== rightCellUpgradeType) {
      return <div />;
    }

    let forgedItem = getForgedItem(leftCellUpgradeType);

    return (
      <div>
        <div className="forge_box" onClick={() => setCellNumberToSelectItemFor(1)}>
          <img src={forgedItem.imgSrc} className="my_nfts_nft_image_upgrades" />
        </div>
      </div>
    )
  }



  let itemsToForge = [];
  if (cellItems) {
    for (let itemsBoxNumber in cellItems) {
      let itemBoxNumber = parseInt(itemsBoxNumber);
      if (cellItems.hasOwnProperty(itemsBoxNumber) && itemBoxNumber % 2 === 0) {
        let otherBoxNumber = itemBoxNumber - 1;
        if (cellItems[otherBoxNumber] && cellItems[itemBoxNumber].upgrade && cellItems[otherBoxNumber].raccoon) {
          itemsToForge.push({raccoon: cellItems[otherBoxNumber].raccoon, upgrade: cellItems[itemBoxNumber].upgrade});
        }
      }
    }
  }


  const renderBasket = () => {
    if (!detachPairs || detachPairs.length < 1) {
      return <div />;
    }

    return (
      <div style={{paddingLeft: '10%', paddingRight: '10%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px', marginBottom: '20px', fontSize: '21px'}}>
        <div style={{color: '#ededed', marginBottom: '10px'}}>Will detach the following items:</div>
        {detachPairs.map((item, i) => {
          return (
            <div key={`itemToForge_${i}`} >
              <div style={{color: '#ededed', marginBottom: '5px'}}>Raccoon {item.racNum} will detach {item.attachmentName}</div>
            </div>
          )
        })}
      </div>
    )
  }

  let enableBulkDetachButton = false;
  if (detachPairs && detachPairs.length > 0) {
    enableBulkDetachButton = true;
  }


  return (
    <>
      <div className="home_wrapper">
        
        <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

        <div className="logo_container">
          <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
        </div>

        {!state.doneInitialWalletLoad && (
          <div className="my_nfts_container">
            <ClipLoader
              color={'#FFF'}
              loading={true}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}


        {state.doneInitialWalletLoad && (
          <div style={{paddingLeft: '10%', paddingRight: '10%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px', marginBottom: '20px'}}>

            <div style={{width: '100%', marginBottom: '70px'}}>
              <div className="forge_row">
                <div className="detach_forge_row_left_1_col">
                    {!cellItems[1] && <div className="forge_box" style={{marginBottom: '22px'}} onClick={() => setCellNumberToSelectItemFor(1)}><span style={{fontSize: '14px'}}>Select Raccoon</span></div>}
                    {cellItems[1] && renderCellItem(1, cellItems[1])}
                </div>
                <div className="detach_forge_row_plus_sign_column">
                  <span>&nbsp;</span>
                </div>
                <div className="detach_forge_row_left_2_col" style={{minWidth: '500px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      {!cellItems[1] && !cellItems[2] && <div />}
                      {cellItems[1] && renderDetachableWing(2, cellItems[2], cellItems[1])}
                      {cellItems[1] && renderDetachableRing(3, cellItems[3], cellItems[1])}
                    </div>
                </div>
              </div>
            </div>


            <div style={{width: '100%', marginBottom: '70px'}}>
              <div className="forge_row">
                <div className="detach_forge_row_left_1_col">
                    {!cellItems[4] && <div className="forge_box" style={{marginBottom: '22px'}} onClick={() => setCellNumberToSelectItemFor(4)}><span style={{fontSize: '14px'}}>Select Raccoon</span></div>}
                    {cellItems[4] && renderCellItem(4, cellItems[4])}
                </div>
                <div className="detach_forge_row_plus_sign_column">
                  <span>&nbsp;</span>
                </div>
                <div className="detach_forge_row_left_2_col" style={{minWidth: '500px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      {!cellItems[4] && !cellItems[5] && <div />}
                      {cellItems[4] && renderDetachableWing(5, cellItems[5], cellItems[4])}
                      {cellItems[4] && renderDetachableRing(6, cellItems[6], cellItems[4])}
                    </div>
                </div>
              </div>
            </div>


            <div style={{width: '100%', marginBottom: '70px'}}>
              <div className="forge_row">
                <div className="detach_forge_row_left_1_col">
                    {!cellItems[7] && <div className="forge_box" style={{marginBottom: '22px'}} onClick={() => setCellNumberToSelectItemFor(7)}><span style={{fontSize: '14px'}}>Select Raccoon</span></div>}
                    {cellItems[7] && renderCellItem(7, cellItems[7])}
                </div>
                <div className="detach_forge_row_plus_sign_column">
                  <span>&nbsp;</span>
                </div>
                <div className="detach_forge_row_left_2_col" style={{minWidth: '500px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      {!cellItems[7] && !cellItems[8] && <div />}
                      {cellItems[7] && renderDetachableWing(8, cellItems[8], cellItems[7])}
                      {cellItems[7] && renderDetachableRing(9, cellItems[9], cellItems[7])}
                    </div>
                </div>
              </div>
            </div>


            <div style={{width: '100%', marginBottom: '70px'}}>
              <div className="forge_row">
                <div className="detach_forge_row_left_1_col">
                    {!cellItems[10] && <div className="forge_box" style={{marginBottom: '22px'}} onClick={() => setCellNumberToSelectItemFor(10)}><span style={{fontSize: '14px'}}>Select Raccoon</span></div>}
                    {cellItems[10] && renderCellItem(10, cellItems[10])}
                </div>
                <div className="detach_forge_row_plus_sign_column">
                  <span>&nbsp;</span>
                </div>
                <div className="detach_forge_row_left_2_col" style={{minWidth: '500px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      {!cellItems[10] && !cellItems[11] && <div />}
                      {cellItems[10] && renderDetachableWing(11, cellItems[11], cellItems[10])}
                      {cellItems[10] && renderDetachableRing(12, cellItems[12], cellItems[10])}
                    </div>
                </div>
              </div>
            </div>



            <div style={{width: '100%', marginBottom: '70px'}}>
              <div className="forge_row">
                <div className="detach_forge_row_left_1_col">
                    {!cellItems[13] && <div className="forge_box" style={{marginBottom: '22px'}} onClick={() => setCellNumberToSelectItemFor(13)}><span style={{fontSize: '14px'}}>Select Raccoon</span></div>}
                    {cellItems[13] && renderCellItem(13, cellItems[13])}
                </div>
                <div className="detach_forge_row_plus_sign_column">
                  <span>&nbsp;</span>
                </div>
                <div className="detach_forge_row_left_2_col" style={{minWidth: '500px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      {!cellItems[13] && !cellItems[14] && <div />}
                      {cellItems[13] && renderDetachableWing(14, cellItems[14], cellItems[13])}
                      {cellItems[13] && renderDetachableRing(15, cellItems[15], cellItems[13])}
                    </div>
                </div>
              </div>
            </div>



          </div>

        )}

        {renderBasket()}

        <center style={{marginTop: '100px'}}>
          <button className={enableBulkDetachButton ? "forgeItemsBtn" : "forgeItemsBtnDisabled"} onClick={enableBulkDetachButton ? (e) => {
            e.preventDefault();
            e.stopPropagation();
            onBulkDetach();
          } : undefined}>
            Bulk Detach
          </button>
        </center>


        <SelectRaccoonModal source="bulkDetach" show={cellNumberToSelectItemFor % 3 !== 0 && cellNumberToSelectItemFor !== null} buttonLabel="Select" boxNumber={cellNumberToSelectItemFor} cellItems={cellItems} refreshInventory={refreshInventory} raccoonsInWallet={raccoonsInWallet} raccoonInfoLocal={raccoonInfo} raccoonUpgradesInWallet={raccoonUpgradesInWallet} raccoonUpgradesApplied={raccoonUpgradesApplied} raccoonSquadInfo={raccoonSquadInfo} inventoryItems={inventory} getUpgradeStatusData={getUpgradeStatusData} parentSetToastColor={setToastColor} selectRaccoon={selectRaccoon} close={() => {setCellNumberToSelectItemFor(null);}} />

        <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={9000} />

      </div>
    </>
  );
};


export default BulkDetach;

